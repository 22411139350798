import { useState } from "react";

import GalleryAll from "./GalleryAll";
import GalleryCategory1 from "./GalleryCategory1";
import GalleryCategory2 from "./GalleryCategory2";
import BredCrumb from "../BredCrumb";
import InternalBaseBanner from "../InternalBaseBanner";
import { useTitle } from "../../../services/PageTitle";

const Gallery = () => {
  useTitle("Gallery")
  const [relatedLink, setRelatedLink] = useState("All");
  //for lightBox

  return (
    <>
      <InternalBaseBanner title={"Gallery"} />
      <BredCrumb title={"Gallery"} />
      <div className="gallery">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              {/* <ul className="nav nav-pills justify-content-center" role="tablist">
              <li className="nav-item">
                <a
                  className={
                    relatedLink === "All" ? "nav-link active" : "nav-link"
                  }
                  data-toggle="pill"
                  href="#menu"
                  onClick={() => setRelatedLink("All")}
                >
                  All
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    relatedLink === "Yoga Day" ? "nav-link active" : "nav-link"
                  }
                  data-toggle="pill"
                  href="#menu1"
                  onClick={() => setRelatedLink("Yoga Day")}
                >
                  Yoga Day
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    relatedLink === "Independence day" ? "nav-link active" : "nav-link"
                  }
                  data-toggle="pill"
                  href="#menu2"
                  onClick={() => setRelatedLink("Independence day")}
                >
                 Independence day
                </a>
              </li>
            </ul> */}
              <div className="tab-content">
                {relatedLink === "All" ? (
                  <GalleryAll />
                ) : relatedLink === "Yoga Day" ? (
                  <GalleryCategory1 />
                ) : (
                  <GalleryCategory2 />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* my design */}
        {/* <h1 className="mt-5 text-center main-heading">Order your Favorite dish</h1>
      <hr />
      <div className="menu-tabs container">
        <div className="menu-tab d-flex justify-content-around">
          <button className="btn btn-warning">Breakfast</button>
        </div>
      </div> */}
      </div>
    </>
  );
};

export default Gallery;
