import React from "react";

const InternalBaseBanner = ({ title }) => {
  return (
    <>
      <div className="inner-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="b-title">
                <h5>{title}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalBaseBanner;
