import RcNumber from "./tabs/RcNumber";

const DrtFinalCertificateParent = () => {
  return (
    <>
      <ul className="nav nav-pills mb-4">
        <li className="nav-item">
          <span className="nav-link active">RC No. / TRC No.</span>
        </li>
      </ul>

      <div className="tab-content" id="pills-tabContent">
        <RcNumber />
      </div>
    </>
  );
};

export default DrtFinalCertificateParent;
