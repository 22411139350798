import axiosBaseURL from "../../../httpCommon";
import React, { useEffect, useState, useRef, useContext } from "react";
import Slider from "react-slick";
import { DrtContext } from "../../../context/DrtContext";
import Loader from "../../Loader";

const Homeslider = () => {
  const { EncryptDecrypt } = useContext(DrtContext);
  const slider = useRef();

  const [homebnr, setBanner] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [playPause, setPlayPause] = useState(false);

  const getHomeBanner = async () => {
    try {
      const result = await axiosBaseURL.get("/get-banners");
      const temp = EncryptDecrypt(result.data);
      setBanner(temp.data);
      setIsLoading(false);
    } catch (error) {
      setError("An error occurred while fetching the data.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHomeBanner();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading) {
    return <Loader />;
  }

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const nextSlide = () => {
    slider.current.slickNext();
  };

  const prevSlide = () => {
    slider.current.slickPrev();
  };

  const handlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    } else {
      slider.current.slickPause();
    }
  };

  return (
    <>
      <div className="row pt-4 pb-4">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <div id="midslide" className="banner">
            <Slider {...settings} ref={slider}>
              {homebnr?.map((item, index) => (
                <div
                  key={item.id}
                  className={index === 0 ? "slick-slide" : "carousel-item"}
                >
                  <img
                    src={`${item.image_url}`}
                    className="banner-img"
                    alt="banner-slider"
                  />
                  <div className="carousel-content">
                    <div className="slide-small-xt">
                      <h6>{item.title}</h6>
                      <h2>{item.sub_title}</h2>
                      <p>{item.summery}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="arrowPlayPause">
              <div className="midslide-arrow">
                <a className="carousel-control-prev" onClick={prevSlide}>
                  <span className="carousel-control-prev-icon">
                    <span className="mdi mdi-chevron-left"></span>
                  </span>{" "}
                </a>
                <a className="carousel-control-next" onClick={nextSlide}>
                  <span className="carousel-control-next-icon ">
                    <span className="mdi mdi-chevron-right"></span>
                  </span>{" "}
                </a>
              </div>
              <a onClick={handlePlayPause} className="btn pause">
                {!playPause ? (
                  <span>
                    <i className="fas fa-pause-circle"></i>
                    Pause
                  </span>
                ) : (
                  <span>
                    <i className="fas fa-play"></i>
                    Play
                  </span>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homeslider;
