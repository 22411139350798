import React from 'react'
import BredCrumb from './BredCrumb'
import InternalBaseBanner from './InternalBaseBanner'
import { NavLink } from 'react-router-dom'
import { useTitle } from '../../services/PageTitle'

const UsefulLinks = () => {
    useTitle('Useful Links')
    return (
        <>
            <div className="about">
                <InternalBaseBanner title={'USEFUL LINKS'} />
                <BredCrumb title={'USEFUL LINKS'} />
                <div className="container pt-4 pb-4">
                    <div className="tender-heading-sec mb-3">
                        <span className="tender-heading">Useful Links</span>
                    </div>
                    <ul className="navbar-nav mr-auto menu">
                        <li className="nav-item">
                            <NavLink to="/vacancies">Vacancies</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/downloads">Downloads</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/casedetail">Case Details </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/causelist">Cause List </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/order">Order</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/listOfcpio">List of CPIOs </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/allnotice">Notices</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/contactus"> Contact Us</NavLink>
                        </li>
                        <li className="nav-item">
                            <a
                                href="https://drt.etribunals.gov.in/edrt/login.php"
                                target={"_blank"}
                            >
                                {" "}
                                e-Filing{" "}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default UsefulLinks