
import axios from "axios";

const Auth ={
    login
}

   function login(values) { 
      const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const caseDetailData = {
          email: values.email,
          password: values.password
          
        };
        return  axios.post(
          "http://164.100.228.65/drtapi/auth/login",
          caseDetailData,
          config
        );
       // setlogindetail(response.data.message);
      };


   export default Auth;