import axios from "axios";

const axiosBaseURL = axios.create({
  // baseURL: "http://127.0.0.1/DRT_application/public/index.php/api/v1",// divyansh
  baseURL: process.env.REACT_APP_INTERNAL_URL,

  // baseURL: "http://91.203.135.173/DRT_application/public/index.php/api/v1",
  // baseURL: "http://10.194.75.135/application/public/index.php/api/v1",
  //baseURL: "http://10.194.75.136/application/public/index.php/api/v1",
  //baseURL: "http://10.194.75.137/application/public/index.php/api/v1",
  // headers: { "X-user": "zur1xjb4", "Content-Type": "application/json" },
  // baseURL: "http://164.100.211.141/DRT_application/public/index.php/api/v1",
});

export default axiosBaseURL;

export const axiosBaseURLExternal = axios.create({
  baseURL: process.env.REACT_APP_EXTERNAL_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
