import { useRef, useContext } from "react";
import { DrtContext } from "../../../context/DrtContext";

const DrtSearch = () => {
  const { getSearchTerm, searchTerm, searchResults, handleClickSearch } =
    useContext(DrtContext);
  const inputEl = useRef("");

  return (
    <div className="form-search">
      <div className="lst-form">
        <input
          ref={inputEl}
          type="text"
          onChange={() => getSearchTerm(inputEl.current.value)}
          value={searchTerm}
          className="form-control"
          placeholder="Search"
        />
        <div className="srch-ab">
          {/* <a href="" className="btn search-btn">
            <i className="fas fa-search"><span className="sr-only">no text</span></i>
            </a>*/}
          <div className="popup">
            {searchTerm !== "" ? (
              searchResults.length > 0 ? (
                searchResults.map((item, index) => (
                  <div key={index} onClick={handleClickSearch}>
                    {item.link}
                  </div>
                ))
              ) : (
                <div style={{ color: "#fff" }}>No Match</div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrtSearch;
