import { useState, useEffect, useContext } from "react";
import axiosBaseURL from "../../httpCommon";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import ReactPaginate from "react-paginate";
import { DrtContext } from "../../context/DrtContext";
import { useTitle } from "../../services/PageTitle";

const ListCPIO = () => {
  useTitle("CPIO List");
  const [listCpio, setListCpio] = useState([]);
  const [loading, setLoading] = useState(true);
  const { EncryptDecrypt } = useContext(DrtContext);
  const [error, setError] = useState(null);
  // fetching List of CPIO's
  // fetching List of CPIO's
  const fetchListCpio = async () => {
    setLoading(true);
    try {
      const result = await axiosBaseURL.get("/get-CpioList");
      const temp = EncryptDecrypt(result.data);
      const tempupdate = temp.data.sort((a, b) =>
        a.drat_name > b.drat_name ? 1 : -1
      );
      //console.log(tempupdate)
      setListCpio(
        tempupdate.map((item, index) => ({ ...item, sr: index + 1 }))
      );
    } catch (error) {
      console.log(error);
      setError("something went wrong");
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchListCpio();
  }, []);

  // pagination
  const itemsPerPage = 5;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [startListNo, setStartListNo] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endListNo = startListNo + itemsPerPage;
    setCurrentItems(listCpio.slice(startListNo, endListNo));
    setPageCount(Math.ceil(listCpio.length / itemsPerPage));
  }, [startListNo, itemsPerPage, listCpio]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newStartListNo = (event.selected * itemsPerPage) % listCpio.length;
    setStartListNo(newStartListNo);
  };

  if (loading) {
    return <h6 className="loader">Loading......</h6>;
  }
  return (
    <>
      <InternalBaseBanner title={"List of CPIOs "} />
      <BredCrumb title={"List of CPIOs "} />
      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div className="about-content">
                <h6>List of CPIOs</h6>
              </div>
            </div>
          </div>

          {loading ? (
        <h6 className="loader">Loading ...</h6>
      ) : error ? (
        <div className="error-message">
          <h6>Error: {error}</h6>
          {/* You can provide additional error handling UI or actions here */}
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 cpi-wraper">
            <div className="table-responsive">
              <table className="table table-striped common-table bluetable mb-3">
                <thead>
                  <tr>
                    <th>Sl. No.</th>
                    <th>DRT/DRAT Name</th>
                    <th>Name of CPIO</th>
                    <th>Designation CPIO</th>
                    <th>Contact No.</th>
                    <th>Address</th>
                    <th>Name of 1st Appellate</th>
                    <th>Designation</th>
                    <th>Address</th>
                    <th>Contact No.</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems ? (
                    currentItems.map((item) => (
                      <tr key={item.sr}>
                        <td>{item.sr}</td>
                        <td>{item.drat_name}</td>
                        <td>{item.cpio_name}</td>
                        <td>{item.cpio_designation.designation_name}</td>
                        <td>{item.cpio_contact}</td>
                        <td>{item.cpio_address}</td>
                        <td>{item.first_appellate_name}</td>
                        <td>{item.appel_designation.designation_name}</td>
                        <td>{item.appellate_address}</td>
                        <td>{item.appellate_contact}</td>
                      </tr>
                    ))
                  ) : (
                    <>NO Data Found</>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
        </div>
      </div>
      <div className="nav-pagination mb-5">
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< PREVIOUS"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default ListCPIO;
