import { years } from "../../../../../../../services/YearService";
import { Formik } from "formik";
import { useEffect, useState, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import Captcha from "react-numeric-captcha";
import * as Yup from "yup";
import { axiosBaseURLExternal } from "../../../../../../../httpCommon";

const DiaryNoWise = () => {
  const [drtLocationName, setDrtLocationName] = useState("");
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  // state to control modal
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);

  // fetching first dropDownData
  const [drtLocations, setDrtLocations] = useState([]);
  const fetchDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDrtLocations(sortedLocation);
  };

  useEffect(() => {
    fetchDrtLocation();
  }, []);

  // fetching final data after post call
  const [loading, setLoading] = useState(false);
  const [caseDetail, setCaseDetail] = useState("");
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    setLoading(true);
    try {
      const response = await axiosBaseURLExternal.post(url, data);
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail("");
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  // Initial Value
  const initialValues = {
    drtDratId: "",
    diaryNo: "",
    caseYear: new Date().getFullYear(),
  };

  // validation
  const validationSchema = Yup.object({
    drtDratId: Yup.string().required("Required"),
    diaryNo: Yup.string()
      .trim()
      .max(8, "Maximum length is 8 characters")
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required("Required"),
    caseYear: Yup.string().required("Required"),
  });

  // submit handler
  const handleSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDrtId: values.drtDratId,
      diaryNo: values.diaryNo,
      diaryYear: values.caseYear,
    };
    getDrtDratName(values.drtDratId);
    fetchCaseDetail("getCaseDetailDiaryNoWise", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    setCaptchaStatus(false);
    capt.current.refresh();
    // resetForm();
  };
  const newMapDate = (date) => {
    if (date) {
      const [dd, mm, yyyy] = date?.split("/");
      return [
        dd.length === 1 ? `0${dd}` : dd,
        mm.length === 1 ? `0${mm}` : mm,
        yyyy,
      ].join("/");
    }
  };

  const getDrtDratName = (id) => {
    let name = drtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtLocationName(name[0]?.SchemaName);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* Field 1 */}
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  DRT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drtDratId"
                    name="drtDratId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.drtDratId}
                  >
                    <option>Select DRT</option>
                    {drtLocations &&
                      drtLocations
                        .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                        .map((item, index) => (
                          <option value={`${item.schemeNameDrtId}`} key={index}>
                            {item.SchemaName}
                          </option>
                        ))}
                  </select>
                  {touched.drtDratId && errors.drtDratId ? (
                    <div className="error">{errors.drtDratId}</div>
                  ) : null}
                </div>
              </div>

              {/* field2 */}
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Diary No
                </label>
                <div className="col-sm-7">
                  <input
                    id="diaryNo"
                    name="diaryNo"
                    type="text"
                    className="form-control"
                    placeholder="Enter Diary No"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.diaryNo}
                    autoComplete="off"
                  />
                  {touched.diaryNo && errors.diaryNo ? (
                    <div className="error">{errors.diaryNo}</div>
                  ) : null}
                </div>
              </div>

              {/* field 3   */}
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Diary Year
                </label>
                <div className="col-sm-7">
                  <select
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="caseYear"
                    name="caseYear"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.caseYear}
                  >
                    {years.map((item, index) => (
                      <option value={item.year} key={index}>
                        {item.year}
                      </option>
                    ))}
                  </select>
                  {touched.caseYear && errors.caseYear ? (
                    <div className="error">{errors.caseYear}</div>
                  ) : null}
                </div>
              </div>

              {/* field 4   */}
              <div className="form-group capcha-sec">
                <div className="col-sm-12 px-0 d-flex">
                  <Captcha
                    onChange={(status) => {
                      setCaptchaStatus(status);
                    }}
                    ref={capt}
                    required
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={!captchaStatus}
                    className="btn btn-reload"
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
                {!captchaStatus ? (
                  <div className="captcha-text">Enter valid captcha</div>
                ) : null}
              </div>
            </form>

            {loading ? (
              <h6 className="loader">Loading ......</h6>
            ) : (
              <Modal
                show={isOpenModal}
                onHide={() => setIsOpenModal(false)}
                // className="modal-90w"
              >
                <Modal.Header>
                  DRT/DRAT Case Status Report <br /> {drtLocationName}{" "}
                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive mb-3">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Applicant V/S Respondent</th>
                          <th>Dairy No.</th>
                          <th>Date of Filing</th>
                          <th>Case No.</th>
                          <th>More Details</th>
                        </tr>
                      </thead>

                      {caseDetail && !error ? (
                        <tbody>
                          <tr>
                            <td>
                              {caseDetail?.petitionerName} <br />
                              V/S <br />
                              {caseDetail?.respondentName}
                            </td>
                            <td>
                              {caseDetail?.diaryno}/{caseDetail?.diaryyear}
                            </td>
                            <td>{newMapDate(caseDetail?.dateoffiling)}</td>
                            <td>
                              {caseDetail?.casetype}/{caseDetail?.caseno}/
                              {caseDetail?.caseyear}
                            </td>
                            <td>
                              <button
                                className="pop-btn btn"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setIsOpenModal2(true);
                                }}
                              >
                                More Details
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5"> No Record Found </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            <Modal show={isOpenModal2} onHide={() => setIsOpenModal2(false)}>
              <Modal.Header>
                <div>
                  <b> More Details </b>
                  <br />
                  <span>{drtLocationName}</span>
                </div>
                <Button
                  type="button"
                  className="close"
                  onClick={() => setIsOpenModal2(false)}
                >
                  &times;
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* case status */}
                <div className="table-responsive mb-3">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan="2">CASE STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Diary No/Year</td>
                        <td>
                          <strong>
                            {caseDetail?.diaryno}/{caseDetail?.diaryyear}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Case Type/Case No/Year</td>
                        <td>
                          <strong>
                            {caseDetail?.casetype}/{caseDetail?.caseno}/
                            {caseDetail?.caseyear}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Date of Filing</td>
                        <td>
                          <strong>
                            {newMapDate(caseDetail?.dateoffiling)}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Case Status</td>
                        <td>
                          <strong>{caseDetail?.casestatus}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td> Date of Disposal </td>
                        <td>
                          <strong> {caseDetail.dateofdisposal} </strong>
                        </td>
                      </tr>
                      <tr>
                        <td> Nature of Disposal </td>
                        <td>
                          <strong> {caseDetail.disposalNature} </strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                {/* case listing details Tentative */}
                {caseDetail?.courtName && caseDetail?.courtNo && (
                  <div className="table-responsive mb-3">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan="2">
                            <strong>CASE LISTING DETAILS</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>In the Court of</td>
                          <td>
                            <strong>{caseDetail.courtName}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Court No.</td>
                          <td>
                            <strong>{caseDetail.courtNo}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Next Listing Date:</td>
                          <td>
                            <strong>
                              {newMapDate(caseDetail.nextlistingdate)}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Next Listing Purpose:</td>
                          <td>
                            <strong>{caseDetail.nextListingPurpose}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}

                {/* petitioner's details */}
                <div className="table-responsive mb-3">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan="2">
                          <strong>PETITIONER/APPLICANT DETAIL</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> Petitioner Name: </td>
                        <td>
                          <strong>{caseDetail?.petitionerName}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Petitioner/Applicant Address: </td>
                        <td>
                          <strong>
                            {caseDetail?.petitionerApplicantAddress}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td> Additional Party: </td>
                        <td>
                          <strong>{caseDetail?.additionalpartypet}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td> Advocate Name: </td>
                        <td>
                          <strong>{caseDetail?.advocatePetName}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                {/* respondent's details */}
                <div className="table-responsive mb-3">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan="2">
                          <strong>RESPONDENTS/DEFENDANT DETAILS</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> Respondent Name: </td>
                        <td>
                          <strong>{caseDetail?.respondentName}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Respondent/Defendant Address: </td>
                        <td>
                          <strong>
                            {caseDetail?.respondentDefendentAddress}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td> Additional Party: </td>
                        <td>
                          <strong>{caseDetail?.additionalpartyres}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td> Advocate Name: </td>
                        <td>
                          <strong>{caseDetail?.advocateResName}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                {/* case proceeding details */}
                {caseDetail?.caseProceedingDetails?.length > 0 && (
                  <div className="table-responsive mb-3">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan="4">CASE PROCEEDING DETAILS</th>
                        </tr>
                        <tr>
                          <th>Court Name</th>
                          <th>Causelist Date</th>
                          <th>Purpose</th>
                          <th>Order</th>
                        </tr>
                      </thead>
                      <tbody>
                        {caseDetail?.caseProceedingDetails?.map(
                          (item, index) => (
                            <tr key={index}>
                              <td>{item.ascourtName}</td>
                              <td>{newMapDate(item?.causelistdate)}</td>
                              <td>{item.purpose}</td>
                              <td>
                                {item.orderUrl && (
                                  <a target="_blank" href={`${item.orderUrl}`}>
                                    <i className="fas fa-file-pdf"></i>
                                  </a>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}

                {/* ia details */}
                {caseDetail?.iaDetails?.length > 0 && (
                  <div className="table-responsive mb-3">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan="6">IA DETAILS</th>
                        </tr>
                        <tr>
                          <th>Sr. No.</th>
                          <th>IA Diary No.</th>
                          <th>Date of Filling</th>
                          <th>Date of Order</th>
                          <th>View IA</th>
                          <th>View IA Order</th>
                        </tr>
                      </thead>
                      <tbody>
                        {caseDetail?.iaDetails?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.iano}</td>
                            <td>{newMapDate(item.iadateoffiling)}</td>
                            <td>{newMapDate(item.iaorderdate)}</td>
                            <td>
                              {item.iaUrl && (
                                <a
                                  target="_blank"
                                  href={`https://drt.gov.in/drtlive/order/pdf/pdfia.php?file=${item.iaUrl}`}
                                >
                                  <i className="fas fa-file-pdf"></i>
                                </a>
                              )}
                            </td>
                            <td>
                              {item.orderUrl && (
                                <a target="_blank" href={`${item?.orderUrl}`}>
                                  <i className="fas fa-file-pdf"></i>
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}

                {/* // RC Details */}
                {caseDetail?.rcdetail && (
                  <>
                    <div className="table-responsive mb-3">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th colSpan="4">
                              RC DETAILS{" "}
                              <strong>
                                {" "}
                                &nbsp;&nbsp;(
                                {caseDetail?.rcdetail[0]?.diaryno})
                              </strong>
                            </th>
                          </tr>
                          <tr>
                            <th>Court Name</th>
                            <th>RC List Date</th>
                            <th>Purpose</th>
                            <th>RC Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{caseDetail.rcdetail[0]?.courtname}</td>
                            <td>
                              {newMapDate(
                                caseDetail.rcdetail[0]?.nextlistingdate
                              )}
                            </td>
                            <td>
                              {caseDetail.rcdetail[0]?.nextListingPurpose}
                            </td>
                            <td>{caseDetail.rcdetail[0]?.status}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>

                    {/* // RC Proceeding details */}
                    {caseDetail?.rcdetail[0]?.rcCaseProceedingDetails.length >
                      0 && (
                      <div className="table-responsive mb-3">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th colSpan="3">RC CASE PROCEEDING DETAILS</th>
                            </tr>
                            <tr>
                              <th>Court No</th>
                              <th>Cause List Date</th>
                              <th>Purpose</th>
                            </tr>
                          </thead>
                          <tbody>
                            {caseDetail.rcdetail[0]?.rcCaseProceedingDetails?.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>{item.courtNo}</td>
                                  <td>{newMapDate(item.causelistdate)}</td>
                                  <td>{item.purpose}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </>
                )}
              </Modal.Body>
            </Modal>
          </>
        )}
      </Formik>
    </div>
  );
};

export default DiaryNoWise;
