import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { years } from "../../../../../../services/YearService";
import { useState, useEffect, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../../../../constants";
import Captcha from "react-numeric-captcha";

import axios from "axios";
import { axiosBaseURLExternal } from "../../../../../../httpCommon";

const RcTrcView = () => {
  const [drtDratLocationName, setDrtDratLocationName] = useState("");
  const [inputObj, setInputObj] = useState({});
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();
  const refDate = useRef("");
  const [loading, setLoading] = useState(false);

  // state to control Model
  const [isOpenModal, setIsOpenModal] = useState(false);

  // fetching first dropDownData
  const [dratDrtLocations, setDratDrtLocations] = useState([]);
  const fetchDratDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratDrtLocations(sortedLocation);
  };

  // get final data after sending required payload
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);

  //setLoading(true);
  const fetchCaseDetail = async (url, data) => {
    try {
      const response = await axiosBaseURLExternal.post(url, data);
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const initialValues = {
    drtId: "",
    caseType: "",
    caseNo: "",
    caseYear: new Date().getFullYear(),
  };

  const validationSchema = Yup.object({
    drtId: Yup.string().required("Required"),
    caseType: Yup.string().required("Required"),
    caseNo: Yup.string()
      .trim()
      .max(8, "Maximum length is 8 characters")
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required("Required"),
    caseYear: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDrtId: values.drtId,
      caseType: values.caseType,
      dailyFinalRcNo: values.caseNo,
      dailyFinalRcYear: values.caseYear,
    };
    getDrtDratName(values.drtId);

    fetchCaseDetail("getRcTrcNewCreate", caseDetailData);
    setInputObj(values);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    capt.current.refresh();
    // resetForm();
  };

  const doubleDigitDate = (date) => {
    // let date = "25/8/2022"
    if (date) {
      let temp = date.split("-");
      let arr = temp.reverse();
      if (arr[0].length === 1) arr[0] = "0" + arr[0];
      if (arr[1].length === 1) arr[1] = "0" + arr[1];

      return arr.join("/");
    }
  };

  const getDrtDratName = (id) => {
    let name = dratDrtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtDratLocationName(name[0]?.SchemaName);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  DRT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drtId"
                    name="drtId"
                    onChange={handleChange}
                    value={values.drtId}
                    onBlur={handleBlur}
                  >
                    <option>Select DRT</option>
                    {dratDrtLocations
                      .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                      .map((item, index) => (
                        <option value={`${item.schemeNameDrtId}`} key={index}>
                          {item.SchemaName}
                        </option>
                      ))}
                  </select>
                  {touched.drtId && errors.drtId ? (
                    <div className="error">{errors.drtId}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  RC/TRC
                </label>
                <div className="col-sm-7">
                  <select
                    id="caseType"
                    name="caseType"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    value={values.caseType}
                    onBlur={handleBlur}
                  >
                    <option>Select RC/TRC</option>
                    <option value={99999}>RC</option>
                    <option value={44444}>TRC</option>
                  </select>
                  {touched.caseType && errors.caseType ? (
                    <div className="error">{errors.caseType}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  RC/TRC No
                </label>
                <div className="col-sm-7">
                  <input
                    id="caseNo"
                    name="caseNo"
                    type="text"
                    className="form-control"
                    placeholder=" Enter RC/TRC No"
                    onChange={handleChange}
                    value={values.caseNo}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  {touched.caseNo && errors.caseNo ? (
                    <div className="error">{errors.caseNo}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  RC/TRC Year
                </label>
                <div className="col-sm-7">
                  <select
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="caseYear"
                    name="caseYear"
                    onChange={handleChange}
                    value={values.caseYear}
                    onBlur={handleBlur}
                  >
                    {years.map((item, index) => (
                      <option value={item.year} key={index}>
                        {item.year}
                      </option>
                    ))}
                  </select>
                  {touched.caseYear && errors.caseYear ? (
                    <div className="error">{errors.caseYear}</div>
                  ) : null}
                </div>
              </div>

              {/* field 3   */}
              <div className="form-group capcha-sec">
                <div className="col-sm-12 px-0 d-flex">
                  <Captcha
                    onChange={(status) => {
                      setCaptchaStatus(status);
                    }}
                    ref={capt}
                    required
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={!captchaStatus}
                    className="btn btn-reload"
                  >
                    <i className="fas fa-search"><span className="sr-only">no text</span></i>
                  </button>
                </div>
                {!captchaStatus ? (
                  <div className="captcha-text">Enter valid captcha</div>
                ) : null}
              </div>
            </form>
            {loading ? (
              <h6 className="loader">Loading ......</h6>
            ) : (
              <Modal
                show={isOpenModal}
                onHide={() => setIsOpenModal(false)}
                className="rctrc-modal"
                // className="modal-90w"
              >
                <Modal.Header>
                  RC/TRC View <br /> {drtDratLocationName}
                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  {!error && caseDetail?.length > 0 ? (
                    <>
                      <div className="table-responsive mb-3">
                        <Table striped bordered hover>
                          <thead>
                            {/* <tr>
                            <th colSpan="4">CASE PROCEEDING DETAILS</th>
                          </tr> */}
                            <tr>
                              <th>Petitioner Name</th>
                              <th>Respondent Name</th>
                              <th>RC/TRC No.</th>
                              {/* <th>RC_No.</th> */}
                              <th>Create Date</th>
                              <th>Order Pdf</th>
                            </tr>
                          </thead>
                          <tbody>
                            {caseDetail?.map((item) => (
                              <tr>
                                <td>{item.pet_name}</td>
                                <td>{item.res_name}</td>
                                <td>{item.rcno}</td>
                                {/* <td>{item.rc_no}</td> */}
                                <td>{doubleDigitDate(item.order_date)}</td>
                                {/* <td>{item.order_date}</td> */}
                                <td>
                                  {" "}
                                  {item.orderPdf && (
                                    <a
                                      target="_blank"
                                      href={`${item.orderPdf}`}
                                    >
                                      <i className="fas fa-search"><span className="sr-only">no text</span></i>
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <>
                      <tbody>
                        <tr>
                          <td colSpan="6"> No Record Found </td>
                        </tr>
                      </tbody>
                    </>
                  )}
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default RcTrcView;
