import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "./App.css";
//import { Provider } from "react-redux";
//import store from "./redux/store";
import { DrtProvider } from "./context/DrtContext";

//css
// import Home from './components/front/home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//JS
// import   'jquery/dist/jquery.min.js';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import { createHashHistory } from "history";

// const history = createHashHistory({
//   basename: "/DRT",
//   hashType: "slash",
// });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <HashRouter history={history}>
  <HashRouter>
    <DrtProvider>
      <App />
    </DrtProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
