import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import axiosBaseURL, { axiosBaseURLExternal } from "../../../httpCommon";
import CryptoJS from "crypto-js";
import { DrtContext } from "../../../context/DrtContext";

const IncidentReportForm = () => {
  const { EncryptDecrypt } = useContext(DrtContext);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);
  const [dratDrtLocations, setDratDrtLocations] = useState([]);

  // form begins

  // fetching DRT/DRAT Locations
  const fetchDratDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    setDratDrtLocations(res.data);
  };

  // get final data after sending required payload
  const [formData, setFormData] = useState("");
  const PostFormData = async (url, data) => {
    // console.log("form values are", data);
    // console.log("base url is", axiosBaseURL, "url is", url);
    try {
      // setLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = await axiosBaseURL.post(url, data, config);
      // console.log("response",result.data)
      const temp = EncryptDecrypt(result.data);
      // console.log("temp",temp)
      setFormData(temp);
    } catch (error) {
      console.log(error);
      setFormData(error.response.data);
    }
    // setLoading(false);
    // console.log("hello");
  };

  const initialValues = {
    name: "",
    email: "",
    mobileNo: "",
    gender: "",
    schemeNameDrtId: "",
    issueRelated: "",
    issueType: "",
    priority: "",
    subject: "",
    issueDescription: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log("inside onSubmit");
    console.log("form values", values);

    const formPayload = {
      name: values.name,
      email: values.email,
      mobile: Number(values.mobileNo),
      gender: values.gender,
      drats_loc: Number(values.schemeNameDrtId),
      issue_related: Number(values.issueRelated),
      issue_type: Number(values.issueType),
      priority: Number(values.priority),
      subject: values.subject,
      description: values.issueDescription,
    };

    // const cryptPayload = CryptoJS.AES.encrypt(
    //   JSON.stringify(formPayload),
    //   "ostrich"
    // ).toString();
    // console.log('crypted text is',cryptPayload)

    // PostFormData("helpdeskpost", JSON.stringify(cryptPayload));
    //  setTimeout(() => {
    //    setIsOpenModal(true);
    //  }, 1000);

    // resetForm();
  };

  const validate = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = "Required";
    } else if (!/^[A-Za-z\s]*$/.test(values.name)) {
      errors.name = "enter only letters";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (!values.email.includes("@")) {
      errors.email = "enter valid email";
    }
    if (!values.mobileNo) {
      errors.mobileNo = "Required";
    } else if (!/^\d+$/.test(values.mobileNo)) {
      errors.mobileNo = "enter only digits";
    }
    if (!values.schemeNameDrtId) {
      errors.schemeNameDrtId = "Required";
    }
    if (!values.issueRelated) {
      errors.issueRelated = "Required";
    }
    if (!values.issueType) {
      errors.issueType = "Required";
    }
    if (!values.priority) {
      errors.priority = "Required";
    }
    if (!values.subject) {
      errors.subject = "Required";
    }
    if (!values.issueDescription) {
      errors.issueDescription = "Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  // form ends

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const success = () => {
    if (formData?.status === true) setIsOpenModal2(true);
  };

  useEffect(() => {
    // console.log("form api response");
    success();
  }, [formData]);

  return (
    <>
      {/* <button className="btn btn-reload" onClick={() => setIsOpenModal(true)}>
        Incident Report Form
      </button> */}
      <a
        className="btn btn-reload"
        href="https://drt.etribunals.gov.in/edrt/help_center.php"
      >
        Incident Report Form
      </a>
      <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
        <Modal.Header className="help">
          <h3> Incident Report Form while using e-DRT portal by users </h3>
          {/* <strong>
            <a
              href="https://drt.gov.in/DRT_application/public/research/uploads/631720bec55d5_helpdesk.pdf"
              target="_blank"
            >
              <u> User Manual For Help Desk </u>
            </a>
          </strong> */}

          <Button
            type="button"
            className="close"
            onClick={() => setIsOpenModal(false)}
          >
            &times;
          </Button>
        </Modal.Header>
        <Modal.Body>
          <>
            <form onSubmit={formik.handleSubmit}>
              {/* Form Row 1 start */}
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label className="mandatory" htmlFor="">
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    maxLength="100"
                    className="form-control"
                    placeholder="Full Name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="error">{formik.errors.name}</div>
                  ) : null}
                </div>

                {/* Row 1 Field 2 */}
                <div className="form-group col-md-4">
                  <label className="mandatory" htmlFor="">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    maxlength="50"
                    className="form-control"
                    placeholder="Enter Email ID"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </div>

                {/* Row 1 Field 3 */}
                <div className="form-group col-md-4">
                  <label className="mandatory" htmlFor="">
                    Mobile
                  </label>
                  <input
                    id="mobileNo"
                    name="mobileNo"
                    type="text"
                    maxlength="10"
                    // pattern="[A-Za-z0-9]+"
                    // title="np special character"
                    className="form-control"
                    placeholder="Enter Mobile No"
                    onChange={formik.handleChange}
                    value={formik.values.mobileNo}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.mobileNo && formik.errors.mobileNo ? (
                    <div className="error">{formik.errors.mobileNo}</div>
                  ) : null}
                </div>
              </div>

              {/* Form Row 2 start */}
              <div className="form-row">
                {/* <div className="form-group col-md-7">
                  <label className="mandatory" htmlFor="inputEmail4">
                    DRAT/DRT
                  </label>
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="schemeNameDrtId"
                    name="schemeNameDrtId"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.schemeNameDrtId}
                    onBlur={formik.handleBlur}
                  >
                    <option>Select DRAT/DRT Location</option>
                    {dratDrtLocations
                      .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                      .map((item, index) => (
                        <option value={`${item.schemeNameDrtId}`} key={index}>
                          {item.SchemaName}
                        </option>
                      ))}
                  </select>
                  {formik.touched.schemeNameDrtId &&
                  formik.errors.schemeNameDrtId ? (
                    <div className="error">{formik.errors.schemeNameDrtId}</div>
                  ) : null}
                </div> */}
                <div className="form-group col-md-4">
                  <label className="mandatory" htmlFor="inputEmail4">
                    Gender
                  </label>
                  <select
                    className="form-control"
                    placeholder="Select Issue"
                    type="text"
                    id="issueType"
                    name="issueType"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.gender}
                    onBlur={formik.handleBlur}
                  >
                    <option> select </option>
                    <option value="1">APPLICATION NOT WORKING</option>
                    <option value="2">PAYMENT RELATED ISSUES</option>
                    <option value="3">CASE REGISTRATION</option>
                    <option value="4">USER REGISTRATION</option>
                    <option value="5">DATA NOT VISIBLE</option>
                    <option value="6">DOCUMENT RELATED ISSUE</option>
                    <option value="7">OTHER</option>
                  </select>
                  {formik.touched.issueType && formik.errors.issueType ? (
                    <div className="error">{formik.errors.issueType}</div>
                  ) : null}
                </div>
                <div className="form-group col-md-4">
                  <label className="mandatory" htmlFor="inputEmail4">
                    Type of Issue
                  </label>
                  <select
                    className="form-control"
                    placeholder="Select Issue"
                    type="text"
                    id="issueType"
                    name="issueType"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.issueType}
                    onBlur={formik.handleBlur}
                  >
                    <option> select </option>
                    <option value="1">APPLICATION NOT WORKING</option>
                    <option value="2">PAYMENT RELATED ISSUES</option>
                    <option value="3">CASE REGISTRATION</option>
                    <option value="4">USER REGISTRATION</option>
                    <option value="5">DATA NOT VISIBLE</option>
                    <option value="6">DOCUMENT RELATED ISSUE</option>
                    <option value="7">OTHER</option>
                  </select>
                  {formik.touched.issueType && formik.errors.issueType ? (
                    <div className="error">{formik.errors.issueType}</div>
                  ) : null}
                </div>

                {/* Row 2 Field 2 */}
                <div className="form-group col-md-4">
                  <label className="mandatory" htmlFor="inputEmail4">
                    Type of Issue
                  </label>
                  <select
                    className="form-control"
                    placeholder="Select Issue"
                    type="text"
                    id="issueType"
                    name="issueType"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.issueType}
                    onBlur={formik.handleBlur}
                  >
                    <option> select </option>
                    <option value="1">APPLICATION NOT WORKING</option>
                    <option value="2">PAYMENT RELATED ISSUES</option>
                    <option value="3">CASE REGISTRATION</option>
                    <option value="4">USER REGISTRATION</option>
                    <option value="5">DATA NOT VISIBLE</option>
                    <option value="6">DOCUMENT RELATED ISSUE</option>
                    <option value="7">OTHER</option>
                  </select>
                  {formik.touched.issueType && formik.errors.issueType ? (
                    <div className="error">{formik.errors.issueType}</div>
                  ) : null}
                </div>
              </div>
              {/* Form Row 2 end */}

              {/* Form Row 3 start */}
              <div className="form-row">
                {/* Row 3 Field 1 */}
                <div className="form-group col-md-6">
                  <label className="mandatory" htmlFor="inputEmail4">
                    Issue Related
                  </label>
                  <select
                    className="form-control"
                    placeholder="Select Issue"
                    type="text"
                    id="issueRelated"
                    name="issueRelated"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.issueRelated}
                    onBlur={formik.handleBlur}
                  >
                    <option> select </option>
                    <option value="1">Administrative</option>
                    <option value="2">Technical Support</option>
                  </select>
                  {formik.touched.issueRelated && formik.errors.issueRelated ? (
                    <div className="error">{formik.errors.issueRelated}</div>
                  ) : null}
                </div>

                {/* blank div */}
                <div className="form-group col-md-1"></div>

                {/* Row 3 Field 2 */}
                <div className="form-group col-md-5">
                  <label className="mandatory" htmlFor="inputEmail4">
                    Priority
                  </label>
                  <select
                    className="form-control"
                    placeholder="Select "
                    type="text"
                    id="priority"
                    name="priority"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.priority}
                    onBlur={formik.handleBlur}
                  >
                    <option> select </option>
                    <option value="1">Low</option>
                    <option value="2">Medium</option>
                    <option value="3">High</option>
                  </select>
                  {formik.touched.priority && formik.errors.priority ? (
                    <div className="error">{formik.errors.priority}</div>
                  ) : null}
                </div>
              </div>
              {/* Form Row 3 end */}

              {/* Form Row 4 start */}
              <div className="form-row">
                {/* Row 4 Field 1 */}
                <div className="form-group col-md-12">
                  <label className="mandatory" htmlFor="inputEmail4">
                    Subject
                  </label>
                  <input
                    id="subject"
                    name="subject"
                    type="text"
                    maxlength="150"
                    className="form-control"
                    placeholder="Enter Subject"
                    onChange={formik.handleChange}
                    value={formik.values.subject}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.subject && formik.errors.subject ? (
                    <div className="error">{formik.errors.subject}</div>
                  ) : null}
                </div>
              </div>
              {/* Form Row 4 end */}

              {/* Form Row 5 start */}
              <div className="form-row">
                {/* Row 5 Field 1 */}
                <div className="form-group col-md-12">
                  <label className="mandatory" htmlFor="inputEmail4">
                    Description of Issue
                  </label>
                  <textarea
                    id="issueDescription"
                    name="issueDescription"
                    type="text"
                    rows="6"
                    maxlength="1000"
                    className="form-control"
                    placeholder="Write Here"
                    onChange={formik.handleChange}
                    value={formik.values.issueDescription}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.issueDescription &&
                  formik.errors.issueDescription ? (
                    <div className="error">
                      {formik.errors.issueDescription}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Form Row 5 end */}

              <div className="d-flex justify-content-center">
                <button className="btn btn-primary">Submit</button>
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>

      <Modal
        className="customclass"
        show={isOpenModal2}
        onHide={() => setIsOpenModal2(false)}
      >
        <Modal.Header className="help innermodalheader">
          {/* Form Status */}
          <Button
            type="button"
            className="close"
            onClick={() => window.location.reload()}
          >
            &times;
          </Button>
        </Modal.Header>
        <Modal.Body className="innermodal">
          <>
            <h1>Your Query has submitted successfully</h1>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IncidentReportForm;
