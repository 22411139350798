import React, { useState } from "react";
import AuctionNotice from "./AuctionNotice";
import PublicationNotice from "./PublicationNoice";
import TenderNotice from "./TenderNotice";
import ActAndRuleParent from "../../Acts/ActAndRuleParent";
import PublicNotice from "./PublicNotice";

const Notices = () => {
  const [relatedLink, setRelatedLink] = useState("PublicNotice");
  return (
    <>
      <div className="tender-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <ul
                className="nav d-flex justify-content-center"
                id="pills-tab-btm"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    onClick={() => setRelatedLink("AuctionNotice")}
                    className={
                      relatedLink === "AuctionNotice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id=""
                    data-toggle="pill"
                    href="#pills-auction"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Auction Notice
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setRelatedLink("PublicationNotice")}
                    className={
                      relatedLink === "PublicationNotice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id=""
                    data-toggle="pill"
                    href="#pills-pn"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Publication Notice
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setRelatedLink("TenderNotice")}
                    className={
                      relatedLink === "TenderNotice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id=""
                    data-toggle="pill"
                    href="#pills-tender"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Tender Notice
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setRelatedLink("PublicNotice")}
                    className={
                      relatedLink === "PublicNotice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id=""
                    data-toggle="pill"
                    href="#pills-filling"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Public Notice
                  </a>
                </li>
              </ul>
              <div className="tab-content notices-sec" id="pills-tabContent">
                {relatedLink === "AuctionNotice" ? (
                  <div
                    className="tab-pane fade show active"
                    id="pills-auction"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <AuctionNotice  />
                  </div>
                ) : relatedLink === "PublicationNotice" ? (
                  <div
                    className="tab-pane fade show active"
                    id="pills-pn"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <PublicationNotice  />
                  </div>
                ) : relatedLink === "TenderNotice" ? (
                  <div
                    className="tab-pane fade show active"
                    id="pills-tender"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <TenderNotice  />
                  </div>
                ) : relatedLink === "PublicNotice" ? (
                  <div
                    className="tab-pane fade show active"
                    id="pills-filling"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <PublicNotice  />
                  </div>
                ) : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Notices;
