import { useState } from "react";
import CaseTransferHome from "./CaseTransferHome"

const CaseTransferParentHome = () => {
  const [relatedLink, setRelatedLink] = useState("CaseTransfer");
  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-0">
          <ul className="nav toptab-two mb-3" id="v-pills-tab" role="tablist">
            <li className="nav-item">
              <a
                className={
                  relatedLink === "CaseTransfer"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#pills-drt"
                role="tab"
                aria-controls="pills-drt"
                aria-selected="true"
                onClick={() => setRelatedLink("CaseTransfer")}
              >
                Case Transfer
              </a>
            </li>

          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 pr-0">
          <div className="tab-content tab-form" id="pills-tabContent">
            {relatedLink === "CaseTransfer" ? (
              <div
                className="tab-pane fade show active"
                id="pills-drt"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <CaseTransferHome />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseTransferParentHome;
