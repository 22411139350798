import React, { useState, useEffect } from "react";
import BredCrumb from "./BredCrumb";
import { axiosBaseURLExternal } from "../../httpCommon";
import InternalBaseBanner from "./InternalBaseBanner";
import { useTitle } from "../../services/PageTitle";

const Tender = () => {
  useTitle("Tender")
  const [loading, setLoading] = useState(false);
  const [showArchive, setShowArchive] = useState(false)

  // DRAT Locations Dropdown
  const [drtLocations, setDrtLocations] = useState([]);
  const [tenderList, setTenderList] = useState([]);

  const fetchDrtLocations = async () => {
    try {
      const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
      setDrtLocations(res.data);
    } catch (error) {
      setDrtLocations(null);
    }
  };

  useEffect(() => {
    fetchDrtLocations();
    const data = {
      schemeNameDrtId: "1",
    };
    fetchTenderList(data);
  }, []);

  const fetchTenderList = async (data) => {
    try {
      setLoading(true);
      const res = await axiosBaseURLExternal.post("getTenderNotice", data);
      setTenderList(
        res.data.map((item, index) => ({ ...item, sr: index + 1 }))
      );
      setLoading(false);
    } catch (error) {
      setTenderList(null);
    }
  };

  const [drtDratLocationName, setDrtDratLocationName] = useState(
    "DEBTS RECOVERY TRIBUNAL DELHI(DRT 1)"
  );
  const getDrtDratName = (id) => {
    let name = drtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtDratLocationName(name[0].SchemaName);
  };

  return (
    <div>
      <InternalBaseBanner title={"Tender "} />
      <BredCrumb title={"Tender "} />
      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="about-content">
                <h6>Tender</h6>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-4">
              <div className="ml-auto">
                <select
                  className="form-control"
                  name=""
                  id=""
                  onChange={(e) => {
                    const data = {
                      schemeNameDrtId: e.target.value,
                    };
                    fetchTenderList(data);
                    getDrtDratName(e.target.value);
                  }}
                  placeholder="drat"
                >
                  <option>Select DRAT/DRT Location</option>
                  {drtLocations &&
                    drtLocations
                      // .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                      .map((item, index) => (
                        <option value={`${item.schemeNameDrtId}`} key={index}>
                          {item.SchemaName}
                        </option>
                      ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <button className="btn btn-warning mb-3" onClick={() => setShowArchive(!showArchive)} > { showArchive ? "Show last 12 months Notices" : "Show Archive" }</button>
              <div className="table-responsive">
                <table className="table table-striped common-table bluetable">
                  <thead>
                    <tr>
                      <th>Sl. No.</th>
                      <th>DRT Name</th>
                      <th>Summary</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="5">
                          <h6 className="loader">Loading ...</h6>
                        </td>
                      </tr>
                    ) : tenderList && tenderList.length > 0 ? (
                      tenderList?.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{drtDratLocationName}</td>
                            <td>{item.massage}</td>
                            <td>
                              <a target="_blank" href={`${item.auctionurl}`}>
                                <i className="fas fa-file-pdf"><span className="sr-only">no text</span></i>
                              </a>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          <strong>
                            <p>No Data Found Select  DRAT/DRT ...</p>
                          </strong>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tender;
