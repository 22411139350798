import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { DrtContext } from "../context/DrtContext";

const ExternalLinkPopup = () => {
  const {
    externalLink,
    setExternalLink,
    setSource,
    showExternalLinkPopup,
    setExternalLinkPopupShow,
  } = useContext(DrtContext);

  console.log(" external link is ", externalLink);
  return (
    <>
      <Modal
        className="login-modal"
        show={showExternalLinkPopup}
        onHide={() => {
          setExternalLink("");
          setExternalLinkPopupShow(false);
          setSource("");
        }}
      >
        <Modal.Header>
          <Modal.Title>External Links</Modal.Title>
          <Button
            type="button"
            className="close"
            onClick={() => {
              setExternalLink("");
              setExternalLinkPopupShow(false);
              setSource("");
            }}
          >
            &times;
          </Button>
        </Modal.Header>
        <Modal.Body>
          You are being redirected to an external website. Click yes to continue{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setExternalLink("");
              setExternalLinkPopupShow(false);
              setSource("");
            }}
          >
            No
          </Button>
          <Button
            variant="primary"
            href={externalLink}
            target="_blank"
            onClick={() => {
              setExternalLink("");
              setExternalLinkPopupShow(false);
              setSource("");
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExternalLinkPopup;
