import React from "react";
import Captcha from "react-numeric-captcha";
import { useState, useEffect, useRef } from "react";
import { DrtContext } from "../../../../../context/DrtContext";

import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../../../constants";
import { years } from "../../../../../services/YearService";

import axios from "axios";
import { useFormik } from "formik";

function DiaryNoFinalOrder() {
  const [loading, setLoading] = useState(false);

  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  const [isOpenModal, setIsOpenModal] = useState(false);

  // state for first dropDownData
  const [dratDrtLocations, setDratDrtLocations] = useState([]);

  // fetching first dropDownData
  const fetchDratDrtLocation = async () => {
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getDrtDratScheamName`
    );
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratDrtLocations(sortedLocation);
  };

  // get final data after sending required payload
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  const initialValues = {
    schemeNameDrtId: "",
    diaryNo: "",
    diaryYear: new Date().getFullYear(),
  };

  const onSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDrtId: values.schemeNameDrtId,
      diaryNo: values.diaryNo,
      diaryYear: values.diaryYear,
    };

    fetchCaseDetail("getDrtFinalOrderReportDiaryNo", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  // validation
  const validate = (values) => {
    let errors = {};
    if (!values.schemeNameDrtId) {
      errors.schemeNameDrtId = "Required";
    }
    if (!values.diaryNo.trim()) {
      errors.diaryNo = "Required";
    } else if (values.diaryNo.trim().length > 8) {
      errors.diaryNo = "Maximum length is 8 characters";
    } else if (!/^[0-9]+$/.test(values.diaryNo.trim())) {
      errors.diaryNo = "Only numbers are allowed";
    }
    if (!values.diaryYear) {
      errors.diaryYear = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const doubleDigitDate = (date) => {
    // let date = "25/8/2022"
    if (date) {
      let arr = date.split("/");
      if (arr[0].length === 1) arr[0] = "0" + arr[0];
      if (arr[1].length === 1) arr[1] = "0" + arr[1];

      return arr.join("/");
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="w-100">
        <div className="form-row">
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="staticEmail">
              {" "}
              DRT
            </label>

            <select
              className="form-control"
              placeholder=""
              type="text"
              id="schemeNameDrtId"
              name="schemeNameDrtId"
              onChange={(e) => {
                formik.handleChange(e);
                // fetchCaseType(e.target.value)
              }}
              value={formik.values.schemeNameDrtId}
              onBlur={formik.handleBlur}
            >
              <option>Select DRT Location</option>
              {dratDrtLocations
                .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                .map((item, index) => (
                  <option value={`${item.schemeNameDrtId}`} key={index}>
                    {item.SchemaName}
                  </option>
                ))}
            </select>
            {formik.touched.schemeNameDrtId && formik.errors.schemeNameDrtId ? (
              <div className="error">{formik.errors.schemeNameDrtId}</div>
            ) : null}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              Diary No
            </label>

            <input
              id="diaryNo"
              name="diaryNo"
              type="text"
              className="form-control"
              placeholder="Enter Diary No"
              onChange={formik.handleChange}
              value={formik.values.diaryNo}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.diaryNo && formik.errors.diaryNo ? (
              <div className="error">{formik.errors.diaryNo}</div>
            ) : null}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              Diary Year
            </label>

            <select
              type="text"
              className="form-control"
              placeholder=""
              id="diaryYear"
              name="diaryYear"
              onChange={formik.handleChange}
              value={formik.values.diaryYear}
              onBlur={formik.handleBlur}
            >
              {years.map((item, index) => (
                <option value={item.year} key={index}>
                  {item.year}
                </option>
              ))}
            </select>
            {formik.touched.diaryYear && formik.errors.diaryYear ? (
              <div className="error">{formik.errors.diaryYear}</div>
            ) : null}
          </div>

          <div className="form-group col-md-8 main-menu-captcha capcha-sec">
            <label>Captcha</label>
            <div className="col-sm-12 px-0 d-flex">
              <Captcha
                onChange={(status) => {
                  setCaptchaStatus(status);
                }}
                ref={capt}
                required
              />
              <br />
              <button
                type="submit"
                disabled={!captchaStatus}
                className="btn btn-reload"
              >
                <i className="fas fa-search"><span className="sr-only">no text</span></i>
              </button>
            </div>
            {!captchaStatus ? (
              <div className="captcha-text">Enter valid captcha</div>
            ) : null}
          </div>
        </div>
      </form>

      {loading ? (
        <h6 className="loader">Loading ......</h6>
      ) : (
        <Modal
          show={isOpenModal}
          onHide={() => setIsOpenModal(false)}
          // className="modal-90w"
        >
          <Modal.Header>
            DRT's Final Order Diary No.
            <Button
              type="button"
              className="close"
              onClick={() => setIsOpenModal(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Applicant vs Respondent</th>
                    <th>Diary No.</th>
                    <th>Application No.</th>
                    <th>Date of Order</th>
                    <th>Pronounced By</th>
                    <th>View Daily Order(s)</th>
                  </tr>
                </thead>
                {!error && caseDetail && caseDetail.length > 0 ? (
                  <tbody>
                    {caseDetail &&
                      caseDetail.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.applicantName} <br /> vs <br />{" "}
                            {item.respondentName}
                          </td>
                          <td>{item.diaryno}</td>
                          <td>{item.applicantno}</td>
                          <td>{doubleDigitDate(item.dateoffiling)}</td>
                          <td>{item.pronouncedBy}</td>
                          <td style={{ textAlign: "center" }}>
                            <a target="_blank" href={`${item.dailyOrderPdf}`}>
                              <i className="fas fa-search"><span className="sr-only">no text</span></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="8"> No Record Found </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default DiaryNoFinalOrder;
