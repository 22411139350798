import { useState } from "react";
import RcTrcCaseStatus from "./RcTrcCaseStatus";
import RcTrcView from "./RcTrcView";

const RcTrcCaseStatusParent = () => {
  const [relatedLink, setRelatedLink] = useState("CaseNoDailyOrder");
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 tab-one p-0">
        <ul
          className="nav toptab-one mb-3 inner-tab mx-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={
                relatedLink === "CaseNoDailyOrder"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("CaseNoDailyOrder")}
            >
              RC/TRC Case Status
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "View"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("View")}
            >
              RC/TRC View
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {relatedLink === "CaseNoDailyOrder" ? (
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              // aria-labelledby="pills-home-tab"
            >
              <RcTrcCaseStatus />
            </div>
          ) : (
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              // aria-labelledby="pills-home-tab"
            >
              <RcTrcView />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RcTrcCaseStatusParent;
