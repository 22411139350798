import React, { useState, useEffect, useContext, useRef } from "react";
import Captcha from "react-numeric-captcha";
import { DrtContext } from "../../../context/DrtContext";
import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../constants";
import axios from "axios";
import { useFormik } from "formik";

function DrtCauseList() {
  const [loading, setLoading] = useState(false);
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [dratDrtLocations, setDratDrtLocations] = useState([]);
  const [courtNameList, setCourtNameList] = useState([]);
  const [courtNumberList, setCourtNumberList] = useState([]);
  const [dateError, setDateError] = useState("");

  const refDrtLocation = useRef("");
  const refDate = useRef("");

  const fetchDratDrtLocation = async () => {
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getDrtDratScheamName`
    );
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratDrtLocations(sortedLocation);
  };

  const fetchCourtName = async (data) => {
    console.log("data is ", data);
    setDateError("");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getRcCourtName`,
      data,
      config
    );
    if (res.data.status) {
      setCourtNameList([]);
      setDateError("No Record Available for this date");
    } else {
      setCourtNameList(res.data);
    }
  };

  const fetchCourtNumber = async (data) => {
    console.log(" fetchCourtNumber data is", data);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getRcCourtNo`,
      data,
      config
    );
    setCourtNumberList(res.data);
  };

  // get final data after sending required payload

  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  const initialValues = {
    schemeNameDrtId: "",
    causeListDate: "",
    courtNameId: "",
    courtNo: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log("inside onSubmit");
    console.log("form values", values);

    const caseDetailData = {
      schemeNameDrtId: values.schemeNameDrtId,
      causeListDate: refDate.current,
      courtNameId: values.courtNameId,
      courtNo: values.courtNo,
    };

    fetchCaseDetail("getRcCauselistReport", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  const validate = (values) => {
    let errors = {};

    if (!values.schemeNameDrtId) {
      errors.schemeNameDrtId = "Required";
    }
    if (!values.causeListDate) {
      errors.causeListDate = "Required";
    }
    if (!values.courtNameId) {
      errors.courtNameId = "Required";
    }
    if (!values.courtNo) {
      errors.courtNo = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const locationAndDate = (value, name) => {
    if (name === "schemeNameDrtId") refDrtLocation.current = value;

    if (name === "causeListDate") {
      let newDate = value.split("-").reverse().join("/");
      refDate.current = newDate;
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="inputEmail4">
              {" "}
              DRT
            </label>
            {/* Field 1 */}
            <select
              className="form-control"
              placeholder=""
              type="text"
              id="schemeNameDrtId"
              name="schemeNameDrtId"
              onChange={(e) => {
                formik.handleChange(e);
                locationAndDate(e.target.value, e.target.name);
              }}
              value={formik.values.schemeNameDrtId}
              onBlur={formik.handleBlur}
            >
              <option>Select DRT Location</option>
              {dratDrtLocations
                .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                .map((item, index) => (
                  <option value={`${item.schemeNameDrtId}`} key={index}>
                    {item.SchemaName}
                  </option>
                ))}
            </select>
            {formik.touched.schemeNameDrtId && formik.errors.schemeNameDrtId ? (
              <div className="error">{formik.errors.schemeNameDrtId}</div>
            ) : null}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="inputEmail4">
              Date
            </label>
            {/* Field 2 */}
            <input
              className="form-control"
              placeholder=""
              type="date"
              id="causeListDate"
              name="causeListDate"
              onChange={(e) => {
                formik.handleChange(e);
                locationAndDate(e.target.value, e.target.name);
                const data = {
                  schemeNameDrtId: refDrtLocation.current,
                  listingDate: refDate.current,
                };
                fetchCourtName(data);
              }}
              value={formik.values.causeListDate}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.causeListDate && formik.errors.causeListDate ? (
              <div className="error">{formik.errors.causeListDate}</div>
            ) : null}
            {dateError === "" ? null : <div className="error">{dateError}</div>}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="inputEmail4">
              Court Name
            </label>
            {/* Field 3 */}
            <select
              className="form-control"
              placeholder=""
              type="text"
              id="courtNameId"
              name="courtNameId"
              onChange={(e) => {
                formik.handleChange(e);
                const data = {
                  schemeNameDrtId: refDrtLocation.current,
                  listingDate: refDate.current,
                  courtNameId: e.target.value,
                };
                fetchCourtNumber(data);
              }}
              value={formik.values.courtNameId}
              onBlur={formik.handleBlur}
            >
              <option>Select Court Name</option>
              {courtNameList.map((item, index) => (
                <option value={`${item.courtNameId}`} key={index}>
                  {item.benchName}
                </option>
              ))}
            </select>
            {formik.touched.courtNameId && formik.errors.courtNameId ? (
              <div className="error">{formik.errors.courtNameId}</div>
            ) : null}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="inputEmail4">
              Court No
            </label>
            {/* Field 4 */}
            <select
              className="form-control"
              placeholder=""
              type="text"
              id="courtNo"
              name="courtNo"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.courtNo}
              onBlur={formik.handleBlur}
            >
              <option>Select Court No</option>
              {courtNumberList.map((item, index) => (
                <option value={`${item.courtNo}`} key={index}>
                  {item.courtNo}
                </option>
              ))}
            </select>
            {formik.touched.courtNo && formik.errors.courtNo ? (
              <div className="error">{formik.errors.courtNo}</div>
            ) : null}
          </div>

          <div className="form-group col-md-8 main-menu-captcha capcha-sec">
            <label>Captcha</label>
            <div className="col-sm-12 px-0 d-flex">
              <Captcha
                onChange={(status) => {
                  setCaptchaStatus(status);
                }}
                ref={capt}
                required
              />
              <br />
              <button
                type="submit"
                disabled={!captchaStatus}
                className="btn btn-reload"
              >
                <i className="fas fa-search"><span className="sr-only">no text</span></i>
              </button>
            </div>
            {!captchaStatus ? (
              <div className="captcha-text">Enter valid captcha</div>
            ) : null}
          </div>
        </div>
      </form>

      {loading ? (
        <h6 className="loader">Loading ......</h6>
      ) : (
        <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
          <Modal.Header>
            DRT Cause List Report
            <Button
              type="button"
              className="close"
              onClick={() => setIsOpenModal(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {!error && caseDetail !== null ? (
                <>
                  <a
                    className="btn search  mr-2"
                    target="_blank"
                    href={caseDetail.dailyCauseListLink}
                  >
                    {`Print Cause List Report ${refDate.current}`}
                  </a>
                  <a
                    className="btn search"
                    target="_blank"
                    href={caseDetail.supplementaryCauseListLink}
                  >
                    {`Print Supplementary Cause List Report ${refDate.current}`}
                  </a>
                </>
              ) : (
                <>
                  <tbody>
                    <tr>
                      <td colSpan={"9"}> No Record Found </td>
                    </tr>
                  </tbody>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default DrtCauseList;
