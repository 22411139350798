import { useState } from "react";

import DratCauseList from "./DratCauseList";
import DrtCauseList from "./DrtCauseList";
import RcCauseList from "./RcCauseList";
const CauseListHome = () => {
  const [relatedLink, setRelatedLink] = useState("DRT Cause List");

  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-0">
          <ul className="nav toptab-two mb-3" id="v-pills-tab" role="tablist">
            <li className="nav-item">
              <a
                // className="nav-link"
                className={
                  relatedLink === "DRT Cause List"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#pills-status"
                role="tab"
                aria-controls="pills-status"
                aria-selected="false"
                onClick={() => setRelatedLink("DRT Cause List")}
              >
                DRT Cause List
              </a>
            </li>
            <li className="nav-item">
              <a
                // className="nav-link active"
                className={
                  relatedLink === "RC Cause List"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#pills-drt"
                role="tab"
                aria-controls="pills-drt"
                aria-selected="true"
                onClick={() => setRelatedLink("RC Cause List")}
              >
                RC Cause List
              </a>
            </li>
            <li className="nav-item">
              <a
                // className="nav-link"
                className={
                  relatedLink === "DRAT Cause List"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#party-name"
                role="tab"
                aria-controls="pills-status"
                aria-selected="false"
                onClick={() => setRelatedLink("DRAT Cause List")}
              >
                DRAT Cause List
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 pr-0">
          <div className="tab-content tab-form" id="pills-tabContent">
            {relatedLink === "DRT Cause List" ? (
              <div
                className="tab-pane fade show active"
                id="pills-drt"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <DrtCauseList />
              </div>
            ) : relatedLink === "RC Cause List" ? (
              <div
                className="tab-pane fade show active"
                id="pills-status"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <RcCauseList />
              </div>
            ) : (
              <div
                className="tab-pane fade show active"
                id="party-name"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <DratCauseList />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CauseListHome;
