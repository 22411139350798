import React from "react";
import Captcha from "react-numeric-captcha";
import { useState, useEffect, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../../../constants";
import { years } from "../../../../../services/YearService";
import axios from "axios";
import { useFormik } from "formik";

const CaseNumber = () => {
  const [loading, setLoading] = useState(false);

  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  // state to control Model
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [dratDrtLocations, setDratDrtLocations] = useState([]);
  const [caseTypeDrt, setCaseTypeDrt] = useState([]);

  // const currentYear = new Date();

  // fetching first dropDownData
  const fetchDratDrtLocation = async () => {
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getDrtDratScheamName`
    );
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratDrtLocations(sortedLocation);
  };

  // fetching second dropDownData
  const fetchCaseType = async (value) => {
    const data = {
      schemeNameDrtId: value,
    };
    console.log("data id is", data.schemeNameDrtId);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getDrtDratCaseTyepName`,
      data,
      config
    );

    const sortedCaseType = res.data.sort((a, b) =>
      a.caseTypeName > b.caseTypeName
        ? 1
        : b.caseTypeName > a.caseTypeName
        ? -1
        : 0
    );
    setCaseTypeDrt(sortedCaseType);
  };

  // get final data after sending required payload

  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const initialValues = {
    schemeNameDrtId: "",
    dratDailyFinalOrderId: "",
    caseType: "",
    caseNo: "",
    caseYear: new Date().getFullYear(),
  };

  const validate = (values) => {
    let errors = {};

    if (!values.schemeNameDrtId) {
      errors.schemeNameDrtId = "Required";
    }
    if (!values.dratDailyFinalOrderId) {
      errors.dratDailyFinalOrderId = "Required";
    }
    if (!values.caseType) {
      errors.caseType = "Required";
    }
    if (!values.caseNo.trim()) {
      errors.caseNo = "Required";
    } else if (values.caseNo.trim().length > 8) {
      errors.caseNo = "Maximum length is 8 characters";
    } else if (!/^[0-9]+$/.test(values.caseNo.trim())) {
      errors.caseNo = "Only numbers are allowed";
    }
    if (!values.caseYear) {
      errors.caseYear = "Required";
    }

    return errors;
  };

  const onSubmit = (values, { resetForm }) => {
    console.log("form values", values);
    console.log(" 1st dropdown selection id", formik.values.schemeNameDrtId);

    const caseDetailData = {
      schemeNameDrtId: values.schemeNameDrtId,
      dratDailyFinalOrderId: values.dratDailyFinalOrderId,
      caseType: values.caseType,
      caseNo: values.caseNo,
      caseYear: values.caseYear,
    };

    fetchCaseDetail("getDratOrderJudgementReportCaseNo", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const doubleDigitDate = (date) => {
    // let date = "25/8/2022"
    if (date) {
      let arr = date.split("/");
      if (arr[0].length === 1) arr[0] = "0" + arr[0];
      if (arr[1].length === 1) arr[1] = "0" + arr[1];

      return arr.join("/");
    }
  };

  return (
    <>
      {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 about-content"> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row">
          {/* field 1 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="staticEmail">
              {" "}
              DRAT
            </label>
            <select
              className="form-control"
              placeholder=""
              type="text"
              id="schemeNameDrtId"
              name="schemeNameDrtId"
              onChange={(e) => {
                formik.handleChange(e);
                fetchCaseType(e.target.value);
              }}
              value={formik.values.schemeNameDrtId}
              onBlur={formik.handleBlur}
            >
              <option>Select DRAT Location</option>
              {dratDrtLocations
                .filter((i) => i.schemeNameDrtId > 99)
                .map((item, index) => (
                  <option value={`${item.schemeNameDrtId}`} key={index}>
                    {item.SchemaName}
                  </option>
                ))}
            </select>
            {formik.touched.schemeNameDrtId && formik.errors.schemeNameDrtId ? (
              <div className="error">{formik.errors.schemeNameDrtId}</div>
            ) : null}
          </div>

          {/* field 2 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              Daily/Final Order
            </label>

            <select
              id="dratDailyFinalOrderId"
              name="dratDailyFinalOrderId"
              type="select"
              className="form-control"
              placeholder=""
              onChange={formik.handleChange}
              value={formik.values.dratDailyFinalOrderId}
              onBlur={formik.handleBlur}
            >
              <option>Select Daily/Final Order</option>
              <option value="1"> Daily Order </option>
              <option value="2"> Final Order </option>
            </select>
            {formik.touched.dratDailyFinalOrderId &&
            formik.errors.dratDailyFinalOrderId ? (
              <div className="error">{formik.errors.dratDailyFinalOrderId}</div>
            ) : null}
          </div>

          {/* field 3 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              Case Type
            </label>

            <select
              id="caseType"
              name="caseType"
              type="select"
              className="form-control"
              placeholder=""
              onChange={formik.handleChange}
              value={formik.values.caseType}
              onBlur={formik.handleBlur}
            >
              <option>Select Case Type</option>
              {caseTypeDrt.map((item, index) => (
                <option value={`${item.caseType}`} key={index}>
                  {item.caseTypeName}
                </option>
              ))}
            </select>
            {formik.touched.caseType && formik.errors.caseType ? (
              <div className="error">{formik.errors.caseType}</div>
            ) : null}
          </div>

          {/* field 4 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              Case No
            </label>

            <input
              id="caseNo"
              name="caseNo"
              type="text"
              className="form-control"
              placeholder="Enter Case No"
              onChange={formik.handleChange}
              value={formik.values.caseNo}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.caseNo && formik.errors.caseNo ? (
              <div className="error">{formik.errors.caseNo}</div>
            ) : null}
          </div>

          {/* field 5 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              Case Year
            </label>

            <select
              type="text"
              className="form-control"
              placeholder=""
              id="caseYear"
              name="caseYear"
              onChange={formik.handleChange}
              value={formik.values.caseYear}
              onBlur={formik.handleBlur}
            >
              {years.map((item, index) => (
                <option value={item.year} key={index}>
                  {item.year}
                </option>
              ))}
            </select>
            {formik.touched.caseYear && formik.errors.caseYear ? (
              <div className="error">{formik.errors.caseYear}</div>
            ) : null}
          </div>

          {/* field captcha */}
          <div className="form-group col-md-8 main-menu-captcha capcha-sec">
            <label>Captcha</label>
            <div className="col-sm-12 px-0 d-flex">
              <Captcha
                onChange={(status) => {
                  setCaptchaStatus(status);
                }}
                ref={capt}
                required
              />
              <br />
              <button
                type="submit"
                disabled={!captchaStatus}
                className="btn btn-reload"
              >
                <i className="fas fa-search"><span className="sr-only">no text</span></i>
              </button>
            </div>
            {!captchaStatus ? (
              <div className="captcha-text">Enter valid captcha</div>
            ) : null}
          </div>
        </div>
      </form>

      {loading ? (
        <h6 className="loader">Loading ......</h6>
      ) : (
        <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
          <Modal.Header>
            DRT's Daily Order Report
            <Button
              type="button"
              className="close"
              onClick={() => setIsOpenModal(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>
                      <center>Applicant V/S Respondent</center>
                    </th>
                    <th>Dairy No.</th>
                    <th>Application No.</th>
                    <th>Date of Order</th>
                    <th>Pronounced By</th>
                    <th>View Daily Order(s)</th>
                  </tr>
                </thead>
                {!error && caseDetail && caseDetail.length > 0 ? (
                  <tbody>
                    {caseDetail.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <center>
                            {item.applicantName} <br />
                            vs
                            <br />
                            {item.respondentName}
                          </center>
                        </td>
                        <td>{item.diaryno}</td>
                        <td>{item.applicantno}</td>
                        <td>{doubleDigitDate(item.dateoffiling)}</td>
                        <td>{item.pronouncedBy}</td>
                        <td>
                          <a target="_blank" href={`${item.dailyOrderPdf}`}>
                            <i className="fas fa-search"><span className="sr-only">no text</span></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="8"> No Record Found </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* </div> */}
    </>
  );
};

export default CaseNumber;
