import Captcha from "react-numeric-captcha";
import { useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { axiosBaseURLExternal } from "../../../../../httpCommon";

const RcCauseList = () => {
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [formattedDate, setFormattedDate] = useState(""); //newDate
  const [dateError, setDateError] = useState("");

  // initialState
  const initialValues = {
    drt: "",
    date: "", //new Date()
    courtName: "",
    courtNumber: "",
  };

  const validationSchema = Yup.object({
    drt: Yup.string().required("Required"),
    date: Yup.string().required("Required"), //new Date()
    courtName: Yup.string()
      .trim()
      .max(8, "Maximum length is 8 characters")
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required("Required"),
    courtNumber: Yup.string().required("Required"),
  });

  // fetching first dropDown 1 DRT/DRAT
  const [dratDrtLocations, setDratDrtLocations] = useState([]);
  const fetchDratDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratDrtLocations(sortedLocation);
  };

  // fetching first dropDown 2 Court Name
  const [courtNameList, setCourtNameList] = useState([]);
  const fetchCourtName = async (data) => {
    setDateError("");
    const res = await axiosBaseURLExternal.post("getRcCourtName", data);
    if (res.data.status) {
      setCourtNameList([]);
      setDateError("No Record Available for this date");
    } else {
      setCourtNameList(res.data);
    }
  };

  // fetching first dropDown 3 Court Number
  const [courtNumberList, setCourtNumberList] = useState([]);
  const fetchCourtNumber = async (data) => {
    const res = await axiosBaseURLExternal.post("getRcCourtNo", data);
    setCourtNumberList(res.data);
  };

  // function to fetch data after final post call
  const [caseDetail, setCaseDetail] = useState({});
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    try {
      const response = await axiosBaseURLExternal.post(url, data);
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail({});
      setError(true);
    }
  };

  const handleDate = (value, values) => {
    const result = value.split("-").reverse().join("/");
    setFormattedDate(result);
    const data = {
      schemeNameDrtId: values.drt,
      listingDate: result,
    };
    fetchCourtName(data);
  };

  // Submitting the data function
  const handleSubmit = (values, { resetForm }) => {
    // payload for final post call
    const caseDetailData = {
      schemeNameDrtId: values.drt,
      causeListDate: formattedDate,
      courtNameId: values.courtName,
      courtNo: values.courtNumber,
    };

    // validations for modal popup
    fetchCaseDetail("getRcCauselistReport", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <>
            {" "}
            <form onSubmit={handleSubmit}>
              {/* Field 1 */}
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  DRT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drt"
                    name="drt"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.drt}
                  >
                    <option>Select DRT</option>
                    {dratDrtLocations
                      .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                      .map((item, index) => (
                        <option value={`${item.schemeNameDrtId}`} key={index}>
                          {item.SchemaName}
                        </option>
                      ))}
                  </select>
                  {touched.drt && errors.drt ? (
                    <div className="error">{errors.drt}</div>
                  ) : null}
                </div>
              </div>

              {/* Field 2 */}
              <div className="form-group row">
                <label
                  htmlFor="inputEmail4"
                  className="col-sm-4 col-form-label mandatory"
                >
                  Date
                </label>
                <div className="col-sm-7">
                  <input
                    id="date"
                    name="date"
                    type="date"
                    className="form-control"
                    value={values.date}
                    onChange={(e) => {
                      handleChange(e);
                      handleDate(e.currentTarget.value, values);
                    }}
                    autoComplete="off"
                  />
                  {touched.date && errors.date ? (
                    <div className="error">{errors.date}</div>
                  ) : null}
                  {dateError === "" ? null : <div className="error">{dateError}</div>}
                </div>
              </div>

              {/* Field 3 */}
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Court Name
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="courtName"
                    name="courtName"
                    onBlur={handleBlur}
                    value={values.courtName}
                    onChange={(e) => {
                      handleChange(e);
                      const data = {
                        schemeNameDrtId: values.drt,
                        listingDate: formattedDate,
                      };
                      fetchCourtNumber(data);
                    }}
                  >
                    <option>Select Court Name</option>
                    {courtNameList.map((item, index) => (
                      <option value={`${item.courtNameId}`} key={index}>
                        {item.benchName}
                      </option>
                    ))}
                  </select>
                  {touched.courtName && errors.courtName ? (
                    <div className="error">{errors.courtName}</div>
                  ) : null}
                </div>
              </div>

              {/* Field 4 */}
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Court Number
                </label>
                <div className="col-sm-7">
                  <select
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="courtNumber"
                    name="courtNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.courtNumber}
                  >
                    <option>Select Court Number</option>
                    {courtNumberList.map((item, index) => (
                      <option value={`${item.courtNo}`} key={index}>
                        {item.courtNo}
                      </option>
                    ))}
                  </select>
                  {touched.courtNumber && errors.courtNumber ? (
                    <div className="error">{errors.courtNumber}</div>
                  ) : null}
                </div>
              </div>

              {/* Field 5 */}
              <div className="form-group capcha-sec">
                <div className="col-sm-12 px-0 d-flex">
                  <Captcha
                    onChange={(status) => {
                      setCaptchaStatus(status);
                    }}
                    ref={capt}
                    required
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={!captchaStatus}
                    className="btn btn-reload"
                  >
                    <i className="fas fa-search"><span className="sr-only">no text</span></i>
                  </button>
                </div>
                {!captchaStatus ? (
                  <div className="captcha-text">Enter valid captcha</div>
                ) : null}
              </div>
            </form>
            <Modal
              show={isOpenModal}
              onHide={() => setIsOpenModal(false)}
              // className="modal-90w"
            >
              <Modal.Header>
                RC Cause List Report
                <Button
                  type="button"
                  className="close"
                  onClick={() => setIsOpenModal(false)}
                >
                  &times;
                </Button>
              </Modal.Header>
              <Modal.Body>
                {!error ? (
                  <div className="text-center">
                    <>
                      <a
                        className="btn search mr-2"
                        target="_blank"
                        href={caseDetail.dailyCauseListLink}
                      >
                        {`Print Cause List Report ${formattedDate}`}
                      </a>
                      <a
                        className="btn search"
                        target="_blank"
                        href={caseDetail.supplementaryCauseListLink}
                      >
                        {`Print Supplementary Cause List Report ${formattedDate}`}
                      </a>
                    </>
                  </div>
                ) : (
                  <>No data Found</>
                )}
              </Modal.Body>
            </Modal>
          </>
        )}
      </Formik>
    </div>
  );
};

export default RcCauseList;
