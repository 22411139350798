import React, { useState } from "react";
import Rc_TrcCaseStatusReport from "./tabs/Rc_TrcCaseStatusReport";
import Rc_TrcView from "./tabs/Rc_TrcView";

function Rc_TrcCaseStatusReportParent() {
  const [tabs, setTabs] = useState("Status");
  return (
    <>
      <ul className="nav nav-pills mb-4">
        <li className="nav-item">
          <span
            className={tabs === "Status" ? "nav-link active" : "nav-link"}
            onClick={() => setTabs("Status")}
          >
            RC/TRC Case Status Report
          </span>
        </li>
        <li className="nav-item">
          <span
            className={tabs === "View" ? "nav-link active" : "nav-link"}
            onClick={() => setTabs("View")}
          >
            RC/TRC View
          </span>
        </li>
      </ul>

      {tabs === "Status" ? <Rc_TrcCaseStatusReport /> : <Rc_TrcView />}
    </>
  );
}

export default Rc_TrcCaseStatusReportParent;
