import React from "react";
import Captcha from "react-numeric-captcha";
import { useState, useEffect, useRef } from "react";

import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../../../constants";

import axios from "axios";
import { useFormik } from "formik";
import { years } from "../../../../../services/YearService";

const Rc_TrcCaseStatusReport = () => {
  const [drtDratLocationName, setDrtDratLocationName] = useState("");
  const [inputObj, setInputObj] = useState({});

  // const { loading } = useContext(DrtContext);
  const [loading, setLoading] = useState(false);

  //state for captcha
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  // state to control Model
  const [isOpenModal, setIsOpenModal] = useState(false);

  // state for first dropDownData
  const [dratDrtLocations, setDratDrtLocations] = useState([]);

  // fetching first dropDownData
  const fetchDratDrtLocation = async () => {
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getDrtDratScheamName`
    );
    let data = res.data;
    let drtData = data.filter((item) => item.schemeNameDrtId < 99);
    const sortedLocation = drtData.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratDrtLocations(sortedLocation);
  };

  // get final data after sending required payload
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
  };

  // calling function for first dropDown data
  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const initialValues = {
    schemeNameDrtId: "",
    caseTypeId: "",
    caseNo: "",
    caseYear: new Date().getFullYear(),
  };

  const validate = (values) => {
    let errors = {};
    if (!values.schemeNameDrtId) {
      errors.schemeNameDrtId = "Required";
    }
    if (!values.caseTypeId) {
      errors.caseTypeId = "Required";
    }
    if (!values.caseNo.trim()) {
      errors.caseNo = "Required";
    } else if (values.caseNo.trim().length > 8) {
      errors.caseNo = "Maximum length is 8 characters";
    } else if (!/^[0-9]+$/.test(values.caseNo.trim())) {
      errors.caseNo = "Only numbers are allowed";
    }
    if (!values.caseYear) {
      errors.caseYear = "Required";
    }

    return errors;
  };

  const onSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDrtId: values.schemeNameDrtId,
      caseTypeId: values.caseTypeId,
      caseNo: values.caseNo,
      caseYear: values.caseYear,
    };

    getDrtDratName(values.schemeNameDrtId);

    fetchCaseDetail("getRcTrcCaseStatusReport", caseDetailData);
    setInputObj(values);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  // const doubleDigitDate = (date) => {
   
  //   if (date) {
  //     let arr = date.split("/");
  //     if (arr[0].length === 1) arr[0] = "0" + arr[0];
  //     if (arr[1].length === 1) arr[1] = "0" + arr[1];

  //     return arr.join("/");
  //   }
  // };

  const doubleDigitDate = (date) => {
    if (date) {
      let arr = date.split("/");
      if (arr[0]?.length === 1) arr[0] = "0" + arr[0];
      if (arr[1]?.length === 1) arr[1] = "0" + arr[1];
      return arr.join("/");
    } else {
      return date;
    }
  };


  const getDrtDratName = (id) => {
    let name = dratDrtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtDratLocationName(name[0]?.SchemaName);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="w-100">
        <div className="form-row">
          {/* field 1 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="staticEmail">
              {" "}
              DRT
            </label>

            <select
              className="form-control"
              placeholder=""
              type="text"
              id="schemeNameDrtId"
              name="schemeNameDrtId"
              onChange={(e) => {
                formik.handleChange(e);
                // fetchCaseType(e.target.value)
              }}
              value={formik.values.schemeNameDrtId}
              onBlur={formik.handleBlur}
            >
              <option> DRT Location</option>
              {dratDrtLocations
                .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                .map((item, index) => (
                  <option value={`${item.schemeNameDrtId}`} key={index}>
                    {item.SchemaName}
                  </option>
                ))}
            </select>
            {formik.touched.schemeNameDrtId && formik.errors.schemeNameDrtId ? (
              <div className="error">{formik.errors.schemeNameDrtId}</div>
            ) : null}
          </div>

          {/* field 2 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              RC/TRC
            </label>

            <select
              id="caseTypeId"
              name="caseTypeId"
              type="select"
              className="form-control"
              placeholder=""
              onChange={formik.handleChange}
              value={formik.values.caseTypeId}
              onBlur={formik.handleBlur}
            >
              <option>Select Case Type</option>
              <option value={99999}> RC </option>
              <option value={44444}> TRC </option>
            </select>
            {formik.touched.caseTypeId && formik.errors.caseTypeId ? (
              <div className="error">{formik.errors.caseTypeId}</div>
            ) : null}
          </div>

          {/* field 3 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              RC/TRC No
            </label>

            <input
              id="caseNo"
              name="caseNo"
              type="text"
              className="form-control"
              placeholder="Enter Case No"
              onChange={formik.handleChange}
              value={formik.values.caseNo}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.caseNo && formik.errors.caseNo ? (
              <div className="error">{formik.errors.caseNo}</div>
            ) : null}
          </div>

          {/* field 4 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              RC/TRC Year
            </label>

            <select
              type="select"
              className="form-control"
              placeholder=""
              id="caseYear"
              name="caseYear"
              onChange={formik.handleChange}
              value={formik.values.caseYear}
              onBlur={formik.handleBlur}
            >
              {years.map((item, index) => (
                <option value={item.year} key={index}>
                  {item.year}
                </option>
              ))}
            </select>
            {formik.touched.caseYear && formik.errors.caseYear ? (
              <div className="error">{formik.errors.caseYear}</div>
            ) : null}
          </div>

          {/* field Captcha */}
          <div className="form-group col-md-8 main-menu-captcha capcha-sec">
            <label>Captcha</label>
            <div className="col-sm-12 px-0 d-flex">
              <Captcha
                onChange={(status) => {
                  setCaptchaStatus(status);
                }}
                ref={capt}
                required
              />
              <br />
              <button
                type="submit"
                disabled={!captchaStatus}
                className="btn btn-reload"
              >
                <i className="fas fa-search"><span className="sr-only">no text</span></i>
              </button>
            </div>
            {!captchaStatus ? (
              <div className="captcha-text">Enter valid captcha</div>
            ) : null}
          </div>
        </div>
      </form>

      {loading ? (
        <h6 className="loader">Loading ......</h6>
      ) : (
        <Modal
          show={isOpenModal}
          onHide={() => setIsOpenModal(false)}
          className="rctrc-modal"
          // className="modal-90w"
        >
          <Modal.Header>
            RC/TRC Case Status Report <br /> {drtDratLocationName}
            <Button
              type="button"
              className="close"
              onClick={() => setIsOpenModal(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            {!error && caseDetail?.status ? (
              <>
                <div className="table-responsive mb-3">
                  <table className="table table-bordered text-center rcstatus-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            {caseDetail?.petitionerName} <br /> VS
                            <br />
                            {caseDetail?.respondentName}
                          </strong>
                        </td>
                        <td>
                          RC/TRC IS <strong>{caseDetail?.status}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="table-responsive mb-3">
                  <table className="table table-bordered rcstatus-table">
                    <thead>
                      <tr>
                        <td colSpan={"2"}>
                          RC/TRC Number:
                          <strong>
                            {inputObj.caseTypeId == "99999" ? "RC" : "TRC"}/
                            {inputObj.caseNo}/{inputObj.caseYear}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={"2"}>
                          Case Number:
                          <strong>{caseDetail?.caseno} </strong>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div className="table-responsive">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan={"2"}>RC/TRC CASE CURRENT STATUS</th>
                      </tr>
                      <tr></tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Court Name:</td>
                        <td>
                          <strong>{caseDetail?.courtname}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Next Listing Date:</td>
                        <td>
                          <strong>
                            {doubleDigitDate(caseDetail?.nextlistingdate)}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Next Listing Purpose:</td>
                        <td>
                          <strong>{caseDetail?.nextListingPurpose}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                {/* case proceeding details */}

                <div className="table-responsive">
                  {caseDetail?.rcCaseProceedingDetails.length > 0 && (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan={"3"}>RC/TRC CASE PROCEEDING DETAILS</th>
                        </tr>
                        <tr>
                          <th>Court No.</th>
                          <th>Causelist Date</th>
                          <th>Purpose</th>
                        </tr>
                      </thead>
                      <tbody>
                        {caseDetail?.rcCaseProceedingDetails?.map(
                          (item, index) => (
                            <tr key={index}>
                              <td>{item.courtNo}</td>
                              <td>{doubleDigitDate(item.causelistdate)}</td>
                              <td>{item.purpose}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>

                {/* case details*/}

                <div className="table-responsive mb-3">
                  {caseDetail.casedetail && (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan="2">CASE STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Diary No/Year</td>
                          <td>
                            <strong>
                              {caseDetail?.casedetail?.diaryno}/
                              {caseDetail?.casedetail?.diaryyear}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          {/* <td>Case Type/Case No/Year</td>
                              <td>
                                <strong>
                                  {caseDetail?.casedetail.casetype}/
                                  {caseDetail?.casedetail.caseno}/
                                  {caseDetail?.casedetail.caseyear}
                                </strong>
                              </td> */}
                        </tr>
                        <tr>
                          <td>Date of Filing</td>
                          <td>
                            <strong>
                              {doubleDigitDate(
                                caseDetail?.casedetail?.dateoffiling
                              )}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Case Status</td>
                          <td>
                            <strong>
                              {caseDetail?.casedetail?.casestatus}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                  <td> Date of Disposal </td>
                  <td>
                    <strong> {caseDetail?.casedetail?.dateofdisposal} </strong>
                  </td>
                </tr>
                <tr>
                  <td> Nature of Disposal </td>
                  <td>
                    <strong> {caseDetail?.casedetail?.disposalNature} </strong>
                  </td>
                </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
                {/* <div className="table-responsive mb-3">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan="2">CASE LISTING DETAILS (Tentative)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>In the Court of</td>
                        <td>
                          <strong>{caseDetail?.casedetail.courtName}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Court No.</td>
                        <td>
                          <strong>{caseDetail?.casedetail.courtNo}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Next Listing Date</td>
                        <td>
                          <strong>
                            {doubleDigitDate(
                              caseDetail?.casedetail.nextlistingdate
                            )}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Next Listing Purpose</td>

                        <td>
                          <strong>
                            {caseDetail?.casedetail.nextListingPurpose}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div> */}
                <div className="table-responsive mb-3">
                  {caseDetail.casedetail && (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan="2">
                            <strong>PETITIONER/APPLICANT DETAIL</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                        <td> Petitioner Name: </td>
                        <td>
                          <strong>
                            {caseDetail?.casedetail.petitionerName}
                          </strong>
                        </td>
                      </tr> */}
                        <tr>
                          <td>
                            Petitioner/Applicant Address:{" "}
                            <strong>
                              {
                                caseDetail?.casedetail
                                  ?.petitionerApplicantAddress
                              }
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td> Additional Party: </td>
                          <td>
                            <strong>
                              {caseDetail?.casedetail?.additionalpartypet}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td> Advocate Name: </td>
                          <td>
                            <strong>
                              {caseDetail?.casedetail?.advocatePetName}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
                <div className="table-responsive mb-3">
                  {caseDetail.casedetail && (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan="2">
                            <strong>RESPONDENTS/DEFENDANT DETAILS</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                        <td> Respondent Name: </td>
                        <td>
                          <strong>
                            {caseDetail?.casedetail.respondentName}
                          </strong>
                        </td>
                      </tr> */}
                        <tr>
                          <td>
                            Respondent/Defendant Address:{" "}
                            <strong>
                              {
                                caseDetail?.casedetail
                                  ?.respondentDefendentAddress
                              }
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td> Additional Party: </td>
                          <td>
                            <strong>
                              {caseDetail?.casedetail?.additionalpartyres}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td> Advocate Name: </td>
                          <td>
                            <strong>
                              {caseDetail?.casedetail?.advocateResName}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>

                {/* // Case Proceeding details */}
                {caseDetail.casedetail?.caseProceedingDetails.length > 0 && (
                  <div className="table-responsive mb-3">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan="4">CASE PROCEEDING DETAILS</th>
                        </tr>
                        <tr>
                          <th>Court Name</th>
                          <th>Causelist Date</th>
                          <th>Purpose</th>
                          <th>Order</th>
                        </tr>
                      </thead>
                      <tbody>
                        {caseDetail.casedetail?.caseProceedingDetails?.map(
                          (item) => (
                            <tr>
                              <td>{item.ascourtName}</td>
                              <td>{doubleDigitDate(item.causelistdate)}</td>
                              <td>{item.purpose}</td>
                              <td>
                                {" "}
                                {item.orderUrl && (
                                  <a target="_blank" href={`${item.orderUrl}`}>
                                    <i className="fas fa-search"><span className="sr-only">no text</span></i>
                                  </a>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </>
            ) : (
              <>
                <tbody>
                  <tr>
                    <td colSpan="6"> No Record Found </td>
                  </tr>
                </tbody>
              </>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Rc_TrcCaseStatusReport;
