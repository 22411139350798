import React, { useState } from "react";
import DratCauseList from "./DratCauseList";
import DrtCauseList from "./DrtCauseList";
import RcCauseList from "./RcCauseList";
import { Link } from "react-router-dom";
import { useTitle } from "../../../services/PageTitle";

const CauseListHome = () => {
  useTitle("Cause List");
  const [relatedLink, setRelatedLink] = useState("DRT Cause List");

  return (
    <>
      <div className="inner-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="b-title">
                <h5>DRT Cause List</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Cause List
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {relatedLink === "DRT Cause List"
                      ? "DRT Cause List"
                      : relatedLink === "RC Cause List"
                      ? "RC Cause List"
                      : "DRAT Cause List"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 pt-4 inner-side-nav">
              {relatedLink === "DRT Cause List" ? (
                <DrtCauseList />
              ) : relatedLink === "RC Cause List" ? (
                <RcCauseList />
              ) : (
                <DratCauseList />
              )}
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-static">
              <div className="bg-shade"></div>
              <div className="right-menu">
                <h3>Related Links</h3>
                <ul>
                  <li>
                    <span
                      className={
                        relatedLink === "DRT Cause List" ? "active" : ""
                      }
                     
                      onClick={() => setRelatedLink("DRT Cause List")}
                    >
                      DRT Cause List
                    </span>
                  </li>
                  <li>
                    <span
                      className={
                        relatedLink === "RC Cause List" ? "active" : ""
                      }
                     
                      onClick={() => setRelatedLink("RC Cause List")}
                    >
                      RC Cause List
                    </span>
                  </li>
                  <li>
                    <span
                      className={
                        relatedLink === "DRAT Cause List" ? "active" : ""
                      }
                     
                      onClick={() => setRelatedLink("DRAT Cause List")}
                    >
                      DRAT Cause List
                    </span> 
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CauseListHome;
