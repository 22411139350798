import { lazy, Suspense, useContext } from "react";
import { DrtContext } from "./context/DrtContext";
import Layout from "./Layout";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

const AdminLayout = lazy(() => import("./AdminExternal/Shared/AdminRouting"));

function App() {
  const { fontSize, handleClickSearch } = useContext(DrtContext);

  return (
    <div
      className="App"
      style={{ fontSize: `${fontSize}px` }}
      onClick={handleClickSearch}
    >
      <ScrollToTop smooth color="#6f00ff" />

      <Routes>
        <Route
          path="/adminimexternal*"
          element={
            <Suspense fallback="">
              <AdminLayout />
            </Suspense>
          }
        ></Route>
        <Route path="/*" element={<Layout />}></Route>
      </Routes>
    </div>
  );
}

export default App;
