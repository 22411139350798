import { useState } from "react";

import CaseNoWise from "./Tabs/CaseNoWise";
import DiaryNoWise from "./Tabs/DiaryNoWise";
import PartyNameWise from "./Tabs/PartyNameWise";
import AdvocateNameWise from "./Tabs/AdvocateNameWise";

const DrtCaseStatusParent = () => {
  const [relatedLink, setRelatedLink] = useState("DiaryNo");
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 tab-one p-0">
        <ul
          className="nav toptab-one mb-3 inner-tab mx-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={
                relatedLink === "DiaryNo" ? "nav-link active" : "nav-link"
              }
              id="inner-tab2"
              data-toggle="pill"
              href="#pills-cause"
              role="tab"
              aria-controls="pills-cause"
              aria-selected="false"
              onClick={() => setRelatedLink("DiaryNo")}
            >
              Diary Number
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "CaseNo" ? "nav-link active" : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("CaseNo")}
            >
              Case Number
            </a>
          </li>

          <li className="nav-item">
            <a
              className={
                relatedLink === "PartyName" ? "nav-link active" : "nav-link"
              }
              id="inner-tab3"
              data-toggle="pill"
              href="#pills-order"
              role="tab"
              aria-controls="pills-order"
              aria-selected="false"
              onClick={() => setRelatedLink("PartyName")}
            >
              Party Name
            </a>
          </li>

          <li className="nav-item">
            <a
              className={
                relatedLink === "AdvocateName" ? "nav-link active" : "nav-link"
              }
              id="inner-tab3"
              data-toggle="pill"
              href="#pills-order"
              role="tab"
              aria-controls="pills-order"
              aria-selected="false"
              onClick={() => setRelatedLink("AdvocateName")}
            >
              Advocate  Name
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {relatedLink === "CaseNo" ? (
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              // aria-labelledby="pills-home-tab"
            >
              <CaseNoWise />
            </div>
          ) : relatedLink === "DiaryNo" ? (
            <div
              className="tab-pane fade show active"
              id="pills-cause"
              role="tabpanel"
              // aria-labelledby="pills-profile-tab"
            >
              <DiaryNoWise />
            </div>
          ) : relatedLink === "PartyName" ? (
            <div
              className="tab-pane fade show active"
              id="pills-order"
              role="tabpanel"
              // aria-labelledby="pills-profile-tab"
            >
              <PartyNameWise />
            </div>
          ) : 
          <div
          className="tab-pane fade show active"
          id="pills-order"
          role="tabpanel"
          // aria-labelledby="pills-profile-tab"
        >
          <AdvocateNameWise />
        </div>
          }
        </div>
      </div>
    </>
  );
};

export default DrtCaseStatusParent;
