import React, { useEffect, useState, useContext } from "react";
import { Outlet } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";
import RelatedLink from "./relatedLink/RelatedLink";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import { DrtContext } from "../../context/DrtContext";
import { useTitle } from "../../services/PageTitle";

const AboutUs = () => {
  useTitle("About Us");
  window.scrollTo(0, 0);
  const { EncryptDecrypt } = useContext(DrtContext);
  const [about, setAbout] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getAbout = async () => {
    setLoading(true);
    try {
      const result = await axiosBaseURL.get("/site/about");
      const temp = EncryptDecrypt(result.data);
      setAbout(temp.data);
    } catch (error) {
      setError("Something Went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <>
      <div className="about">
        <InternalBaseBanner title={about.title} />
        <BredCrumb title={about.title} />
        <div className="common-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 inner-side-nav">
                <div className="about-content">
                  {loading ? (
                    <h6 className="loader">Loading ...</h6>
                  ) : error ? (
                    <div className="error-message">
                      <h6>Error: {error}</h6>
                      {/* You can provide additional error handling UI or actions here */}
                    </div>
                  ) : (
                    <Outlet />
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 p-static">
                <div className="bg-shade"></div>
                <RelatedLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;