import { useState, useEffect, useContext } from "react";
import Viewer from "react-viewer";
import { DrtContext } from "../../../context/DrtContext";
import axiosBaseURL from "../../../httpCommon";

const GalleryCategory2 = () => {
  const { EncryptDecrypt  } = useContext(DrtContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);

   // fetching data
   const [drtGetData, setDrtGetData] = useState([]);
   const fetchDrtData = async (url) => {
     const response = await axiosBaseURL.get(url);
     const result = await response.data;
     console.log("inside galleryAll",result)
 
     const temp = EncryptDecrypt(result)
 
     setDrtGetData(temp.data);
   };

  const galleryImagesItem = drtGetData.map((item) => {
    return {
      src: item.file_path,
      title: item.title,
    };
  });

 

  useEffect(() => {
    setLoading(true);
    fetchDrtData("/get-gallery/8");
    setLoading(false);
  }, []);

  if (loading) {
    return <h6 className="loader">Loading ...</h6>;
  } else {
    return (
      <>
        <div id="1" className="tab-pane active">
          <section className="module module-spaced module-masonry masonry-layout-8 wow fadeInRight">
            <div className="grouping-section">
              {galleryImagesItem.map((item, index) => (
                <a
                  className="masonry-item"
                 
                  key={index}
                  onClick={() => {
                    setVisible(true);
                    setActiveIndex(index);
                  }}
                >
                  <img
                    src={item.src}
                    width="550"
                    height="314"
                    alt="Art and Culture"
                    loading="lazy"
                    typeof="foaf:Image"
                    className="img-responsive"
                  />
                  <div className="inner  details ">
                    <h3>{item.title}</h3>
                  </div>
                </a>
              ))}
            </div>
          </section>
          <Viewer
            visible={visible}
            onClose={() => {
              setVisible(false);
            }}
            onMaskClick={() => {
              setVisible(false);
            }}
            // zoomSpeed={0.2}
            images={galleryImagesItem}
            activeIndex={activeIndex}
            rotatable={false}
            zoomable={false}
            scalable={false}
            attribute={false}
            // noFooter={true}
            // noClose={true}
            // downloadable
          />
        </div>
      </>
    );
  }
};

export default GalleryCategory2;
