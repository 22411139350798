import React from "react";
import { NavLink } from "react-router-dom";

const RelatedLink = () => {
  return (
    <>
      <div className="right-menu">
        <h3>Related Links</h3>
        <ul>
          <li>
            <NavLink to="/aboutus/aboutdetail">About DRATS</NavLink>
          </li>
          <li>
            <NavLink to="/aboutus/actrule">Act & Rules</NavLink>
          </li>
          <li>
            <NavLink to="/aboutus/missionvission">Mission And Vision</NavLink>
          </li>
          {/* <li><a href="#">Case Details</a></li>
                              <li><a href="#">Cause List</a></li>
                              <li><a href="#">Order</a></li>
                              <li><a href="#">List of CPIOs</a></li> */}
        </ul>
      </div>
    </>
  );
};

export default RelatedLink;
