import React, { useState } from "react";
import DratOrderParent from "./DRAT-order/DratOrderParent";
import DrtDailyFinalOrderParent from "./DRT-order/DrtDailyFinalOrderParent";
import DrtFinalCertificateParent from "./drtrecovery-certificate-order/DrtFinalCertificateParent";
import { Link } from "react-router-dom";
import { useTitle } from "../../../services/PageTitle";

const OrderParent = () => {
  useTitle("Orders")
  const [relatedLink, setRelatedLink] = useState("DRT's Order / Final Order");

  return (
    <div>
      <div className="inner-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="b-title">
                <h5>DRT/DRAT Orders</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Orders
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {relatedLink === "DrtDailyFinalOrderParent"
                      ? "DRT's Daily / Final Order"
                      : relatedLink === "RC"
                      ? "RC"
                      : "DRAT's Order / Final Order"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="common-bg mb-4">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 pt-4 inner-side-nav">
              {relatedLink === "DrtDailyFinalOrderParent" ? (
                <DrtDailyFinalOrderParent />
              ) : relatedLink === "RC" ? (
                <DrtFinalCertificateParent />
              ) : (
                <DratOrderParent />
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-static">
              <div className="bg-shade"></div>
              <div className="right-menu">
                <h3>Related Links</h3>
                <ul>
                  <li>
                    <span
                      className={
                        relatedLink === "DRT's Order / Final Order"
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        setRelatedLink("DRT's Order / Final Order")
                      }
                    >
                      DRAT's Order / Final Order
                    </span>
                  </li>
                  <li>
                    <span
                      className={
                        relatedLink === "DrtDailyFinalOrderParent"
                          ? "active"
                          : ""
                      }
                      onClick={() => setRelatedLink("DrtDailyFinalOrderParent")}
                    >
                      DRT's Daily / Final Order
                    </span>
                  </li>
                  <li>
                    <span
                      className={relatedLink === "RC" ? "active" : ""}
                      onClick={() => setRelatedLink("RC")}
                    >
                      RC / TRC
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderParent;
