import axiosBaseURL from "../../../../httpCommon";
import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { DrtContext } from "../../../../context/DrtContext";
import Loader from "../../../Loader";

const WhatsNew = () => {
  const slider = useRef(null);
  const { EncryptDecrypt } = useContext(DrtContext);
  const [whatsNew, setWhatsNew] = useState(null);
  const [loading, setLoading] = useState(false);
  const [playPause, setPlayPause] = useState(false);

  const fetchWhatsNew = async () => {
    try {
      setLoading(true);
      const response = await axiosBaseURL.get("/get-recent-news");
      setWhatsNew(EncryptDecrypt(response.data));
      setLoading(false);
    } catch (error) {
      setWhatsNew(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWhatsNew();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    } else {
      slider.current.slickPause();
    }
  };

  const settings = {
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    vertical: true,
  };

  return (
    <>
      <div className="whatsnew-bg">
        <h4>What’s New</h4>
        <div className="vticker">
          <ul>
            <Slider {...settings} ref={slider}>
              {whatsNew && whatsNew.data ? (
                whatsNew.data.map((item, index) => (
                  <li key={index}>
                    <NavLink to={`/whats-new-detail-Single/${item.slug}`}>
                      {item.title}
                    </NavLink>
                  </li>
                ))
              ) : (
                <p>No data Found</p>
              )}
            </Slider>
          </ul>
        </div>
        <div className="bottom-btn d-flex justify-content-between">
          <a onClick={handlePlayPause} className="btn pause">
            {!playPause ? (
              <span>
                <i className="fas fa-pause-circle"></i>
                Pause
              </span>
            ) : (
              <span>
                <i className="fas fa-play"></i>
                Play
              </span>
            )}
          </a>
          <NavLink className="btn viewall" to="/whatsnewdetail">
            View All
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default WhatsNew;
