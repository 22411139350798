import React, { useEffect, useState, useContext } from "react";
import { DrtContext } from "../../../context/DrtContext";
import axiosBaseURL from "../../../httpCommon";

const MissionVission = () => {
  const { EncryptDecrypt } = useContext(DrtContext);
  const [about, setabout] = useState([]);

  const getAbout = async () => {
    await axiosBaseURL.get("/site/mission-and-vision").then((result) => {
      const temp = EncryptDecrypt(result.data);
      setabout(temp.data);
    });
  };
  useEffect(() => {
    getAbout();
  }, []);

  if (!about)
    return (
      <>
        <h6 className="loader">Loading......</h6>
      </>
    );

  return (
    <>
      <h6>{about && about.title}</h6>
      <div dangerouslySetInnerHTML={{ __html: about && about.body }}></div>
    </>
  );
};
export default MissionVission;
