import React, { useState, useEffect, useContext } from "react";
import axiosBaseURL from "../../httpCommon";
import { DrtContext } from "../../context/DrtContext";
import { useTitle } from "../../services/PageTitle";

const ContactUs = () => {
  useTitle("Contact Us");
  const [location, setLocation] = useState([]);
  const [addAndLoc, setAddAndLoc] = useState([]);
  const { EncryptDecrypt } = useContext(DrtContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setAddAndLoc(
      location.filter((item) => item.id.toString() === e.target.value)
    );
  };

  useEffect(() => {
    fetchAllLocations();
  }, []);

  const fetchAllLocations = async () => {
    setLoading(true);
    try {
      const result = await axiosBaseURL.get("/get-drtlocation");
      const temp = EncryptDecrypt(result.data);
      setLocation(temp.data);
    } catch (error) {
      setError("something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="contact pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end">
                <select
                  className="form-control w-50 contact-drat"
                  onChange={handleChange}
                >
                  {location &&
                    location
                      // .sort((a, b) => a.id - b.id)
                      .map((item, index) => (
                        <option value={`${item.id}`} key={index}>
                          {item.name}
                        </option>
                      ))}
                </select>
              </div>
              {loading ? (
                <h6 className="loader">Loading ...</h6>
              ) : error ? (
                <div className="error-message">
                  <h6>Error: {error}</h6>
                  {/* You can provide additional error handling UI or actions here */}
                </div>
              ) : addAndLoc.length === 1 ? (
                <div className="contact-box">
                  <div className="contact-links">
                    <h2>CONTACT US</h2>
                    <div className="address">
                      <div className="drt-name-contact">
                        {addAndLoc[0]?.name}
                      </div>
                      {addAndLoc[0]?.address}
                    </div>
                  </div>
                  <div className="contact-form-wrapper">
                    <div className="map-sec">
                      <iframe
                        title="myframe1"
                        src={addAndLoc[0]?.geolocation}
                        width="100%"
                        height="400px"
                        border="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="contact-box">
                  <div className="contact-links">
                    <h2>CONTACT US</h2>
                    <div className="address">
                      <div className="drt-name-contact">
                        {location[0]?.name}
                      </div>
                      {location[0]?.address}
                    </div>
                  </div>
                  <div className="contact-form-wrapper">
                    <div className="map-sec">
                      <iframe
                        src={location[0]?.geolocation}
                        width="100%"
                        height="400px"
                        border="0"
                        title="myframe1"
                      ></iframe>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
