import React, { useState, useEffect, useContext, useRef } from "react";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import axios from "axios";
import { DrtContext } from "../../context/DrtContext";
import { useTitle } from "../../services/PageTitle";
import Captcha from "react-numeric-captcha";
import { useFormik } from "formik";
import { apiStagingOutdoorUrl } from "../../constants";
import { axiosBaseURLExternal } from "../../httpCommon";

const RTI = () => {
  useTitle("RTI");
  const [loading, setLoading] = useState(false);
  const { EncryptDecrypt } = useContext(DrtContext);
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();
  const [drtLocations, setDrtLocations] = useState([]);
  const [dratLocations, setDratLocations] = useState([]);
  const refFromDate = useRef("");
  const refToDate = useRef("");
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const [selectedTab, setSelectedTab] = useState("DRAT");
  const [isSubmitted, setIsSubmitted] = useState(false);
  

  const fetchRtiDetail = async (url, data) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setError(true);
    }
    setLoading(false);
  };

  const initialValues = {
    schemeNameDratDrtId: "",
    fromDate: "",
    toDate: "",
  };
  

  const fetchDrtLocation = async () => {
    try {
      const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
      const sortedLocation = res.data
        .filter((item) => parseInt(item.schemeNameDrtId) < 100)
        .sort((a, b) => (a.SchemaName > b.SchemaName ? 1 : -1));
      setDrtLocations(sortedLocation);
    } catch (error) {
      console.error("Error fetching DRT locations:", error.message);
    }
  };

  const fetchDratLocation = async () => {
    try {
      const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
      const sortedLocation = res.data
        .filter((item) => parseInt(item.schemeNameDrtId) > 99)
        .sort((a, b) => (a.SchemaName > b.SchemaName ? 1 : -1));
      setDratLocations(sortedLocation);
    } catch (error) {
      console.error("Error fetching DRAT locations:", error.message);
    }
  };

  useEffect(() => {
    fetchDrtLocation();
    fetchDratLocation();
  }, []);

  const validate = (values) => {
    let errors = {};
    if (!values.schemeNameDratDrtId) {
      errors.schemeNameDratDrtId = "Required";
    }
    if (!values.fromDate) {
      errors.fromDate = "Required";
    }
    if (!values.toDate) {
      errors.toDate = "Required";
    }
    return errors;
  };

  const onSubmit = (values, { resetForm }) => {
    if (!captchaStatus) {
      alert("Please enter a valid captcha.");
      return;
    }

    const caseDetailData = {
      schemeNameDrtId: values.schemeNameDratDrtId,
      fromDate: refFromDate.current,
      toDate: refToDate.current,
    };

    fetchRtiDetail("getRti", caseDetailData);
    setIsSubmitted(true);
    setCaptchaStatus(false);
    capt.current.refresh();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setIsSubmitted(false);
  };
  const locationAndDate = (value, name) => {
    if (name === "fromDate") {
      refFromDate.current = value;
    }
    if (name === "toDate") {
      refToDate.current = value;
    }
  };

  if (loading) {
    return <h6 className="loader">Loading......</h6>;
  }

  return (
    <>
      <InternalBaseBanner title={"RTI"} />
      <BredCrumb title={"RTI"} />
      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 pt-4 inner-side-nav">
            <h6>{selectedTab === "DRT" ? "DRT RTI" : "DRAT RTI"}</h6>

              <form onSubmit={formik.handleSubmit} className="w-100">
                <div className="form-row">
                  {/* DRAT/DRT Dropdown */}
                  <div className="form-group col-md-4">
                    <label className="mandatory" htmlFor="schemeNameDratDrtId">
                      {selectedTab === "DRT" ? "DRT Location" : "DRAT Location"}
                    </label>
                    <select
                      className="form-control"
                      id="schemeNameDratDrtId"
                      name="schemeNameDratDrtId"
                      onChange={formik.handleChange}
                      value={formik.values.schemeNameDratDrtId}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Location</option>
                      {selectedTab === "DRT"
                        ? drtLocations.map((item, index) => (
                            <option value={`${item.schemeNameDrtId}`} key={index}>
                              {item.SchemaName}
                            </option>
                          ))
                        : dratLocations.map((item, index) => (
                            <option value={`${item.schemeNameDrtId}`} key={index}>
                              {item.SchemaName}
                            </option>
                          ))}
                    </select>
                    {formik.touched.schemeNameDratDrtId &&
                    formik.errors.schemeNameDratDrtId ? (
                      <div className="error">{formik.errors.schemeNameDratDrtId}</div>
                    ) : null}
                  </div>

                  {/* From Date */}
                  <div className="form-group col-md-4">
                    <label className="mandatory" htmlFor="fromDate">
                      From Date
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      id="fromDate"
                      name="fromDate"
                      onChange={(e) => {
                        formik.handleChange(e);
                        locationAndDate(e.target.value, e.target.name);
                      }}
                      value={formik.values.fromDate}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                    />
                    {formik.touched.fromDate && formik.errors.fromDate ? (
                      <div className="error">{formik.errors.fromDate}</div>
                    ) : null}
                  </div>

                  {/* To Date */}
                  <div className="form-group col-md-4">
                    <label className="mandatory" htmlFor="toDate">
                      To Date
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      id="toDate"
                      name="toDate"
                      onChange={(e) => {
                        formik.handleChange(e);
                        locationAndDate(e.target.value, e.target.name);
                      }}
                      value={formik.values.toDate}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.toDate && formik.errors.toDate ? (
                      <div className="error">{formik.errors.toDate}</div>
                    ) : null}
                  </div>

                  {/* Captcha */}
                  <div className="form-group col-md-8 main-menu-captcha capcha-sec">
                    <label>Captcha</label>
                    <div className="col-sm-12 px-0 d-flex">
                      <Captcha
                        onChange={(status) => {
                          setCaptchaStatus(status);
                        }}
                        ref={capt}
                        required
                      />
                      <br />
                      <button
                        type="submit"
                        disabled={!captchaStatus}
                        className="btn btn-reload"
                      >
                        <i className="fas fa-search">
                          <span className="sr-only">no text</span>
                        </i>
                      </button>
                    </div>
                    {!captchaStatus ? <div className="captcha-text">Invalid Captcha</div> : null}
                  </div>
                </div>
              </form>

              {/* Show table only after submission */}
              {isSubmitted && (
                <div className="table-responsive mt-5">
                  <table className="table table-striped common-table bluetable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Subject</th>
                        <th>Date</th>
                        <th>Document</th>
                      </tr>
                    </thead>
                    <tbody>
                      {caseDetail.length > 0 ? (
                        caseDetail.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.subject}</td>
                            <td>{new Date(item.entry_date).toLocaleDateString()}</td>
                            <td>
                              <a href={item.dailyOrderPdf} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-file"></i> Download PDF
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">Select Above Location and date for RTI</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-static">
              <div className="bg-shade"></div>
              <div className="right-menu">
                <h3>Related Links</h3>
                <ul>
                  <li>
                    <span
                      className={selectedTab === "DRAT" ? "nav-link active" : "nav-link"}
                      id="pills-drat-tab"
                      role="tab"
                      onClick={() => setSelectedTab("DRAT")}
                    >
                      DRAT RTI
                    </span>
                  </li>
                  <li>
                    <span
                      className={selectedTab === "DRT" ? "nav-link active" : "nav-link"}
                      id="pills-drt-tab"
                      role="tab"
                      onClick={() => handleTabChange("DRT")}
                    >
                      DRT RTI
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RTI;
