import { useState } from "react";
import DrtDailyFinalOrderParentHome from "./Drt-Order/DrtDailyFinalOrderParentHome";
import DratOrderFinalOrderParentHome from "./Drat-Order/DratOrderFinalOrderParentHome";
import DrtRecoveryCertificateParent from "./DrtRecoveryCertificate/DrtRecoveryCertificateParent";

const OrderHome = () => {
  const [relatedLink, setRelatedLink] = useState("DrtOrderFinalOrder");

  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-0">
          <ul className="nav toptab-two mb-3" id="v-pills-tab" role="tablist">
            <li className="nav-item">
              <a
                // className="nav-link"
                className={
                  relatedLink === "DrtOrderFinalOrder"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#pills-status"
                role="tab"
                aria-controls="pills-status"
                aria-selected="false"
                onClick={() => setRelatedLink("DrtOrderFinalOrder")}
              >
                DRAT's Order / Final Order
              </a>
            </li>
            <li className="nav-item">
              <a
                // className="nav-link"
                className={
                  relatedLink === "DrtDailyFinalOrderParentHome"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#pills-status"
                role="tab"
                aria-controls="pills-status"
                aria-selected="false"
                onClick={() => setRelatedLink("DrtDailyFinalOrderParentHome")}
              >
                DRT's Daily / Final Order
              </a>
            </li>
            <li className="nav-item">
              <a
                // className="nav-link"
                className={
                  relatedLink === "DrtRecoveryCertificate"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#pills-status"
                role="tab"
                aria-controls="pills-status"
                aria-selected="false"
                onClick={() => setRelatedLink("DrtRecoveryCertificate")}
              >
                RC / TRC
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 pr-0">
          <div className="tab-content tab-form p-0" id="pills-tabContent">
            {relatedLink === "DrtDailyFinalOrderParentHome" ? (
              <div
                className="tab-pane fade show active"
                id="pills-drt"
                role="tabpanel"
                // aria-labelledby="pills-home-tab"
              >
                <DrtDailyFinalOrderParentHome />
              </div>
            ) : relatedLink === "DrtRecoveryCertificate" ? (
              <div
                className="tab-pane fade show active"
                id="party-name"
                role="tabpanel"
                // aria-labelledby="pills-profile-tab"
              >
                <DrtRecoveryCertificateParent />
              </div>
            ) : (
              <div
                className="tab-pane fade show active"
                id="party-name"
                role="tabpanel"
                // aria-labelledby="pills-profile-tab"
              >
                <DratOrderFinalOrderParentHome />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHome;
