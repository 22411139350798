import React, { useState } from "react";
import DrtDailyOrderParent from "./DrtDailyOrderParent";
import DrtFinalOrderParent from "./DrtFinalOrderParent";

const DrtDailyFinalOrderParent = () => {
  const [tabs, setTabs] = useState("DrtDailyOrderParent");

  return (
    <>
      <ul className="nav nav-pills mb-4">
        <li className="nav-item">
          <span
            className={
              tabs === "DrtDailyOrderParent" ? "nav-link active" : "nav-link"
            }
            onClick={() => setTabs("DrtDailyOrderParent")}
          >
            DRT's Daily Order
          </span>
        </li>
        <li className="nav-item">
          <span
            className={
              tabs === "DrtFinalOrderParent" ? "nav-link active" : "nav-link"
            }
            onClick={() => setTabs("DrtFinalOrderParent")}
          >
            DRT's Final Order
          </span>
        </li>
      </ul>

      {tabs === "DrtDailyOrderParent" ? (
        <DrtDailyOrderParent />
      ) : (
        <DrtFinalOrderParent />
      )}
    </>
  );
};

export default DrtDailyFinalOrderParent;
