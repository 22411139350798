import { useState } from "react";
import CaseNoJudgementNo from "./CaseNoJudgementNo";
import DailyFinalOrderJudgementNo from "./DailyFinalOrderJudgementNo";
import DiaryNoJudgementNo from "./DiaryNoJudgementNo";
import PartyNameJudgeName from "./PartyNameJudgeName";


const DratOrderFinalOrderParentHome = () => {
  const [relatedLink, setRelatedLink] = useState("DiaryNoJudgementNo");
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 tab-one p-0">
        <ul
          className="nav toptab-one mb-3 inner-tab mx-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={
                relatedLink === "DiaryNoJudgementNo"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab2"
              data-toggle="pill"
              href="#pills-cause"
              role="tab"
              aria-controls="pills-cause"
              aria-selected="false"
              onClick={() => setRelatedLink("DiaryNoJudgementNo")}
            >
              Diary No.
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "CaseNoJudgementNo"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("CaseNoJudgementNo")}
            >
              Case No.
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "PartyNameJudgeName"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab3"
              data-toggle="pill"
              href="#pills-order"
              role="tab"
              aria-controls="pills-order"
              aria-selected="false"
              onClick={() => setRelatedLink("PartyNameJudgeName")}
            >
              Party/Judge Name
            </a>
          </li>
         
          <li className="nav-item">
            <a
              className={
                relatedLink === "DailyFinalOrderJudgementNo"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab4"
              data-toggle="pill"
              href="#pills-notice"
              role="tab"
              aria-controls="pills-notice"
              aria-selected="false"
              onClick={() => setRelatedLink("DailyFinalOrderJudgementNo")}
            >
              DRAT's Daily/Final Order
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {relatedLink === "CaseNoJudgementNo" ? (
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              // aria-labelledby="pills-home-tab"
            >
              <CaseNoJudgementNo />
            </div>
          ) : relatedLink === "DiaryNoJudgementNo" ? (
            <div
              className="tab-pane fade show active"
              id="pills-cause"
              role="tabpanel"
              // aria-labelledby="pills-profile-tab"
            >
              <DiaryNoJudgementNo />
            </div>
          ) : relatedLink === "PartyNameJudgeName" ? (
            <div
              className="tab-pane fade show active"
              id="pills-order"
              role="tabpanel"
              // aria-labelledby="pills-profile-tab"
            >
              <PartyNameJudgeName />
            </div>
          )  : (
            <div
              className="tab-pane fade show active"
              id="pills-notice"
              role="tabpanel"
              // aria-labelledby="pills-contact-tab"
            >
              <DailyFinalOrderJudgementNo />
            </div>
          )}

                           </div>
      </div>
    </>
  );
};

export default DratOrderFinalOrderParentHome;
