import React, { useEffect, useState } from "react";
import axiosBaseURL from "../../httpCommon";

const AccessStatement = ()=>{

    const [helpcontent, setHelpcontent] = useState(null);

    const getHelp = async() => {
     await axiosBaseURL.get( '/site/accessibility-statement').then((result) => {
        setHelpcontent(result.data.data)
      })
  
    }
    useEffect(() => { 
  
        getHelp();
  
   }, []);
  
   if (!helpcontent) return (<><h6>Loading......</h6></>);
  //console.log(helpcontent)
  


    return (
        <div className="about">
        <div className="inner-bg">
                    <div className="container">
                       <div className="row">
                          <div className="col-12">
                          <div className="b-title"><h5>{helpcontent.title}</h5></div>
                      </div>
                    </div> 
                 </div>
           </div>
           <div className="breadcrumb-bg">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
               <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{helpcontent.title}</li>
                </ol>
              </nav>
            </div>
         </div>
         </div>
      </div>

      <div className="common-bg">
         <div className="container">
            <div className="row">
               <div className="col-12 col-sm-12 col-md-12 col-lg-12">
             <div className="about-content">
                <h6>{helpcontent.title}</h6>
                {/* <h2>DEBTS RECOVERY APPELLATE TRIBUNALS (DRATs)</h2>
                <div className="underline"></div> */}
                <div dangerouslySetInnerHTML={{__html: helpcontent.body}}></div>
                </div>
             </div>
             </div>
             </div>
             </div>


           </div>
    )
}
export default AccessStatement;