import { years } from "../../../../../services/YearService";
import { Formik } from "formik";
import { useEffect, useState, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import Captcha from "react-numeric-captcha";
import * as Yup from "yup";
import { axiosBaseURLExternal } from "../../../../../httpCommon";

const DiaryNoWise = () => {
  const [drtLocationName, setDrtLocationName] = useState("");
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  // state to control modal
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);

  // fetching first dropDownData
  const [drtLocations, setDrtLocations] = useState([]);
  const fetchDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDrtLocations(sortedLocation);
  };

  useEffect(() => {
    fetchDrtLocation();
  }, []);

  // fetching final data after post call
  const [loading, setLoading] = useState(false);
  const [caseDetail, setCaseDetail] = useState("");
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    setLoading(true);
    try {
      const response = await axiosBaseURLExternal.post(url, data);
      setCaseDetail(response.data[0]);
    } catch (error) {
      setCaseDetail("");
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  // Initial Value
  const initialValues = {
    drtDratId: "",
    diaryNo: "",
    caseYear: new Date().getFullYear(),
  };

  // validation
  const validationSchema = Yup.object({
    drtDratId: Yup.string().required("Required"),
    diaryNo: Yup.string()
      .trim()
      .max(8, "Maximum length is 8 characters")
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required("Required"),
    caseYear: Yup.string().required("Required"),
  });

  // submit handler
  const handleSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDrtId: values.drtDratId,
      diaryNo: values.diaryNo,
      diaryYear: values.caseYear,
    };
    getDrtDratName(values.drtDratId);
    fetchCaseDetail("transfer_case", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);
    setCaptchaStatus(false);
    capt.current.refresh();
    // resetForm();
  };
  const newMapDate = (date) => {
    if (date) {
        const [yyyy, mm, dd] = date?.split("-");
        return [
            dd.length === 1 ? `0${dd}` : dd,
            mm.length === 1 ? `0${mm}` : mm,
            yyyy
        ].join("/");
    }
    return null;
  };

  const getDrtDratName = (id) => {
    let name = drtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtLocationName(name[0]?.SchemaName);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* Field 1 */}
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  Old DRT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drtDratId"
                    name="drtDratId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.drtDratId}
                  >
                    <option>Select DRT</option>
                    {drtLocations &&
                      drtLocations
                        .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                        .map((item, index) => (
                          <option value={`${item.schemeNameDrtId}`} key={index}>
                            {item.SchemaName}
                          </option>
                        ))}
                  </select>
                  {touched.drtDratId && errors.drtDratId ? (
                    <div className="error">{errors.drtDratId}</div>
                  ) : null}
                </div>
              </div>

              {/* field2 */}
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Diary No
                </label>
                <div className="col-sm-7">
                  <input
                    id="diaryNo"
                    name="diaryNo"
                    type="text"
                    className="form-control"
                    placeholder="Enter Diary No"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.diaryNo}
                    autoComplete="off"
                  />
                  {touched.diaryNo && errors.diaryNo ? (
                    <div className="error">{errors.diaryNo}</div>
                  ) : null}
                </div>
              </div>

              {/* field 3   */}
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Diary Year
                </label>
                <div className="col-sm-7">
                  <select
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="caseYear"
                    name="caseYear"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.caseYear}
                  >
                    {years.map((item, index) => (
                      <option value={item.year} key={index}>
                        {item.year}
                      </option>
                    ))}
                  </select>
                  {touched.caseYear && errors.caseYear ? (
                    <div className="error">{errors.caseYear}</div>
                  ) : null}
                </div>
              </div>

              {/* field 4   */}
              <div className="form-group capcha-sec">
                <div className="col-sm-12 px-0 d-flex">
                  <Captcha
                    onChange={(status) => {
                      setCaptchaStatus(status);
                    }}
                    ref={capt}
                    required
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={!captchaStatus}
                    className="btn btn-reload"
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
                {!captchaStatus ? (
                  <div className="captcha-text">Enter valid captcha</div>
                ) : null}
              </div>
            </form>

            {loading ? (
              <h6 className="loader">Loading ......</h6>
            ) : (
              <Modal
                show={isOpenModal}
                onHide={() => setIsOpenModal(false)}
                // className="modal-90w"
              >
                <Modal.Header>
                  DRT Case Transfer Report <br /> {drtLocationName}{" "}
                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Applicant V/S Respondent</th>
                    <th>New Case No.</th>
                    <th>New Dairy No.</th>
                    <th>Date of Filing</th>
                    <th>DRT Name</th>
                    
                  </tr>
                </thead>

                { caseDetail !== undefined && caseDetail ? (
                  <tbody>
                    <tr>
                        <td>1</td>
                      <td>
                        {caseDetail.pet_name} <br />
                        V/S <br />
                        {caseDetail.res_name}
                      </td>
                      <td>
                        {caseDetail.newcaseno}
                      </td>
                      <td>
                        {caseDetail.newdairyno}
                      </td>
                      <td>{newMapDate(caseDetail.dt_of_filing)}</td>
                      <td>
                      {caseDetail.drtname.charAt(0).toUpperCase() + caseDetail.drtname.slice(1)}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={5}> No Record Found </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </Modal.Body>
              </Modal>
            )}
           
          </>
        )}
      </Formik>
    </div>
  );
};

export default DiaryNoWise;
