import React, { useState } from "react";
import CaseNumberDrt from "./Tabs/CaseNumberDrt";
import DiaryNumberDrt from "./Tabs/DiaryNumberDrt";
import PartyNameDrt from "./Tabs/PartyNameDrt";
import AdvocateNameDrt from "./Tabs/AdvocateNameDrt";

function DrtCaseStatusParent() {
  const [tabs, setTabs] = useState("Diary Number");

  return (
    <>
      <ul className="nav nav-pills">
        <li className="nav-item">
          <span
            className={tabs === "Diary Number" ? "nav-link active" : "nav-link"}
            onClick={() => setTabs("Diary Number")}
          >
            Diary Number
          </span>
        </li>
        <li className="nav-item">
          <span
            className={tabs === "Case Number" ? "nav-link active" : "nav-link"}
            onClick={() => setTabs("Case Number")}
          >
            Case Number
          </span>
        </li>
        <li className="nav-item">
          <span
            className={tabs === "Party Name" ? "nav-link active" : "nav-link"}
            onClick={() => setTabs("Party Name")}
          >
            Party Name
          </span>
        </li>
        <li className="nav-item">
          <span
            className={tabs === "Advocate Name" ? "nav-link active" : "nav-link"}
            onClick={() => setTabs("Advocate Name")}
          >
            Advocate Name
          </span>
        </li>
      </ul>

      {tabs === "Case Number" ? (
        <CaseNumberDrt />
      ) : tabs === "Diary Number" ? (
        <DiaryNumberDrt />
      ) : tabs === "Party Name" ? (
        <PartyNameDrt />
      ) : <AdvocateNameDrt/>}
    </>
  );
}

export default DrtCaseStatusParent;
