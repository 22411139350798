import React from "react";
import Captcha from "react-numeric-captcha";
import { useState, useEffect, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../../../../../constants";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { years } from "../../../../../../../services/YearService";

const CaseNoDailyOrder = () => {
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();
  // state to control Model
  const [isOpenModal, setIsOpenModal] = useState(false);

  // fetching first dropDownData
  const [dratDrtLocations, setDratDrtLocations] = useState([]);
  const fetchDratDrtLocation = async () => {
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getDrtDratScheamName`
    );
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratDrtLocations(sortedLocation);
  };

  // fetch second dropDownData
  const [caseTypeDrt, setCaseTypeDrt] = useState([]);
  const fetchCaseType = async (value) => {
    const data = {
      schemeNameDrtId: value,
    };
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getDrtDratCaseTyepName`,
      data,
      config
    );
    setCaseTypeDrt(res.data);
  };

  // get final data after sending required payload
  const [loading, setLoading] = useState(false);
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);

  const fetchCaseDetail = async (url, data) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetail(response.data);
    } catch (error) {
      setError(true);
      setCaseDetail([]);
      setLoading(false);
    }

    setLoading(false);
  };

  // calling function for first dropDown data
  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const initialValues = {
    drt: "",
    caseType: "",
    caseNo: "",
    caseYear: new Date().getFullYear(),
  };

  // validation
  const validationSchema = Yup.object({
    drt: Yup.string().required("Required"),
    caseType: Yup.string().required("Required"),
    caseNo: Yup.string()
      .trim()
      .max(8, "Maximum length is 8 characters")
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required("Required"),
    caseYear: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDrtId: values.drt,
      caseType: values.caseType,
      caseNo: values.caseNo,
      caseYear: values.caseYear,
    };

    fetchCaseDetail("getDrtDailyOrderReportCaseNo", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  const doubleDigitDate = (date) => {
    // let date = "25/8/2022"
    if (date) {
      let arr = date.split("/");
      if (arr[0].length === 1) arr[0] = "0" + arr[0];
      if (arr[1].length === 1) arr[1] = "0" + arr[1];

      return arr.join("/");
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          errors,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* field 1 */}

              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  DRT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drt"
                    name="drt"
                    onChange={(e) => {
                      handleChange(e);
                      fetchCaseType(e.target.value);
                    }}
                    value={values.drt}
                    onBlur={handleBlur}
                  >
                    <option>Select DRT</option>
                    {dratDrtLocations
                      .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                      .map((item, index) => (
                        <option value={`${item.schemeNameDrtId}`} key={index}>
                          {item.SchemaName}
                        </option>
                      ))}
                  </select>
                  {touched.drt && errors.drt ? (
                    <div className="error">{errors.drt}</div>
                  ) : null}
                </div>
              </div>

              {/* field 2*/}

              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Case Type
                </label>
                <div className="col-sm-7">
                  <select
                    id="caseType"
                    name="caseType"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    value={values.caseType}
                    onBlur={handleBlur}
                  >
                    <option>Select Case Type</option>
                    {caseTypeDrt.map((item, index) => (
                      <option value={`${item.caseType}`} key={index}>
                        {item.caseTypeName}
                      </option>
                    ))}
                  </select>
                  {touched.caseType && errors.caseType ? (
                    <div className="error">{errors.caseType}</div>
                  ) : null}
                </div>
              </div>

              {/* field 3*/}

              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Case No
                </label>
                <div className="col-sm-7">
                  <input
                    id="caseNo"
                    name="caseNo"
                    type="text"
                    className="form-control"
                    placeholder="Enter Case No"
                    onChange={handleChange}
                    value={values.caseNo}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  {touched.caseNo && errors.caseNo ? (
                    <div className="error">{errors.caseNo}</div>
                  ) : null}
                </div>
              </div>

              {/* field 4*/}
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Case Year
                </label>
                <div className="col-sm-7">
                  <select
                    type="text"
                    className="form-control"
                    placeholder="Select Case Year"
                    id="caseYear"
                    name="caseYear"
                    onChange={handleChange}
                    value={values.caseYear}
                    onBlur={handleBlur}
                  >
                    {years.map((item, index) => (
                      <option value={item.year} key={index}>
                        {item.year}
                      </option>
                    ))}
                  </select>
                  {touched.caseYear && errors.caseYear ? (
                    <div className="error">{errors.caseYear}</div>
                  ) : null}
                </div>
              </div>

              {/* field 5*/}

              <div className="form-group capcha-sec">
                <div className="col-sm-12 px-0 d-flex">
                  <Captcha
                    onChange={(status) => {
                      setCaptchaStatus(status);
                    }}
                    ref={capt}
                    required
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={!captchaStatus}
                    className="btn btn-reload"
                  >
                    <i className="fas fa-search"><span className="sr-only">no text</span></i>
                  </button>
                </div>
                {!captchaStatus ? (
                  <div className="captcha-text">Enter valid captcha</div>
                ) : null}
              </div>
            </form>
            {loading ? (
              <h6 className="loader">Loading ......</h6>
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  DRT's Daily Order Report
                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>
                            <center>Applicant V/S Respondent</center>
                          </th>
                          <th>Dairy No.</th>
                          <th>Application No.</th>
                          <th>Date of Order</th>
                          <th>Pronounced By</th>
                          <th>View Daily Order(s)</th>
                        </tr>
                      </thead>
                      {!error && caseDetail.length > 0 ? (
                        <tbody>
                          {caseDetail.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <center>
                                  {item.applicantName} <br />
                                  <strong>V/S</strong>
                                  <br />
                                  {item.respondentName}
                                </center>
                              </td>
                              <td>{item.diaryno}</td>
                              <td>{item.applicantno}</td>
                              <td>{doubleDigitDate(item.dateoffiling)}</td>
                              <td>{item.pronouncedBy}</td>
                              <td>
                                <a
                                  target="_blank"
                                  href={`${item.dailyOrderPdf}`}
                                >
                                  <i className="fas fa-search"><span className="sr-only">no text</span></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="8"> No Record Found </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default CaseNoDailyOrder;
