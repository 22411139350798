import React from "react";
import { Routes, Route } from "react-router-dom";
// import Aboutus from './components/front/about';
import AboutUs from "./components/front/AboutUs";
import Home from "./components/front/home/Home";
import Underconstruction from "./components/front/underconstruction";
import CaseDetailParent from "./components/front/case-detail/CaseDetailParent";
import OrderParent from "./components/front/orders/OrderParent";
import CauseListHome from "./components/front/cause-list/CauseListMain";
import CaseTransferParent from "./components/front/case-transfer/CaseTransferParent";
// import Help from "./components/front/Help";
import TermsCondition from "./components/front/termsCondition";
import Copyright from "./components/front/copyright";
import PrivacyPolicy from "./components/front/privacyPolicy";
import HyperlinkPolicy from "./components/front/hyperlinkpolicy";
import Disclaimer from "./components/front/disclaimer";
import AccessStatement from "./components/front/AccessStatement";
import AboutusDetail from "./components/front/relatedLink/aboutusDetail";
import ActRules from "./components/front/relatedLink/actrule";
import MissionVission from "./components/front/relatedLink/missionvission";
import ActAndRuleParent from "./components/Acts/ActAndRuleParent";
import ContactUs from "./components/front/ContactUs";
import AllNotices from "./components/front/AllNotice";
import Login from "./components/front/home/Login";
import Gallery from "./components/front/Gallery/Gallery";
import Tender from "./components/front/Tender";
import ListCPIO from "./components/front/ListCpio";
import Downloads from "./components/front/Downloads";
import Vacancies from "./components/front/Vacancies";
import Search from "./components/front/Search";
import RTI from "./components/front/RTI";
import SiteMap from "./components/front/SiteMap";
// import Feedback from "./components/front/Feedback";
import UsefulLinks from "./components/front/UsefulLinks";
import RtiDetail from "./components/front/RtiDetail";
import Screenreader from "./components/front/Screenreader";
import FAQs from "./components/front/FAQs";
import OrganizationalStructure from "./components/front/OrganizationalStructure";
import WhatsNewDetail from "./components/front/home/WhatsNew/WhatsNewDetail";
import WhatsNewSingle from "./components/front/home/WhatsNew/WhatsNewDetailSingle";
import Help from "./components/front/HelpDesk/Help";
import Manager from "./components/front/Manager";

class AppRouting extends React.Component {
  render() {
    return (
      <Routes>
        {/* <Route exact path="/" element={<App />} /> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="aboutus" element={<AboutUs />}>
          <Route path="" element={<AboutusDetail />} />
          <Route path="aboutdetail" element={<AboutusDetail />} />
          <Route path="actrule" element={<ActRules />} />
          <Route path="missionvission" element={<MissionVission />} />
        </Route>
        <Route exact path="casedetail" element={<CaseDetailParent />} />
        <Route exact path="causelist" element={<CauseListHome />} />
        <Route exact path="order" element={<OrderParent />} />
        <Route exact path="caseTransfer" element={<CaseTransferParent />} />
        <Route exact path="underconstruction" element={<Underconstruction />} />
        <Route exact path="help" element={<Help />} />
        <Route exact path="termsCondition" element={<TermsCondition />} />
        <Route exact path="copyright" element={<Copyright />} />
        <Route exact path="privacy" element={<PrivacyPolicy />} />
        <Route exact path="hyperpolicy" element={<HyperlinkPolicy />} />
        <Route exact path="disclaimer" element={<Disclaimer />} />
        <Route exact path="accessability" element={<AccessStatement />} />
        <Route exact path="whatsnewdetail" element={<WhatsNewDetail />} />
        <Route
          exact
          path="whats-new-detail-Single/:id"
          element={<WhatsNewSingle />}
        />
        <Route path="actnrule" element={<ActAndRuleParent />} />
        <Route path="contactus" element={<ContactUs />} />
        <Route path="allnotice" element={<AllNotices />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="listOfcpio" element={<ListCPIO />} />
        <Route path="tender" element={<Tender />} />
        <Route path="downloads" element={<Downloads />} />
        <Route path="login" element={<Login />} />
        <Route path="vacancies" element={<Vacancies />} />
        <Route path="search" element={<Search />} />
        <Route path="rti" element={<RTI />} />
        <Route path="sitemap" element={<SiteMap />} />
        {/* <Route path="feedback" element={<Feedback />} /> */}
        <Route path="usefullink" element={<UsefulLinks />} />
        <Route path="rtidetail/:id" element={<RtiDetail />} />
        <Route path="screenreader" element={<Screenreader />} />
        <Route path="faqs" element={<FAQs />} />
        <Route path="web-information-manager" element={<Manager />} />
        <Route
          path="organizationalstructure"
          element={<OrganizationalStructure />}
        />
        <Route path="*" element={<Underconstruction />} />
      </Routes>
    );
  }
}

export default AppRouting;
