import React, { useEffect, useState, useContext } from "react";
import axiosBaseURL from "../../httpCommon";
import { DrtContext } from "../../context/DrtContext";

const Acts = () => {
  const [loading, setLoading] = useState(false);
  const { EncryptDecrypt } = useContext(DrtContext);
  const [about, setabout] = useState([]);

  const getAbout = async () => {
    setLoading(true);
    await axiosBaseURL.get("/site/act-rules").then((result) => {
      const temp = EncryptDecrypt(result.data)
      setabout(temp.data);
    });
    setLoading(false);
  };
  useEffect(() => {
    getAbout();
  }, []);

  //if (!about) return (<><h6 className="loader">Loading......</h6></>);

  return (
    <>
      <div className="about">
        {loading ? (
          <h6 className="loader">Loading ...</h6>
        ) : (
          <>
            <div className="common-bg">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="about-content">
                      <div
                        dangerouslySetInnerHTML={{ __html: about.body }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Acts;
