import React from "react";


const Underconstruction = () =>{

    return(
       <>
    

<div className="about">
        <div className="inner-bg">
                    <div className="container">
                       <div className="row">
                          <div className="col-12">
                          <div className="b-title"><h5>Page Under Development</h5></div>
                      </div>
                    </div> 
                 </div>
           </div>
           <div className="breadcrumb-bg">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
               <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Page Under Development</li>
                </ol>
              </nav>
            </div>
         </div>
         </div>
      </div>
      <div className="construction"> 
      <h2>Page Under Development</h2>
      </div>
           
  </div>
  
  </>
        )
}

export default Underconstruction;