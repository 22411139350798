import { useState, useEffect, useContext } from "react";
import axiosBaseURL from "../../httpCommon";
import {DrtContext} from "../../context/DrtContext";


function Circulars() {
  const { EncryptDecrypt } = useContext(DrtContext);
  const [tenderList, setTenderList] = useState([]);
  const [loading, setLoading] = useState(true);

  // fetching List of CPIO's
  const fetchTenderList = async () => {
    const result = await axiosBaseURL.get("/get-notices/4");
    const temp = EncryptDecrypt(result.data)
    setTenderList(temp.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchTenderList();
  }, []);

  if (loading) {
    return <h6 className="loader">Loading......</h6>;
  } else {
  return (
    // <div className="common-bg">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-12 col-sm-12 col-md-12 col-lg-12">
    //         <div className="about-content">
    //           The SARFAESI Act, 2002 aims to regulate securitization and
    //           reconstruction of financial assets and enforcement of security
    //           interest and to provide for a Central database of security
    //           interests created on property rights and for connected matters
    //           therewith. The Act has simplified the recovery procedure for banks
    //           and specified financial institutions for recovery of secured debts
    //           from borrowers without intervention of Courts at the first stage.
    //           Borrowers can file applications in the Debts Recovery Tribunals
    //           (DRTs) against action taken for enforcement of security interest
    //           under this Act, with the appellate jurisdiction for such
    //           applications lying with the Debts Recovery Appellate Tribunals
    //           (DRATs).The Act is applicable to cases where security interest for
    //           securing repayment of any financial asset is more than Rs.1 lakh
    //           and the amount due is 20% or more of the principal amount and
    //           interest thereon. The Act is not applicable to any security
    //           interest created in agricultural land and certain properties not
    //           liable to attachment under some specified Acts.
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="row m-0">
    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
      <div className="table-responsive">
        <table className="table table-striped common-table bluetable">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Title</th>
              <th>Summary</th>
              <th>Issue Date</th>
              <th>Document</th>
            </tr>
          </thead>
          <tbody>
            {tenderList.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{item.summery}</td>
                <td>{item.from_date}</td>
                <td>
                  <a target="_blank" href={`${item.aad_file}`}>
                    <i className="fas fa-search"><span className="sr-only">no text</span></i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  );
}
}

export default Circulars;
