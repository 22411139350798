import React, { useEffect, useState, useContext } from "react";
import { DrtContext } from "../../../context/DrtContext";
import ReactPaginate from "react-paginate";
import { axiosBaseURLExternal } from "../../../httpCommon";
import ExternalLinkPopup from "../../../services/ExternalLinkPopup";
import archiveDate from "../../../services/Date";

const PublicNotice = () => {
  const {
    externalLink,
    source,
    setSource,
    setExternalLink,
    setExternalLinkPopupShow,
  } = useContext(DrtContext);
  const [loading, setLoading] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [drtDratLocationName, setDrtDratLocationName] = useState(
    "DEBT RECOVERY APPELLATE TRIBUNAL - DELHI"
  );
  const [publicNotices, setPublicNotices] = useState([]);
  const [selectedDratLocationId, setSelectedDratLocationId] = useState("100");
  const [selectedDrtLocationId, setSelectedDrtLocationId] = useState("");

  // DRAT Locations Dropdown
  const [dratLocations, setDratLocations] = useState([]);
  const fetchDratLocations = async () => {
    try {
      const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
      setDratLocations(res.data);
    } catch (error) {
      console.error("Error fetching DRAT locations:", error);
      setDratLocations([]);
    }
  };

  useEffect(() => {
    fetchDratLocations();
    fetchPublicNotices(selectedDratLocationId);
    fetchDrtLocations(selectedDratLocationId);
  }, [selectedDratLocationId]);

  const getDrtDratName = (id) => {
    try {
      let name = dratLocations.filter((item) => item.schemeNameDrtId === id);
      setDrtDratLocationName(name[0]?.SchemaName);
    } catch (error) {
      console.error("Error getting DRAT name:", error);
      setDrtDratLocationName("");
    }
  };

  // DRT Locations Dropdown
  const [drtLocations, setDrtLocations] = useState([]);
  const fetchDrtLocations = async (id) => {
    try {
      const data = {
        schemeNameDratId: id,
      };
      const res = await axiosBaseURLExternal.post("getDrtSchemaName", data);
      setDrtLocations(res.data);
    } catch (error) {
      console.error("Error fetching DRT locations:", error);
      setDrtLocations([]);
    }
  };

  const fetchPublicNotices = async (id) => {
    try {
      setLoading(true);
      const data = {
        schemeNameDrtId: id,
      };
      const res = await axiosBaseURLExternal.post("getPublicNotice", data);
      if (showArchive) {
        setPublicNotices(
          res.data.map((item, index) => ({ ...item, sr: index + 1 }))
        );
      } else {
        const twelveMonthsAgo = archiveDate();
        const filteredEntries = res.data.filter((item) => {
          const noticeDate = parseDate(item.publicnoticdate);
          return noticeDate >= twelveMonthsAgo;
        });
        setPublicNotices(
          filteredEntries.map((item, index) => ({ ...item, sr: index + 1 }))
        );
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching public notices:", error);
      setPublicNotices([]);
      setLoading(false);
    }
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const doubleDigitDate = (date) => {
    if (date) {
      let arr = date.split("/");
      if (arr[0].length === 1) arr[0] = "0" + arr[0];
      if (arr[1].length === 1) arr[1] = "0" + arr[1];
      return arr.join("/");
    }
  };

  // Pagination
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [startListNo, setStartListNo] = useState(0);

  useEffect(() => {
    const endListNo = startListNo + itemsPerPage;
    setCurrentItems(
      publicNotices ? publicNotices.slice(startListNo, endListNo) : []
    );
    setPageCount(Math.ceil(publicNotices?.length / itemsPerPage));
  }, [startListNo, itemsPerPage, publicNotices]);

  const handlePageClick = (event) => {
    const newStartListNo =
      (event.selected * itemsPerPage) % publicNotices.length;
    setStartListNo(newStartListNo);
  };

  useEffect(() => {
    if (selectedDrtLocationId !== "") fetchPublicNotices(selectedDrtLocationId);
    else fetchPublicNotices(selectedDratLocationId);
  }, [showArchive]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4 d-flex justify-content-between align-items-center">
          <div className="w-25">
            <h2>Public Notice</h2>
          </div>
          <div className="w-75 d-flex">
            <div className="w-50 pr-3">
              <select
                className="form-control"
                onChange={(e) => {
                  setSelectedDratLocationId(e.target.value);
                  fetchDrtLocations(e.target.value);
                  getDrtDratName(e.target.value);
                  fetchPublicNotices(e.target.value);
                  setDrtLocations([]);
                }}
                value={selectedDratLocationId}
              >
                {dratLocations &&
                  dratLocations
                    .filter((item) => parseInt(item.schemeNameDrtId) >= 100)
                    .map((item, index) => (
                      <option value={`${item.schemeNameDrtId}`} key={index}>
                        {item.SchemaName}
                      </option>
                    ))}
              </select>
            </div>
            <div className="w-50 pr-3">
              <select
                className="form-control "
                onChange={(e) => {
                  fetchPublicNotices(e.target.value);
                  getDrtDratName(e.target.value);
                  setSelectedDrtLocationId(e.target.value);
                }}
              >
                <option hidden>Select DRT</option>
                {drtLocations &&
                  drtLocations.map((item, index) => (
                    <option value={item.schema_id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 notice-db">
          <button
            className="btn btn-warning mb-3"
            onClick={() => setShowArchive(!showArchive)}
          >
            {" "}
            {showArchive ? "Show last 12 months Notices" : "Show Archive"}
          </button>
          <div className="table-responsive">
            <table className="table table-striped common-table tender">
              <thead>
                <tr>
                  <th>Sl.No.</th>
                  <th>DRT/DRAT Name</th>
                  <th>Notice Title</th>
                  <th>Notice Issue Date</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td>
                      <h6 className="loader">Loading ...</h6>
                    </td>
                  </tr>
                ) : currentItems.length > 0 ? (
                  currentItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{item.sr}</td>
                        <td>{drtDratLocationName}</td>
                        <td>{item.massage}</td>
                        <td>{doubleDigitDate(item.publicnoticdate)}</td>
                        <td>
                          <a
                            target="_blank"
                            className="external-link"
                            onClick={() => {
                              setExternalLinkPopupShow(true);
                              setExternalLink(item.auctionurl);
                              setSource("PublicNotice");
                            }}
                            // href={item.auctionurl}
                          >
                            <i className="fas fa-file-pdf">
                              <span className="sr-only">no text</span>
                            </i>
                          </a>
                          <br />
                          <small> size 589kb</small>
                          <br />
                          {/* <small>Format</small><br/>
                            <small>Usage Instruction</small> */}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">
                      <p>No Data Found ...</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {externalLink !== "" && source === "PublicNotice" ? (
          <ExternalLinkPopup />
        ) : null}

        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <div className="nav-pagination mb-5">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicNotice;
