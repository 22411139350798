import React from "react";
import Captcha from "react-numeric-captcha";
import { useState, useEffect, useContext, useRef } from "react";

import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../../../constants";

import axios from "axios";
import { useFormik } from "formik";
import { axiosBaseURLExternal } from "../../../../../httpCommon";

const DrtDailyFinalOrder = () => {
  const [formattedDate, setFormattedDate] = useState(""); //newDate

  const [loading, setLoading] = useState(false);

  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  // state to control Model
  const [isOpenModal, setIsOpenModal] = useState(false);
  // state for first dropDownData
  const [dratLocations, setDratLocations] = useState([]);
  //state for second dropDownData
  // states for Date

  // fetching first dropDownData (All Locations)
  const fetchDratDrtLocation = async () => {
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getDrtDratScheamName`
    );
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratLocations(sortedLocation);
  };

  const [drtLocations, setDrtLocations] = useState([]);
  const fetchDrtLocations = async (data) => {
    try {
      const res = await axiosBaseURLExternal.post("getDrtSchemaName", data);
      setDrtLocations(res.data);
    } catch (error) {
      setDrtLocations(null);
    }
  };

  // get final data after sending required payload
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);

  const fetchCaseDetail = async (url, data) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  // calling function for first dropDown data
  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const initialValues = {
    drat: "",
    drt: "",
    orderType: "",
    date: "",
  };

  const validate = (values) => {
    let errors = {};
    if (!values.drat) {
      errors.drat = "Required";
    }
    if (!values.drt) {
      errors.drt = "Required";
    }
    if (!values.orderType) {
      errors.orderType = "Required";
    }
    if (!values.date) {
      errors.date = "Required";
    }

    return errors;
  };

  const onSubmit = (values, { resetForm }) => {
    // console.log("values are ", values);

    const caseDetailData = {
      schemeNameDratId: values.drat,
      schemeNameDrtId: values.drt,
      dratDailyFinalOrderId: values.orderType,
      fromDate: formattedDate,
    };

    fetchCaseDetail("getDratDrtDailyFinalOrderReport", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const doubleDigitDate = (date) => {
    if (date) {
      let arr = date.split("/");
      if (arr[0].length === 1) arr[0] = "0" + arr[0];
      if (arr[1].length === 1) arr[1] = "0" + arr[1];

      return arr.join("/");
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="w-100">
        <div className="form-row">
          {/* field 1 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="staticEmail">
              DRAT
            </label>

            <select
              className="form-control"
              placeholder=""
              type="text"
              id="drat"
              name="drat"
              onChange={(e) => {
                formik.handleChange(e);
                fetchDrtLocations({ schemeNameDratId: e.target.value });
              }}
              value={formik.values.drat}
              onBlur={formik.handleBlur}
            >
              <option>Select DRAT Location</option>
              {dratLocations
                .filter((i) => i.schemeNameDrtId > 99)
                .map((item, index) => (
                  <option value={`${item.schemeNameDrtId}`} key={index}>
                    {item.SchemaName}
                  </option>
                ))}
            </select>
            {formik.touched.drat && formik.errors.drat ? (
              <div className="error">{formik.errors.drat}</div>
            ) : null}
          </div>

          {/* field 2 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="staticEmail">
              {" "}
              DRT
            </label>

            <select
              className="form-control"
              placeholder=""
              type="text"
              id="drt"
              name="drt"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.drt}
              onBlur={formik.handleBlur}
            >
              <option>Select DRT Location</option>
              {drtLocations &&
                drtLocations.map((item, index) => (
                  <option value={`${item.schema_id}`} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
            {formik.touched.drt && formik.errors.drt ? (
              <div className="error">{formik.errors.drt}</div>
            ) : null}
          </div>

          {/* field 3 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              Daily/Final Order
            </label>

            <select
              id="orderType"
              name="orderType"
              type="select"
              className="form-control"
              placeholder=""
              onChange={formik.handleChange}
              value={formik.values.orderType}
              onBlur={formik.handleBlur}
            >
              <option>Select Daily/Final Order</option>
              <option value="1"> Daily Order </option>
              <option value="2"> Final Order </option>
            </select>
            {formik.touched.orderType && formik.errors.orderType ? (
              <div className="error">{formik.errors.orderType}</div>
            ) : null}
          </div>

          {/* Field 4 */}
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="inputEmail4">
              From Date
            </label>
            <input
              className="form-control"
              placeholder=""
              type="date"
              id="date"
              name="date"
              onChange={(e) => {
                formik.handleChange(e);
                setFormattedDate(e.target.value.split("-").reverse().join("/"));
              }}
              value={formik.values.date}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.date && formik.errors.date ? (
              <div className="error">{formik.errors.date}</div>
            ) : null}
          </div>

          {/* field Captcha */}

          <div className="form-group col-md-8 main-menu-captcha capcha-sec">
            <label>Captcha</label>
            <div className="col-sm-12 px-0 d-flex">
              <Captcha
                onChange={(status) => {
                  setCaptchaStatus(status);
                }}
                ref={capt}
                required
              />
              <br />
              <button
                type="submit"
                disabled={!captchaStatus}
                className="btn btn-reload"
              >
                <i className="fas fa-search"><span className="sr-only">no text</span></i>
              </button>
            </div>
            {!captchaStatus ? (
              <div className="captcha-text">Enter valid captcha</div>
            ) : null}
          </div>
        </div>
      </form>

      {loading ? (
        <h6 className="loader">Loading ......</h6>
      ) : (
        <Modal
          show={isOpenModal}
          onHide={() => setIsOpenModal(false)}
          // className="modal-90w"
        >
          <Modal.Header>
            DRAT ORDER/JUDGEMENT REPORT
            <Button
              type="button"
              className="close"
              onClick={() => setIsOpenModal(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>
                      <center>Applicant V/S Respondent</center>
                    </th>
                    <th>Dairy No.</th>
                    <th>Case No.</th>
                    <th>Date of Order</th>
                    <th>Pronounced By</th>
                    <th>View Daily Order(s)</th>
                  </tr>
                </thead>
                {!error && caseDetail && caseDetail.length > 0 ? (
                  <tbody>
                    {caseDetail.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <center>
                            {item.applicantName} <br />
                            vs
                            <br />
                            {item.respondentName}
                          </center>
                        </td>
                        <td>{item.diaryno}</td>
                        <td>{item.applicantno}</td>
                        <td>{doubleDigitDate(item.dateoffiling)}</td>
                        <td>{item.pronouncedBy}</td>
                        <td>
                          <a target="_blank" href={`${item.dailyOrderPdf}`}>
                            <i className="fas fa-search"><span className="sr-only">no text</span></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <>
                    <tbody>
                      <tr>
                        <td colSpan={"9"}> No Record Found </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default DrtDailyFinalOrder;
