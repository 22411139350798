import React, { useState } from "react";
import CaseNumber from "./tabs-daily/CaseNumber";
import DiaryNumber from "./tabs-daily/DiaryNumber";
import IaOrder from "./tabs-daily/IA_Order";

const DrtDailyFinalOrderParent = () => {
  const [tabs, setTabs] = useState("Diary Number");

  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 tab-one p-0">
      <ul
          className="nav toptab-one mb-3 inner-tab mx-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <span
              className={
                tabs === "Diary Number" ? "nav-link active" : "nav-link"
              }
              onClick={() => setTabs("Diary Number")}
            >
              Diary No.
            </span>
          </li>
          <li className="nav-item">
            <span
              className={
                tabs === "Case Number" ? "nav-link active" : "nav-link"
              }
              onClick={() => setTabs("Case Number")}
            >
              Case No.
            </span>
          </li>
          <li className="nav-item">
            <span
              className={tabs === "IA Order" ? "nav-link active" : "nav-link"}
              onClick={() => setTabs("IA Order")}
            >
              IA Order
            </span>
          </li>
        </ul>

        {tabs === "Case Number" ? (
          <CaseNumber />
        ) : tabs === "Diary Number" ? (
          <DiaryNumber />
        ) : tabs === "IA Order" ? (
          <IaOrder />
        ) : (
          null
        )}
      </div>
    </>
  );
};

export default DrtDailyFinalOrderParent;
