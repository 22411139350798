import React from "react";
import Captcha from "react-numeric-captcha";
import { useState, useEffect, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { years } from "../../../services/YearService";
import { axiosBaseURLExternal } from "../../../httpCommon";

const CaseTransferDrt = () => {
  const [loading, setLoading] = useState(false);

  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  const [drtLocationName, setDrtLocationName] = useState("");

  const [isOpenModal, setIsOpenModal] = useState(false);
  
  const [drtLocations, setDrtLocations] = useState([]);

  const fetchDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDrtLocations(sortedLocation);
  };

  useEffect(() => {
    fetchDrtLocation();
  }, []);

  // get final data after sending required payload
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    try {
      setLoading(true);
      const response = await axiosBaseURLExternal.post(url, data);
      setCaseDetail(response.data[0]);
      console.log("case transer response ",response.data[0])
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  const initialValues = {
    schemeNameDratDrtId: "",
    diaryNo: "",
    caseYear: new Date().getFullYear(),
  };

  const validate = (values) => {
    let errors = {};

    if (!values.schemeNameDratDrtId) {
      errors.schemeNameDratDrtId = "Required";
    }
    if (!values.diaryNo.trim()) {
      errors.diaryNo = "Required";
    } else if (values.diaryNo.trim().length > 8) {
      errors.diaryNo = "Maximum length is 8 characters";
    } else if (!/^[0-9]+$/.test(values.diaryNo.trim())) {
      errors.diaryNo = "Only numbers are allowed";
    }
    if (!values.caseYear) {
      errors.caseYear = "Required";
    }

    return errors;
  };

  const getDrtDratName = (id) => {
    let name = drtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtLocationName(name[0]?.SchemaName);
  };

  const onSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDrtId: values.schemeNameDratDrtId,
      diaryNo: values.diaryNo,
      diaryYear: values.caseYear,
    };

    getDrtDratName(values.schemeNameDratDrtId);

    fetchCaseDetail("transfer_case", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const doubleDigitDate = (date) => {
    // let date = "25/8/2022"
    if (date) {
        let arr = date.split('-');
        if (arr[0].length === 1) arr[0] = "0" + arr[0];
        if (arr[1].length === 1) arr[1] = "0" + arr[1];
        return arr[2] + '/' + arr[1] + '/' + arr[0];
    }
    return null;
  };

  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 pt-4">
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="staticEmail">
              {" "}
              Old DRT
            </label>

            <select
              className="form-control"
              placeholder=""
              type="text"
              id="schemeNameDratDrtId"
              name="schemeNameDratDrtId"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.schemeNameDratDrtId}
              onBlur={formik.handleBlur}
            >
              <option> Select DRT Location</option>
              {drtLocations &&
                drtLocations
                  .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                  .map((item, index) => (
                    <option value={`${item.schemeNameDrtId}`} key={index}>
                      {item.SchemaName}
                    </option>
                  ))}
            </select>
            {formik.touched.schemeNameDratDrtId &&
            formik.errors.schemeNameDratDrtId ? (
              <div className="error">{formik.errors.schemeNameDratDrtId}</div>
            ) : null}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              Diary No
            </label>

            <input
              id="diaryNo"
              name="diaryNo"
              type="text"
              className="form-control"
              placeholder="Enter Diary No"
              onChange={formik.handleChange}
              value={formik.values.diaryNo}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.diaryNo && formik.errors.diaryNo ? (
              <div className="error">{formik.errors.diaryNo}</div>
            ) : null}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              {" "}
              Diary Year
            </label>

            <select
              type="text"
              className="form-control"
              placeholder=""
              id="caseYear"
              name="caseYear"
              onChange={formik.handleChange}
              value={formik.values.caseYear}
              onBlur={formik.handleBlur}
            >
              {years.map((item, index) => (
                <option value={item.year} key={index}>
                  {item.year}
                </option>
              ))}
            </select>
            {formik.touched.caseYear && formik.errors.caseYear ? (
              <div className="error">{formik.errors.caseYear}</div>
            ) : null}
          </div>

          <div className="form-group col-md-8 main-menu-captcha capcha-sec">
            <label>Captcha</label>
            <div className="col-sm-12 px-0 d-flex">
              <Captcha
                onChange={(status) => {
                  setCaptchaStatus(status);
                }}
                ref={capt}
                required
              />
              <br />
              <button
                type="submit"
                disabled={!captchaStatus}
                className="btn btn-reload"
              >
                <i className="fas fa-search"><span className="sr-only">no text</span></i>
              </button>
            </div>
            {!captchaStatus ? (
              <div className="captcha-text">Enter valid captcha</div>
            ) : null}
          </div>
        </div>
      </form>

      {loading ? (
        <h6 className="loader">Loading ......</h6>
      ) : (
        <Modal
          show={isOpenModal}
          onHide={() => setIsOpenModal(false)}
          // className="modal-90w"
        >
          <Modal.Header>
            DRT Case Transfer Report <br /> {drtLocationName}
            <Button
              type="button"
              className="close"
              onClick={() => setIsOpenModal(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Applicant V/S Respondent</th>
                    <th>New Case No.</th>
                    <th>New Dairy No.</th>
                    <th>Date of Filing</th>
                    <th>DRT Name</th>
                    
                  </tr>
                </thead>

                { caseDetail !== undefined &&  caseDetail ? (
                  <tbody>
                    <tr>
                        <td>1</td>
                      <td>
                        {caseDetail.pet_name} <br />
                        V/S <br />
                        {caseDetail.res_name}
                      </td>
                      <td>
                        {caseDetail.newcaseno}
                      </td>
                      <td>
                        {caseDetail.newdairyno}
                      </td>
                      <td>{doubleDigitDate(caseDetail.dt_of_filing)}</td>
                      <td>
                      {caseDetail && caseDetail.drtname ? caseDetail.drtname.charAt(0).toUpperCase() + caseDetail.drtname.slice(1) : ""}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={5}> No Record Found </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      )}
     
    </div>
  );
};

export default CaseTransferDrt;
