import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { DrtContext } from "../../context/DrtContext";

function Search() {
  const { searchResult, loading } = useContext(DrtContext);
  console.log("search results are", searchResult);
  return (
    <>
      <div className="about">
        <div className="inner-bg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="b-title">
                  <h5>Search Results</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Search
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <h6 className="loader">Loading ......</h6>
        ) : (
          <div className="allnotice-sec">
            <div className="container">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <h1> Search Results </h1>

                {searchResult.map((item) => (
                  <>
                    <h3> {item.subject} </h3>
                    <h3> {item.type} </h3>
                  </>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Search;
