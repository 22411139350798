import React, { useState } from "react";
import CaseNumberDrat from "./Tabs/CaseNumberDrat";
import DiaryNumberDrat from "./Tabs/DiaryNumberDrat";
import PartyNameDrat from "./Tabs/PartyNameDrat";

function DratCaseStatusParent() {
  const [tabs, setTabs] = useState("Diary Number");

  return (
    <>
      <ul className="nav nav-pills">
        <li className="nav-item">
          <span
            className={tabs === "Diary Number" ? "nav-link active" : "nav-link"}
            onClick={() => setTabs("Diary Number")}
          >
            Diary Number
          </span>
        </li>
        <li className="nav-item">
          <span
            className={tabs === "Case Number" ? "nav-link active" : "nav-link"}
            onClick={() => setTabs("Case Number")}
          >
            Case Number
          </span>
        </li>
        <li className="nav-item">
          <span
            className={tabs === "Party Name" ? "nav-link active" : "nav-link"}
            onClick={() => setTabs("Party Name")}
          >
            Party Name
          </span>
        </li>
      </ul>

      {tabs === "Case Number" ? <CaseNumberDrat /> : tabs === "Diary Number" ? <DiaryNumberDrat /> : <PartyNameDrat/> }
    </>
  );
}

export default DratCaseStatusParent;
