import React, { useState, useEffect, useContext } from "react";
import FooterNav from "./FooterLinks";
import axiosBaseURL from "../../../httpCommon";
import axios from "axios";
import { DrtContext } from "../../../context/DrtContext";
import Loader from "../../Loader";
// import Chatboat from "../../../services/Chatboat";

const Footer = () => {
  const { EncryptDecrypt } = useContext(DrtContext);
  const currentYear = new Date().getFullYear();

  const [ip, setIP] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [visitors, setVisitors] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");
  const [getVisitorError, setGetVisitorError] = useState(false);
  const [addVisitorError, setAddVisitorError] = useState(false);
  const [lastUpdateError, setLastUpdateError] = useState(false);

  const getIP = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIP(res.data.IPv4);
    } catch (error) {
      console.log(error);
    }
  };

  const sendIP = async () => {
    try {
      const result = await axiosBaseURL
        .get(`addVisitor/${ip}`)
        .then((res) => res.data);
      const temp = EncryptDecrypt(result);

      if (temp.status === 200) {
        setStatus(temp.status);
      }
    } catch (error) {
      addVisitorError(true);
    }
  };

  const fetchVisitors = async () => {
    try {
      const result = await axiosBaseURL
        .get("/getVisitor")
        .then((res) => res.data);
      const temp = EncryptDecrypt(result);
      temp.data && setVisitors(temp.data);
    } catch (error) {
      getVisitorError(true);
    }
  };

  const lastUpdatedOn = async () => {
    try {
      const result = await axiosBaseURL
        .get("/getlastupdate")
        .then((res) => res.data);
      const temp = EncryptDecrypt(result);
      temp.data && setLastUpdate(temp.data);
    } catch (error) {
      lastUpdateError(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    getIP();
  }, []);

  useEffect(() => {
    if (ip) {
      sendIP();
    }
  }, [ip]);

  useEffect(() => {
    if (status) {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    fetchVisitors();
    lastUpdatedOn();
  }, []);

  // if (loading) {
  //   return <Loader />;
  // }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <>
      <footer>
        <FooterNav />
        <div className="footer-bottom-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 copyright-sec">
                <div className="footer-nav-sec">
                  <p>
                    Copyright © {currentYear} Debts Recovery Appellate Tribunals
                    (DRATs)
                  </p>
                </div>
                <p>All Rights Reserved</p>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 visitor-sec">
                <div className="mt-1 mb-2 text-right social-media">
                  <p>
                    Last updatedon:{" "}
                    <span>
                      {lastUpdate && !lastUpdateError
                        ? lastUpdate
                        : "No data found"}
                    </span>
                  </p>
                </div>
                <div className="text-right">
                  <p>
                    Visitors:{" "}
                    <span>
                      {visitors && !getVisitorError
                        ? visitors
                        : "No data found"}
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </footer>
      {/* <Chatboat />  */}
    </>
  );
};

export default Footer;
