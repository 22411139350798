import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { years } from "../../../../../../services/YearService";
import { useState, useEffect, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../../../../constants";
import Captcha from "react-numeric-captcha";

import axios from "axios";
import { axiosBaseURLExternal } from "../../../../../../httpCommon";

const RcTrcCaseStatus = () => {
  const [drtDratLocationName, setDrtDratLocationName] = useState("");
  const [inputObj, setInputObj] = useState({});
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();
  const [loading, setLoading] = useState(false);

  // state to control Model
  const [isOpenModal, setIsOpenModal] = useState(false);

  // fetching first dropDownData
  const [dratDrtLocations, setDratDrtLocations] = useState([]);
  const fetchDratDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratDrtLocations(sortedLocation);
  };

  // get final data after sending required payload
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);

  //setLoading(true);
  const fetchCaseDetail = async (url, data) => {
    try {
      const response = await axiosBaseURLExternal.post(url, data);
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const initialValues = {
    drtId: "",
    caseType: "",
    caseNo: "",
    caseYear: new Date().getFullYear(),
  };

  const validationSchema = Yup.object({
    drtId: Yup.string().required("Required"),
    caseType: Yup.string().required("Required"),
    caseNo: Yup.string()
      .trim()
      .max(8, "Maximum length is 8 characters")
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .required("Required"),
    caseYear: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDrtId: values.drtId,
      caseTypeId: values.caseType,
      caseNo: values.caseNo,
      caseYear: values.caseYear,
    };
    getDrtDratName(values.drtId);

    fetchCaseDetail("getRcTrcCaseStatusReport", caseDetailData);
    setInputObj(values);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    capt.current.refresh();
    // resetForm();
  };

  // const doubleDigitDate = (date) => {

  //   if (date) {
  //     let arr = date.split("/");
  //     if (arr[0].length === 1) arr[0] = "0" + arr[0];
  //     if (arr[1].length === 1) arr[1] = "0" + arr[1];

  //     return arr.join("/");
  //   }
  // };

  const doubleDigitDate = (date) => {
    if (date) {
      let arr = date.split("/");
      if (arr[0]?.length === 1) arr[0] = "0" + arr[0];
      if (arr[1]?.length === 1) arr[1] = "0" + arr[1];
      return arr.join("/");
    } else {
      return date;
    }
  };



  const getDrtDratName = (id) => {
    let name = dratDrtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtDratLocationName(name[0]?.SchemaName);
  };

  console.log(caseDetail);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  DRT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drtId"
                    name="drtId"
                    onChange={handleChange}
                    value={values.drtId}
                    onBlur={handleBlur}
                  >
                    <option>Select DRT</option>
                    {dratDrtLocations
                      .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                      .map((item, index) => (
                        <option value={`${item.schemeNameDrtId}`} key={index}>
                          {item.SchemaName}
                        </option>
                      ))}
                  </select>
                  {touched.drtId && errors.drtId ? (
                    <div className="error">{errors.drtId}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  RC/TRC
                </label>
                <div className="col-sm-7">
                  <select
                    id="caseType"
                    name="caseType"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    value={values.caseType}
                    onBlur={handleBlur}
                  >
                    <option>Select RC/TRC</option>
                    <option value={99999}>RC</option>
                    <option value={44444}>TRC</option>
                  </select>
                  {touched.caseType && errors.caseType ? (
                    <div className="error">{errors.caseType}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  RC/TRC No
                </label>
                <div className="col-sm-7">
                  <input
                    id="caseNo"
                    name="caseNo"
                    type="text"
                    className="form-control"
                    placeholder=" Enter RC/TRC No"
                    onChange={handleChange}
                    value={values.caseNo}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  {touched.caseNo && errors.caseNo ? (
                    <div className="error">{errors.caseNo}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  RC/TRC Year
                </label>
                <div className="col-sm-7">
                  <select
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="caseYear"
                    name="caseYear"
                    onChange={handleChange}
                    value={values.caseYear}
                    onBlur={handleBlur}
                  >
                    {years.map((item, index) => (
                      <option value={item.year} key={index}>
                        {item.year}
                      </option>
                    ))}
                  </select>
                  {touched.caseYear && errors.caseYear ? (
                    <div className="error">{errors.caseYear}</div>
                  ) : null}
                </div>
              </div>

              {/* field 3   */}
              <div className="form-group capcha-sec">
                <div className="col-sm-12 px-0 d-flex">
                  <Captcha
                    onChange={(status) => {
                      setCaptchaStatus(status);
                    }}
                    ref={capt}
                    required
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={!captchaStatus}
                    className="btn btn-reload"
                  >
                    <i className="fas fa-search"><span className="sr-only">no text</span></i>
                  </button>
                </div>
                {!captchaStatus ? (
                  <div className="captcha-text">Enter valid captcha</div>
                ) : null}
              </div>
            </form>
            {loading ? (
              <h6 className="loader">Loading ......</h6>
            ) : (
              <Modal
                show={isOpenModal}
                className="rctrc-modal"
                onHide={() => setIsOpenModal(false)}
                // className="modal-90w"
              >
                <Modal.Header>
                  RC/TRC Case Status Report <br /> {drtDratLocationName}
                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  {caseDetail?.status && !error ? (
                    <>
                      <div className="table-responsive mb-3">
                        <table className="table table-bordered text-center rcstatus-table">
                          <tbody>
                            <tr>
                              <td>
                                <strong>
                                  {caseDetail?.petitionerName} <br /> VS
                                  <br />
                                  {caseDetail?.respondentName}
                                </strong>
                              </td>
                              <td>
                                RC/TRC IS <strong>{caseDetail?.status}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="table-responsive mb-3">
                        <table className="table table-bordered rcstatus-table">
                          <thead>
                            <tr>
                              <td colSpan={"2"}>
                                RC/TRC Number:
                                <strong>
                                  {inputObj.caseType == "99999" ? "RC" : "TRC"}/
                                  {inputObj.caseNo}/{inputObj.caseYear}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={"2"}>
                                Case Number:
                                <strong>{caseDetail?.caseno} </strong>
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>

                      <div className="table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th colSpan={"2"}>RC/TRC CASE CURRENT STATUS</th>
                            </tr>
                            <tr></tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Court Name:</td>
                              <td>
                                <strong>{caseDetail?.courtname}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>Next Listing Date:</td>
                              <td>
                                <strong>
                                  {doubleDigitDate(caseDetail?.nextlistingdate)}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td>Next Listing Purpose:</td>
                              <td>
                                <strong>
                                  {caseDetail?.nextListingPurpose}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      {/* case proceeding details */}

                      <div className="table-responsive">
                        {caseDetail?.rcCaseProceedingDetails.length > 0 && (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th colSpan={"3"}>
                                  RC/TRC CASE PROCEEDING DETAILS
                                </th>
                              </tr>
                              <tr>
                                <th>Court No.</th>
                                <th>Causelist Date</th>
                                <th>Purpose</th>
                              </tr>
                            </thead>
                            <tbody>
                              {caseDetail?.rcCaseProceedingDetails?.map(
                                (item, index) => (
                                  <tr key={index}>
                                    <td>{item.courtNo}</td>
                                    <td>
                                      {doubleDigitDate(item.causelistdate)}
                                    </td>
                                    <td>{item.purpose}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        )}
                      </div>

                      {/* case details*/}

                      <div className="table-responsive mb-3">
                        {caseDetail.casedetail && (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th colSpan="2">CASE STATUS</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Diary No/Year</td>
                                <td>
                                  <strong>
                                    {caseDetail?.casedetail?.diaryno}/
                                    {caseDetail?.casedetail?.diaryyear}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                {/* <td>Case Type/Case No/Year</td>
                              <td>
                                <strong>
                                  {caseDetail[0]?.casedetail.casetype}/
                                  {caseDetail[0]?.casedetail.caseno}/
                                  {caseDetail[0]?.casedetail.caseyear}
                                </strong>
                              </td> */}
                              </tr>
                              <tr>
                                <td>Date of Filing</td>
                                <td>
                                  <strong>
                                    {doubleDigitDate(
                                      caseDetail?.casedetail?.dateoffiling
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>Case Status</td>
                                <td>
                                  <strong>
                                    {caseDetail?.casedetail?.casestatus}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td> Date of Disposal </td>
                                <td>
                                  <strong> {caseDetail?.casedetail?.dateofdisposal} </strong>
                                </td>
                              </tr>
                              <tr>
                                <td> Nature of Disposal </td>
                                <td>
                                  <strong> {caseDetail?.casedetail?.disposalNature} </strong>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </div>
                      {/* <div className="table-responsive mb-3">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th colSpan="2">
                                CASE LISTING DETAILS (Tentative)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>In the Court of</td>
                              <td>
                                <strong>
                                  {caseDetail[0]?.casedetail.courtName}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td>Court No.</td>
                              <td>
                                <strong>
                                  {caseDetail[0]?.casedetail.courtNo}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td>Next Listing Date</td>
                              <td>
                                <strong>
                                  {doubleDigitDate(
                                    caseDetail[0]?.casedetail.nextlistingdate
                                  )}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td>Next Listing Purpose</td>

                              <td>
                                <strong>
                                  {caseDetail[0]?.casedetail.nextListingPurpose}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div> */}
                      <div className="table-responsive mb-3">
                        {caseDetail?.casedetail && (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th colSpan="2">
                                  <strong>PETITIONER/APPLICANT DETAILS</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* <tr>
                              <td> Petitioner Name: </td>
                              <td>
                                <strong>
                                  {caseDetail[0]?.casedetail.petitionerName}
                                </strong>
                              </td>
                            </tr> */}
                              <tr>
                                <td>
                                  Petitioner/Applicant Address:{" "}
                                  <strong>
                                    {
                                      caseDetail?.casedetail
                                        ?.petitionerApplicantAddress
                                    }
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td> Additional Party: </td>
                                <td>
                                  <strong>
                                    {caseDetail?.casedetail?.additionalpartypet}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td> Advocate Name: </td>
                                <td>
                                  <strong>
                                    {caseDetail?.casedetail?.advocatePetName}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="table-responsive mb-3">
                        {caseDetail?.casedetail && (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th colSpan="2">
                                  <strong>RESPONDENTS/DEFENDANT DETAILS</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* <tr>
                              <td> Respondent Name: </td>
                              <td>
                                <strong>
                                  {caseDetail[0]?.casedetail.respondentName}
                                </strong>
                              </td>
                            </tr> */}
                              <tr>
                                <td>
                                  Respondent/Defendant Address:{" "}
                                  <strong>
                                    {
                                      caseDetail?.casedetail
                                        ?.respondentDefendentAddress
                                    }
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td> Additional Party: </td>
                                <td>
                                  <strong>
                                    {
                                      caseDetail[0]?.casedetail
                                        ?.additionalpartyres
                                    }
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td> Advocate Name: </td>
                                <td>
                                  <strong>
                                    {caseDetail?.casedetail?.advocateResName}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </div>

                      {/* // Case Proceeding details */}
                      {caseDetail.casedetail?.caseProceedingDetails.length >
                        0 && (
                        <div className="table-responsive mb-3">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th colSpan="4">CASE PROCEEDING DETAILS</th>
                              </tr>
                              <tr>
                                <th>Court Name</th>
                                <th>Causelist Date</th>
                                <th>Purpose</th>
                                <th>Order</th>
                              </tr>
                            </thead>
                            <tbody>
                              {caseDetail.casedetail?.caseProceedingDetails?.map(
                                (item, index) => (
                                  <tr key={index}>
                                    <td>{item.ascourtName}</td>
                                    <td>
                                      {doubleDigitDate(item.causelistdate)}
                                    </td>
                                    <td>{item.purpose}</td>
                                    <td>
                                      {" "}
                                      {item.orderUrl && (
                                        <a
                                          target="_blank"
                                          href={`${item.orderUrl}`}
                                        >
                                          <i className="fas fa-search"><span className="sr-only">no text</span></i>
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <tbody>
                        <tr>
                          <td colSpan="6"> No Record Found </td>
                        </tr>
                      </tbody>
                    </>
                  )}
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default RcTrcCaseStatus;
