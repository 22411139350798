import { useState } from "react";
import DrtRC from "./DrtRC";

const DrtRecoveryCertificateParent = () => {
  const [relatedLink, setRelatedLink] = useState("CaseNoFinalOrder");
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 tab-one p-0">
        <ul
          className="nav toptab-one mb-3 inner-tab mx-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={
                relatedLink === "CaseNoFinalOrder"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("CaseNoFinalOrder")}
            >
              RC/TRC No.
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <DrtRC />
          </div>
        </div>
      </div>
    </>
  );
};

export default DrtRecoveryCertificateParent;
