import { useState } from "react";
import CaseNoFinalOrder from "./tabs-final/CaseNoFinalOrder";
import DiaryNoFinalOrder from "./tabs-final/DiaryNoFinalOrder";


const DrtFinalOrderParent = () => {
  const [relatedLink, setRelatedLink] = useState("DiaryNoFinalOrder");
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 tab-one p-0">
        <ul
          className="nav toptab-one mb-3 inner-tab mx-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <span
              className={
                relatedLink === "DiaryNoFinalOrder"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab2"
              data-toggle="pill"
              
              role="tab"
              aria-controls="pills-cause"
              aria-selected="false"
              onClick={() => setRelatedLink("DiaryNoFinalOrder")}
            >
              Diary No.
            </span>
          </li>
          <li className="nav-item">
            <span
              className={
                relatedLink === "CaseNoFinalOrder"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
             
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("CaseNoFinalOrder")}
            >
              Case No.
            </span>
          </li>
        </ul>
        
        <div className="tab-content" id="pills-tabContent">
        {relatedLink === "CaseNoFinalOrder" ? (
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <CaseNoFinalOrder />
          </div>
        ) :  (
          <div
            className="tab-pane fade show active"
            id="pills-cause"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <DiaryNoFinalOrder />
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default DrtFinalOrderParent;
