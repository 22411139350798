import { useState, useEffect, useContext } from "react";
import axiosBaseURL from "../../httpCommon";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import { DrtContext } from "../../context/DrtContext";
import { useTitle } from "../../services/PageTitle";

const Vacancies = () => {
  useTitle("Vacancies")
  const { EncryptDecrypt } = useContext(DrtContext);
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(true);

  // fetching List of CPIO's
  const fetchVacancies = async () => {
    const result = await axiosBaseURL.get("/get-vacancies");
    const temp = EncryptDecrypt(result.data)
    setVacancies(temp.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchVacancies();
  }, []);

  if (loading) {
    return <h6 className="loader">Loading......</h6>;
  } else {
    return (
      <div>
        <InternalBaseBanner title={"vacancies "} />
        <BredCrumb title={"Vacancies "} />
        <div className="common-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                <div className="about-content">
                  <h6>Vacancies</h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="table-responsive">
                  <table className="table table-striped common-table bluetable">
                    <thead>
                      <tr>
                        <th>Vacancies Circular No.</th>
                        <th>Section</th>
                        <th>Date of Issue</th>
                        <th>Last Date of Application</th>
                        <th>Subject</th>
                        <th>Attachment</th>
                        <th>Organization</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vacancies.map((item, index) => (
                        <tr key={index}>
                          <td>{item.vacancy_circular}</td>
                          <td>{item.section}</td>
                          <td>{item.date_of_issue}</td>
                          <td>{item.last_date}</td>
                          <td
                            dangerouslySetInnerHTML={{ __html: item.subject }}
                          ></td>
                          <td className="text-center">
                            <a target="_blank" href={`${item.file_path}`}>
                              <i className="fas fa-search"><span className="sr-only">no text</span></i>
                            </a>
                          </td>
                          <td>{item.organisation}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Vacancies;
