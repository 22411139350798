import React from "react";
import Captcha from "react-numeric-captcha";
import { useState, useEffect, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../../../../constants";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { axiosBaseURLExternal } from "../../../../../../httpCommon";

const DailyFinalOrderJudgementNo = () => {
  const [formattedDate, setFormattedDate] = useState(""); //newDate
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();
  // state to control Model
  const [isOpenModal, setIsOpenModal] = useState(false);

  // fetching first dropDownData
  const [dratLocations, setDratLocations] = useState([]);

  const fetchDratLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratLocations(sortedLocation);
  };

  const [drtLocations, setDrtLocations] = useState([]);
  const fetchDrtLocations = async (data) => {
    try {
      const res = await axiosBaseURLExternal.post("getDrtSchemaName", data);
      setDrtLocations(res.data);
    } catch (error) {
      setDrtLocations(null);
    }
  };

  // get final data after sending required payload
  const [loading, setLoading] = useState(false);
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }

    setLoading(false);
  };

  // calling function for first dropDown data
  useEffect(() => {
    fetchDratLocation();
  }, []);

  const initialValues = {
    drt: "",
    drat: "",
    orderType: "",
    date: "",
  };

  // validation
  const validationSchema = Yup.object({
    drt: Yup.string().required("Required"),
    drat: Yup.string().required("Required"),
    orderType: Yup.string().trim().required("Required"),
    date: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    console.log(values);
    const caseDetailData = {
      schemeNameDratId: values.drat,
      schemeNameDrtId: values.drt,
      dratDailyFinalOrderId: values.orderType,
      fromDate: formattedDate,
    };

    fetchCaseDetail("getDratDrtDailyFinalOrderReport", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  const doubleDigitDate = (date) => {
    // let date = "25/8/2022"
    if (date) {
      let arr = date.split("/");
      if (arr[0].length === 1) arr[0] = "0" + arr[0];
      if (arr[1].length === 1) arr[1] = "0" + arr[1];

      return arr.join("/");
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          errors,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* field 1 */}

              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  DRAT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drat"
                    name="drat"
                    onChange={(e) => {
                      handleChange(e);
                      fetchDrtLocations({ schemeNameDratId: e.target.value });
                    }}
                    value={values.drat}
                    onBlur={handleBlur}
                  >
                    <option>Select DRAT</option>
                    {dratLocations
                      .filter((item) => parseInt(item.schemeNameDrtId) >= 100)
                      .map((item, index) => (
                        <option value={`${item.schemeNameDrtId}`} key={index}>
                          {item.SchemaName}
                        </option>
                      ))}
                  </select>
                  {touched.drat && errors.drat ? (
                    <div className="error">{errors.drat}</div>
                  ) : null}
                </div>
              </div>

              {/* field 2*/}
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  DRT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drt"
                    name="drt"
                    onChange={(e) => {
                      handleChange(e);
                      // fetchCaseType(e.target.value);
                    }}
                    value={values.drt}
                    onBlur={handleBlur}
                  >
                    <option>Select DRT</option>
                    {drtLocations &&
                      drtLocations.map((item, index) => (
                        <option value={`${item.schema_id}`} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  {touched.drt && errors.drt ? (
                    <div className="error">{errors.drt}</div>
                  ) : null}
                </div>
              </div>

              {/* field 3 */}
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  Daily/Final Order
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="orderType"
                    name="orderType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.orderType}
                  >
                    <option>Select Daily/Final Order</option>
                    <option value={1}>Daily Order</option>
                    <option value={2}>Final Order</option>
                  </select>
                  {touched.orderType && errors.orderType ? (
                    <div className="error">{errors.orderType}</div>
                  ) : null}
                </div>
              </div>

              {/* field 4*/}
              <div className="form-group row">
                <label
                  htmlFor="inputEmail4"
                  className="col-sm-4 col-form-label mandatory"
                >
                  Date
                </label>
                <div className="col-sm-7">
                  <input
                    id="date"
                    name="date"
                    type="date"
                    className="form-control"
                    onBlur={handleBlur}
                    value={values.date}
                    onChange={(e) => {
                      handleChange(e);
                      setFormattedDate(
                        e.target.value.split("-").reverse().join("/")
                      );
                    }}
                    autoComplete="off"
                  />
                  {touched.date && errors.date ? (
                    <div className="error">{errors.date}</div>
                  ) : null}
                </div>
              </div>

              {/* field 5*/}

              <div className="form-group capcha-sec">
                <div className="col-sm-12 px-0 d-flex">
                  <Captcha
                    onChange={(status) => {
                      setCaptchaStatus(status);
                    }}
                    ref={capt}
                    required
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={!captchaStatus}
                    className="btn btn-reload"
                  >
                    <i className="fas fa-search"><span className="sr-only">no text</span></i>
                  </button>
                </div>
                {!captchaStatus ? (
                  <div className="captcha-text">Enter valid captcha</div>
                ) : null}
              </div>
            </form>
            {loading ? (
              <h6 className="loader">Loading ......</h6>
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  DRT's Daily Order Report
                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>
                            <center>Applicant V/S Respondent</center>
                          </th>
                          <th>Dairy No.</th>
                          <th>Case No.</th>
                          <th>Date of Order</th>
                          <th>Pronounced By</th>
                          <th>View Daily Order(s)</th>
                        </tr>
                      </thead>
                      {!error && caseDetail.length > 0 ? (
                        <tbody>
                          {caseDetail.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <center>
                                  {item.applicantName} <br />
                                  <strong>V/S</strong>
                                  <br />
                                  {item.respondentName}
                                </center>
                              </td>
                              <td>{item.diaryno}</td>
                              <td>{item.applicantno}</td>
                              <td>{doubleDigitDate(item.dateoffiling)}</td>
                              <td>{item.pronouncedBy}</td>
                              <td>
                                <a
                                  target="_blank"
                                  href={`${item.dailyOrderPdf}`}
                                >
                                  <i className="fas fa-search"><span className="sr-only">no text</span></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="8"> No Record Found </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default DailyFinalOrderJudgementNo;
