import React from "react";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import { useTitle } from "../../services/PageTitle";

function OrganizationalStructure() {
  useTitle("Organizational Structure")
  return (
    <>
      <InternalBaseBanner title={"Organizational Structure "} />
      <BredCrumb title={"Organizational Structure "} />
      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="about-content">
                <h6>Organizational Structure</h6>

                <div className="org-img-sec">
                  <img src="images/org-chart.png" alt="org-chart" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrganizationalStructure;
