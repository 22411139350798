import React, { useEffect, useState, useContext } from "react";
import { DrtContext } from "../../context/DrtContext";
import axiosBaseURL from "../../httpCommon";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import { useTitle } from "../../services/PageTitle";

const HyperlinkPolicy = () => {
   useTitle("HyperLink Policy")
   const { EncryptDecrypt } = useContext(DrtContext);
   const [helpcontent, setHelpcontent] = useState(null);

   const getHelp = async () => {
      await axiosBaseURL.get('/site/hyperlinking-policy').then((result) => {
         const temp = EncryptDecrypt(result.data)
         setHelpcontent(temp.data)
      })

   }
   useEffect(() => {

      getHelp();

   }, []);

   if (!helpcontent) return (<><h6>Loading......</h6></>);
   //console.log(helpcontent)



   return (
      <div className="about">
         <InternalBaseBanner title={helpcontent.title}/>
         <BredCrumb title={helpcontent.title}/>
        
         <div className="common-bg">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                     <div className="about-content">
                        <h6>{helpcontent.title}</h6>
                        {/* <h2>DEBTS RECOVERY APPELLATE TRIBUNALS (DRATs)</h2>
                <div className="underline"></div> */}
                        <div dangerouslySetInnerHTML={{ __html: helpcontent.body }}></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


      </div>
   )
}
export default HyperlinkPolicy;