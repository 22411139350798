import { Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import Captcha from "react-numeric-captcha";
import * as Yup from "yup";
import { axiosBaseURLExternal } from "../../../../../../../httpCommon";

const AdvocateNameWise = () => {
  const [drtName, setDrtName] = useState("");
  const [inputObj, setInputObj] = useState({});
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [drtLocations, setDrtLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const [advType, setAdvType] = useState("");

  const fetchDratDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDrtLocations(sortedLocation);
  };

  const fetchCaseDetail = async (url, data) => {
    setLoading(true);
    try {
      const response = await axiosBaseURLExternal.post(url, data);
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  const initialValues = {
    drtDratId: "",
    advocateName: "",
    advType: "",
  };

  const validationSchema = Yup.object({
    drtDratId: Yup.string().required("Required"),
    advocateName: Yup.string().trim().required("Required"),
    advType: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      catschemaId: values.drtDratId,
      advName: values.advocateName.toUpperCase(),
      advType: values.advType,
    };
    getDrtDratName(values.drtDratId);
    fetchCaseDetail("casedetail_adv_name_wise_mob", caseDetailData);
    setInputObj(values);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    capt.current.refresh();
  };

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const getDrtDratName = (id) => {
    let name = drtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtName(name[0]?.SchemaName);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  DRT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drtDratId"
                    name="drtDratId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.drtDratId}
                  >
                    <option>Select DRT</option>
                    {drtLocations &&
                      drtLocations
                        .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                        .map((item, index) => (
                          <option value={`${item.schemeNameDrtId}`} key={index}>
                            {item.SchemaName}
                          </option>
                        ))}
                  </select>
                  {touched.drtDratId && errors.drtDratId ? (
                    <div className="error">{errors.drtDratId}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Advocate Name
                </label>
                <div className="col-sm-7">
                  <input
                    id="advocateName"
                    name="advocateName"
                    type="text"
                    className="form-control"
                    placeholder="Enter Advocate Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.advocateName}
                    autoComplete="off"
                  />
                  {touched.advocateName && errors.advocateName ? (
                    <div className="error">{errors.advocateName}</div>
                  ) : null}
                </div>
              </div>

              {/* AdvType input field */}
              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Advocate Type
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    id="advType"
                    name="advType"
                    onChange={(e) => {
                      setAdvType(e.target.value);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    value={values.advType}
                  >
                    <option value="">Select Adv Type</option>
                    <option value="1">Applicant</option>
                    <option value="2">Defendant</option>
                  </select>
                  {touched.advType && errors.advType ? (
                    <div className="error">{errors.advType}</div>
                  ) : null}
                </div>
              </div>

              {/* Captcha */}
              <div className="form-group capcha-sec">
                <div className="col-sm-12 px-0 d-flex">
                  <Captcha
                    onChange={(status) => {
                      setCaptchaStatus(status);
                    }}
                    ref={capt}
                    required
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={!captchaStatus}
                    className="btn btn-reload"
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
                {!captchaStatus ? (
                  <div className="captcha-text">Enter valid captcha</div>
                ) : null}
              </div>
            </form>
            {loading ? (
              <h6 className="loader">Loading ......</h6>
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  DRT/DRAT Case Status Report <br /> {drtName}
                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "350px" }}
                  >
                    <div className="table-responsive mb-3">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Diary No.</th>
                            <th>Case No.</th>
                            <th>Date of Filing</th>
                            <th>Applicant vs Defendant </th>
                            <th>
                              {" "}
                              {advType === "1"
                                ? `Applicant's Advocate`
                                : `Defendant's Advocate `}
                            </th>

                            <th>Stage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {caseDetail.length > 0 && !error ? (
                            caseDetail.map((item, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.diaryno}</td>
                                  <td>{item.caseno}</td>
                                  <td>{item.dateoffiling}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.applicant} <br /> vs <br />{" "}
                                    {item.respondent}
                                  </td>
                                  <td>
                                    {advType === "1"
                                      ? item.applicantadvocate
                                      : item.respondentadvocate}
                                  </td>
                                  <td>
                                    {item.stage === "D"
                                      ? "Disposed"
                                      : item.stage === "T"
                                      ? "Transfered"
                                      : "Pending"}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))
                          ) : (
                            <>
                              <tr>
                                <td colSpan="9"> No Record Found </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default AdvocateNameWise;
