import React, { useState } from "react";
import Acts from "./Acts";
import Rules from "./Rules";
import Circulars from "./Circulars";

function ActAndRuleParent() {
  const [tabs, setTabs] = useState("Acts");
  return (
    <>
      <div className="common-bg mb-4">
        <div className="container">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 act-rule-sec">
            <ul className="nav nav-pills mb-4">
              <li className="nav-item">
                <span
                  className={tabs === "Acts" ? "nav-link active" : "nav-link"}
                  onClick={() => setTabs("Acts")}
                >
                  Acts
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={tabs === "Rules" ? "nav-link active" : "nav-link"}
                  onClick={() => setTabs("Rules")}
                >
                  Rules & Regulations
                </span>
              </li>

              {/* <li className="nav-item">
                <span
                  className={
                    tabs === "Circulars" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setTabs("Circulars")}
                >
                  Circulars
                </span>
              </li> */}
            </ul>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="about-content p-0">
                {tabs === "Acts" ? (
                  <h6>Acts </h6>
                ) : tabs === "Rules" ? (
                  <h6>Rules & Regulations </h6>
                ) : (
                  <h6>Circulars </h6>
                )}
              </div>
            </div>

            <div className="tab-content " id="pills-tabContent">
              {tabs === "Acts" ? (
                <div
                  className="tab-pane fade show active"
                  id="pills-auction"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <Acts />
                </div>
              ) : tabs === "Rules" ? (
                <div
                  className="tab-pane fade show active"
                  id="pills-pn"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <Rules />
                </div>
              ) : (
                <div
                  className="tab-pane fade show active"
                  id="pills-tender"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <Circulars />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActAndRuleParent;
