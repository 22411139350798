import { Field, Formik, Form, ErrorMessage } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Auth from "../../../services/Auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  // login logic
  const user = {
    email: "",
    Password: "",
  };

  const AUTH_ID = "AUTH_ID";
  const [message, setMessage] = useState("");

  const onSubmit = (values) => {
    //alert();

    Auth.login(values).then((res) => {
      if (res.status == 200 && res.data) {
        const user = res.data;
        const access_token = user.access_token;
        localStorage.setItem(AUTH_ID, JSON.stringify(access_token));
        setMessage("Login Successful");
        navigate("/adminimexternal/*");
      } else {
        setMessage("Username or Password doesn't Exist");
      }
    });
  };

  //console.log(message);

  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-xl-12 mb-4">
        {message}
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-0">
            <Formik initialValues={user} onSubmit={onSubmit}>
              <Form>
                <div className="login-wrap">
                  <div className="login-heading">Debts Internal Login </div>
                  <div className="user mb-3">
                    <span className="mdi mdi-account-outline"></span>
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Username"
                    />
                  </div>
                  <div className="login-pass mb-4">
                    <span className="mdi mdi-lock-outline"></span>
                    <Field
                      id="password"
                      name="password"
                      type="text"
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                  {/* <div className="capcha">
                <div className="capcha-img">
                  <img src="images/capcha.png" alt="capcha" />
                </div>
                <div className="capcha-ref">
                  <img src="images/refrace.png" alt="capcha" />
                </div>
                <div className="capcha-input">
                  <input type="text" placeholder="Enter Capcha" />
                </div>
              </div>
              <div className="reset-pass">
                <input type="checkbox" />
                <a href="#">Reset Password</a>
              </div> */}
                  <div className="mt-3 mb-3">
                    <Button type="submit" className="full-w-blu-btn">
                      Sign In
                    </Button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
