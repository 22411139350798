import React, { useEffect, useState, useContext } from "react";
import { DrtContext } from "../../context/DrtContext";
import axiosBaseURL from "../../httpCommon";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import { useTitle } from "../../services/PageTitle";

function FAQs() {
  useTitle("FAQs")
  const { EncryptDecrypt } = useContext(DrtContext);
  const [faqsContent, setFaqsContent] = useState(null);

  const getHelp = async () => {
    await axiosBaseURL.get("/site/faqs").then((result) => {
      const temp = EncryptDecrypt(result.data)
      setFaqsContent(temp.data);
    });
  };

  useEffect(() => {
    getHelp();
  }, []);

  return (
    <>
      <div className="about">
        <InternalBaseBanner title={faqsContent?.title} />
        <BredCrumb title={faqsContent?.title} />
        <div className="common-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="about-content">
                  <h6>{faqsContent?.title}</h6>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: faqsContent?.body }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQs;
