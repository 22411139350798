import React, { useState } from "react";
import CaseNumber from "./tabs/CaseNumber";
import DiaryNumber from "./tabs/DiaryNumber";
import DrtDailyFinalOrder from "./tabs/DrtDailyFinalOrder";
import PartyJudgeName from "./tabs/PartyJudgeName";
//import RcOrder from "./tabs/extras/RcOrder";

const DratOrderParent = () => {
  const [tabs, setTabs] = useState("Diary Number");

  return (
    <>
     <ul className="nav nav-pills mb-4">
        <li className="nav-item">
          <span className={tabs === "Diary Number" ? "nav-link active" : "nav-link"}  onClick={() => setTabs('Diary Number')} >
            Diary No.
          </span>
        </li>
        <li className="nav-item">
          <span className={tabs === "Case Number" ? "nav-link active" : "nav-link"}  onClick={() => setTabs('Case Number')} >
            Case No.
          </span>
        </li>
        
        <li className="nav-item">
        <span className={tabs === "Party Judge Name" ? "nav-link active" : "nav-link"}  onClick={() => setTabs('Party Judge Name')} >
        Party / Judge Name
        </span>
        </li>
         <li className="nav-item">
          <span className={tabs === "DRTs Daily & Final Order" ? "nav-link active" : "nav-link"}  onClick={() => setTabs('DRTs Daily & Final Order')} >
            DRAT's Daily / Final Order
          </span>
        </li> 
      </ul>
      
      {tabs === "Case Number" ? (
        <CaseNumber />
      ) : tabs === "Diary Number" ? (
        <DiaryNumber />
      ) : tabs === "Party Judge Name" ? (
        <PartyJudgeName />
      ) :
        <DrtDailyFinalOrder />
      }
      
    </>
  );
};

export default DratOrderParent;
