import React from "react";
import { NavLink } from "react-router-dom";
//import { imageURL } from "../../constants";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import { useTitle } from "../../services/PageTitle";

const SiteMap = () => {
  useTitle("Site Map")
  return (
    <>
      <div className="about">
        <InternalBaseBanner title={"SITEMAP"} />
        <BredCrumb title={"Sitemap"} />
        <div className="container pt-4 pb-4">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="tender-heading-sec mb-3">
                <span className="tender-heading">Sitemap</span>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4">
              <ul className="navbar-nav mr-auto menu">
                <li className="nav-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/aboutus">About Us</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/casedetail">Case Details </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/causelist">Cause List </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/order">Order</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/listOfcpio">List of CPIOs </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/allnotice">Notices</NavLink>
                </li>
                
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-4">
              <ul className="navbar-nav mr-auto menu">
                <li className="nav-item">
                  <a href="https://drt.etribunals.gov.in/edrt/login.php" target={"_blank"}>
                    {" "}
                    e-Filing{" "}
                  </a>
                </li>
                <li>
                  <NavLink to="/gallery">Gallery</NavLink>
                </li>
                <li>
                  <NavLink to="/tender">Tender</NavLink>
                </li>
                <li>
                  <NavLink to="/vacancies">Vacancies</NavLink>
                </li>
                {/* <li>
                  <NavLink to="13">Press Release</NavLink>
                </li> */}
                <li>
                  <NavLink to="/actnrule">Acts & Rules</NavLink>
                </li>
                <li>
                  <NavLink to="/downloads">Downloads</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/feedback">Feedback</NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink to="/contactus"> Contact Us</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-4">
              <ul className="navbar-nav mr-auto menu">
                <li>
                  {" "}
                  <NavLink to="/termsCondition">
                    Terms & Conditions
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink to="/privacy">Privacy Policy</NavLink>
                </li>
                <li>
                  <NavLink to="/copyright">Copyright Policy</NavLink>
                </li>
                <li>
                  <NavLink to="/hyperpolicy">Hyperlinking Policy</NavLink>
                </li>
                <li>
                  <NavLink to="/disclaimer">Disclaimer</NavLink>
                </li>
                {/* <li><NavLink to="/accessability">Accessibility Statement</NavLink></li> */}
                <li>
                  {" "}
                  <NavLink to="/help">Help</NavLink>
                </li>
                <li>
                  <NavLink to="/web-information-manager">Web Information Manager</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteMap;
