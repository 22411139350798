import { useState, useEffect, useContext } from "react";
import Viewer from "react-viewer";
import { DrtContext } from "../../../context/DrtContext";
import axiosBaseURL from "../../../httpCommon";

const GalleryAll = () => {
  const { EncryptDecrypt } = useContext(DrtContext);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");

  // fetching data
  const [drtGetData, setDrtGetData] = useState([]);
  const fetchDrtData = async (url) => {
    try {
      setLoading(true);
      const response = await axiosBaseURL.get(url);
      const result = await response.data;
      const temp = EncryptDecrypt(result);
      setDrtGetData(temp.data);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const galleryImages = drtGetData.map((item) => {
    return {
      src: item.file_path,
      title: item.title,
    };
  });

  useEffect(() => {
    fetchDrtData("/get-gallery");
  }, []);

  if (loading) {
    return <h6 className="loader">Loading ...</h6>;
  }

  return (
    <div id="menu" className="tab-pane active">
      <section className="module module-spaced module-masonry masonry-layout-8 wow fadeInRight">
        <div className="grouping-section">
          {galleryImages.map((item, index) => (
            <a
              className="masonry-item"
              key={index}
              onClick={() => {
                setVisible(true);
                setActiveIndex(index);
              }}
            >
              <img
                src={item.src}
                width="550"
                height="314"
                alt="Art and Culture"
                loading="lazy"
                typeof="foaf:Image"
                className="img-responsive"
              />
              <div className="inner  details ">
                <i className="mdi mdi-magnify"></i>
              </div>
            </a>
          ))}
        </div>
      </section>
      {/* Light Box */}
      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        // zoomSpeed={0.2}
        images={galleryImages}
        activeIndex={activeIndex}
        onMaskClick={() => {
          setVisible(false);
        }}
        rotatable={false}
        zoomable={false}
        scalable={false}
        attribute={false}
        // noFooter={true}
        // noClose={true}
        // downloadable
      />
    </div>
  );
};

export default GalleryAll;
