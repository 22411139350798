import React, { useEffect, useState, useContext } from "react";
import axiosBaseURL from "../../httpCommon";
import InternalBaseBanner from "./InternalBaseBanner";
import BredCrumb from "./BredCrumb";
import { useParams } from 'react-router-dom';
import { DrtContext } from "../../context/DrtContext";
import archiveDate from "../../services/Date";

const RtiDetail = () => {
    const { EncryptDecrypt } = useContext(DrtContext);
    const [rtidetail, setRtidetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [showArchive, setShowArchive] = useState(false);

    let id = params.id;

    const rtiDetail = async () => {
      setLoading(true);
        
        const result = await axiosBaseURL.get(`/get-rti/${id}`);
        const temp = EncryptDecrypt(result.data);
        if (showArchive) {
          setRtidetail(temp.data);
        } else {
          const twelveMonthsAgo = archiveDate();
          const filteredEntries = temp.data.filter((item) => {
            const noticeDate = new Date(item.created_at);
            return noticeDate >= twelveMonthsAgo;
          });
      
          setRtidetail(filteredEntries);
        }
        
        setLoading(false);

    }

    useEffect(() => {
        rtiDetail();
    }, [showArchive]); 

    if (loading) {
        return <h6 className="loader">Loading......</h6>;
    }

    return (
        <>
            <InternalBaseBanner title={"RTI Detail "} />
            <BredCrumb title={"RTI Detail"} />
            <div className="container mt-4">
                <button className="btn btn-warning mb-3" onClick={() => setShowArchive(!showArchive)} >
                    {showArchive ? "Show last 12 months Data" : "Show Archive"}
                </button>

                <div className="table-responsive">
                    <table className="table table-striped common-table bluetable">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Title</th>
                                <th>Documents</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rtidetail.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.title}</td>
                                    <td><a target="_blank" href={item.aad_file}><i className="fas fa-file"></i><span className="sr-only">no text</span></a></td>
                                    <td>{item.from_date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default RtiDetail;
