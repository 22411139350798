import React, { useEffect, useState, useContext } from "react";
import { DrtContext } from "../../../context/DrtContext";
import axiosBaseURL from "../../../httpCommon";
import BredCrumb from "../BredCrumb";
import InternalBaseBanner from "../InternalBaseBanner";

import IncidentReportForm from "./IncidentReportForm";

const Help = () => {
  const { EncryptDecrypt } = useContext(DrtContext);
  const [helpContent, setHelpContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const getHelp = async () => {
    try {
      setLoading(true);
      await axiosBaseURL.get("/site/help").then((result) => {
        const temp = EncryptDecrypt(result.data);
        setHelpContent(temp.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHelp();
  }, []);

  if (loading)
    return (
      <>
        <h6 className="loader">Loading ...</h6>
      </>
    );

  return (
    <>
      <div className="about">
        <InternalBaseBanner title="Help" />
        <BredCrumb title="Help" />
        <div className="common-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="about-content">
                  <div className="help-title-sec">
                    <h6>Help</h6>
                    <div className="help-btns-sec">
                      <IncidentReportForm />
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: helpContent && helpContent.body,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Help;
