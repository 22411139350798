import CaseDEtailParent from "./CaseDetail/CaseDetailParent";
import CaseTransferParentHome from "./CaseTransfer/CaseTransferParentHome";
import CauseListHome from "./CauseList/CauseListHome";
import OrderHome from "./Order/OrderHome";
import { useState } from "react";

const DrtCaseCauseParent = () => {
  const [relatedLink, setRelatedLink] = useState("CaseDetail");
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-8 tab-one">
      <ul className="nav toptab-one mb-3" id="pills-tab" role="tablist">
        <li className="nav-item">
          <a
            className={
              relatedLink === "CaseDetail" ? "nav-link active" : "nav-link"
            }
            id="pills-home-tab"
            data-toggle="pill"
            href="#pills-home"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            onClick={() => setRelatedLink("CaseDetail")}
          >
            Case Details
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              relatedLink === "CauseList" ? "nav-link active" : "nav-link"
            }
            id="pills-cause-tab"
            data-toggle="pill"
            href="#pills-cause"
            role="tab"
            aria-controls="pills-cause"
            aria-selected="false"
            onClick={() => setRelatedLink("CauseList")}
          >
            Cause List
          </a>
        </li>
        <li className="nav-item">
          <a
            className={relatedLink === "Order" ? "nav-link active" : "nav-link"}
            id="pills-order-tab"
            data-toggle="pill"
            href="#pills-order"
            role="tab"
            aria-controls="pills-order"
            aria-selected="false"
            onClick={() => setRelatedLink("Order")}
          >
            Orders
          </a>
        </li>
        <li className="nav-item">
          <a
            className={relatedLink === "CaseTransfer" ? "nav-link active" : "nav-link"}
            id="pills-order-tab"
            data-toggle="pill"
            href="#pills-order"
            role="tab"
            aria-controls="pills-order"
            aria-selected="false"
            onClick={() => setRelatedLink("CaseTransfer")}
          >
            Case Transfer
          </a>
        </li>
       
      </ul>
      <div className="tab-content" id="pills-tabContent">
        {relatedLink === "CaseDetail" ? (
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            // aria-labelledby="pills-home-tab"
          >
            <CaseDEtailParent />
          </div>
        ) : relatedLink === "CauseList" ? (
          <div
            className="tab-pane fade show active"
            id="pills-cause"
            role="tabpanel"
            // aria-labelledby="pills-profile-tab"
          >
            <CauseListHome />
          </div>
        ) : relatedLink === "Order" ? (
          <div
            className="tab-pane fade show active"
            id="pills-order"
            role="tabpanel"
            // aria-labelledby="pills-profile-tab"
          >
            <OrderHome />
          </div>
        ) : relatedLink === "CaseTransfer" ? (
          <div
            className="tab-pane fade show active"
            id="pills-order"
            role="tabpanel"
            // aria-labelledby="pills-profile-tab"
          >
            <CaseTransferParentHome />
          </div>
        ): (
          null
        )}
      </div>
    </div>
  );
};

export default DrtCaseCauseParent;
