// export const apiStagingOutdoorUrl = `http://164.100.228.65/drtapi`;
export const apiStagingOutdoorUrl = process.env.REACT_APP_EXTERNAL_URL;

// export const imageURL = `http://localhost:3006/images/`;

//Staging server

// export const apiStagingURL = `http://91.203.135.173`;
export const apiStagingURL = process.env.REACT_APP_STAGING_URL;

// export const imageURL = `http://91.203.135.173/DRT/images`;
export const imageURL = process.env.REACT_APP_IMAGE_URL;

// export const apiStagingURL = `http://91.203.135.173`;
// export const imageURL = `http://91.203.135.173/DRT/images`;

//live server
//export const apiStagingURL = `http://10.194.75.135`;
//export const imageURL = `http://10.194.75.135/images`;

//live server 2
//export const apiStagingURL = `http://10.194.75.136`;
//export const imageURL = `http://10.194.75.136/images`;
//live server 3
// export const apiStagingURL = `http://164.100.211.141`;
// export const imageURL = `http://164.100.211.141/images`;
