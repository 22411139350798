import { Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import Captcha from "react-numeric-captcha";
import * as Yup from "yup";
import { axiosBaseURLExternal } from "../../../../../../../httpCommon";
import PartyNameMoreDetail from "./PartyNameMoreDetail";

const PartyNameWise = () => {
  const [drtName, setDrtName] = useState("");
  const [inputObj, setInputObj] = useState({});
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  const [isOpenModal, setIsOpenModal] = useState(false);
  // const [isOpenModal2, setIsOpenModal2] = useState(false);

  // state for first dropDownData
  const [drtLocations, setDrtLocations] = useState([]);

  // fetching first dropDownData
  const fetchDratDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDrtLocations(sortedLocation);
  };

  // fetching final data after post call
  const [loading, setLoading] = useState(false);
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    setLoading(true);
    try {
      const response = await axiosBaseURLExternal.post(url, data);
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  const initialValues = {
    drtDratId: "",
    partyName: "",
  };

  // validation
  const validationSchema = Yup.object({
    drtDratId: Yup.string().required("Required"),
    partyName: Yup.string()
      .trim()
      .max(8, "Maximum length is 8 characters")
      .required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDratDrtId: values.drtDratId,
      partyName: values.partyName.toUpperCase(),
    };
    getDrtDratName(values.drtDratId);
    fetchCaseDetail("casedetail_party_name_wise", caseDetailData);
    setInputObj(values);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    capt.current.refresh();
    // resetForm();
  };

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  const getDrtDratName = (id) => {
    let name = drtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtName(name[0]?.SchemaName);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          values,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-4 col-form-label mandatory"
                >
                  DRT
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    placeholder=""
                    type="text"
                    id="drtDratId"
                    name="drtDratId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.drtDratId}
                  >
                    <option>Select DRT</option>
                    {drtLocations &&
                      drtLocations
                        .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                        .map((item, index) => (
                          <option value={`${item.schemeNameDrtId}`} key={index}>
                            {item.SchemaName}
                          </option>
                        ))}
                  </select>
                  {touched.drtDratId && errors.drtDratId ? (
                    <div className="error">{errors.drtDratId}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label mandatory">
                  Party Name
                </label>
                <div className="col-sm-7">
                  <input
                    id="partyName"
                    name="partyName"
                    type="text"
                    className="form-control"
                    placeholder="Select Party Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.partyName}
                    autoComplete="off"
                  />
                  {touched.partyName && errors.partyName ? (
                    <div className="error">{errors.partyName}</div>
                  ) : null}
                </div>
              </div>

              {/* field 3   */}
              <div className="form-group capcha-sec">
                <div className="col-sm-12 px-0 d-flex">
                  <Captcha
                    onChange={(status) => {
                      setCaptchaStatus(status);
                    }}
                    ref={capt}
                    required
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={!captchaStatus}
                    className="btn btn-reload"
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
                {!captchaStatus ? (
                  <div className="captcha-text">Enter valid captcha</div>
                ) : null}
              </div>
            </form>
            {loading ? (
              <h6 className="loader">Loading ......</h6>
            ) : (
              <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
                <Modal.Header>
                  DRT/DRAT Case Status Report <br /> {drtName}
                  <Button
                    type="button"
                    className="close"
                    onClick={() => setIsOpenModal(false)}
                  >
                    &times;
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "350px" }}
                  >
                    <div className="table-responsive mb-3">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>
                              Applicant <br />
                              V/S <br /> Respondent
                            </th>
                            <th>Case No.</th>
                            <th>Case Type</th>
                            <th>Dairy No.</th>
                            <th>Date of Filing</th>
                            <th>Applicant's Advocate</th>
                            <th>Respondent's Advocate</th>
                            <th>More Detail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {caseDetail.length > 0 && !error ? (
                            caseDetail.map((item, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    {item.applicant} <br />
                                    V/S <br />
                                    {item.respondent}
                                  </td>
                                  <td>{item.caseno}</td>
                                  <td>{item.casetype}</td>
                                  <td>{item.diaryno}</td>
                                  <td>{item.dateoffiling}</td>
                                  <td>{item.applicantadvocate}</td>
                                  <td>{item.respondentadvocate}</td>
                                  <td>
                                    <PartyNameMoreDetail
                                      drtId={inputObj.drtDratId}
                                      item={item}
                                      drtName={drtName}
                                    />
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))
                          ) : (
                            <>
                              <tr>
                                <td colSpan="9"> No Record Found </td>
                              </tr>
                            </>
                          )}
                          {/* <Modal
                            show={isOpenModal2}
                            onHide={() => setIsOpenModal2(false)}
                          >
                            <Modal.Header>
                              More Details
                              <Button
                                type="button"
                                className="close"
                                onClick={() => setIsOpenModal2(false)}
                              >
                                &times;
                              </Button>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="table-responsive mb-3">
                                <Table striped bordered hover>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>
                                          <br />
                                          VS
                                          <br />
                                        </strong>
                                      </td>
                                      <td>
                                        <strong>RC IS </strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                              <div className="table-responsive mb-3">
                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <td colSpan="2">
                                        <strong>Case Number: </strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colSpan="2">
                                        <strong>
                                          RC/TRC CASE CURRENT STATUS
                                        </strong>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Court Name: <strong></strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Next Listing Date: <strong></strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Next Listing Purpose: <strong></strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                              <div className="table-responsive mb-3">
                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th colSpan="3">
                                        RC CASE PROCEEDING DETAILS
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>Court No.</th>
                                      <th>Causelist Date</th>
                                      <th>Purpose</th>
                                    </tr>
                                  </thead>
                                </Table>
                              </div>
                            </Modal.Body>
                          </Modal> */}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default PartyNameWise;
