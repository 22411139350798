import React, { useEffect, useState, useContext } from "react";
import { DrtContext } from "../../context/DrtContext";
import axiosBaseURL from "../../httpCommon";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import { useTitle } from "../../services/PageTitle";

const Copyright = () => {
  useTitle("Copyright")
  const [helpContent, setHelpContent] = useState(null);
  const { EncryptDecrypt } = useContext(DrtContext);

  const getHelp = async () => {
    await axiosBaseURL.get("/site/copyright-policy").then((result) => {
      const temp = EncryptDecrypt(result.data)
      setHelpContent(temp.data);
    });
  };
  useEffect(() => {
    getHelp();
  }, []);

  if (!helpContent)
    return (
      <>
        <h6>Loading......</h6>
      </>
    );
  //console.log(helpcontent)

  return (
    <div className="about">
      <InternalBaseBanner title={helpContent.title} />
      <BredCrumb title={helpContent.title} />

      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="about-content">
                <h6>{helpContent.title}</h6>
                {/* <h2>DEBTS RECOVERY APPELLATE TRIBUNALS (DRATs)</h2>
                <div className="underline"></div> */}
                <div
                  dangerouslySetInnerHTML={{ __html: helpContent.body }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Copyright;
