import axiosBaseURL from "../../../httpCommon";
import React, { useEffect, useState, useContext, useRef } from "react";
import Slider from "react-slick";
import { DrtContext } from "../../../context/DrtContext";
import { imageURL } from "../../../constants";

const Latestnews = () => {
  const slider = useRef();
  const { lang, EncryptDecrypt } = useContext(DrtContext);
  const [update, setUpdate] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [playPause, setPlayPause] = useState(false);

  const getLatestupdate = async () => {
    setIsLoading(true);
    try {
      const result = await axiosBaseURL.get("/get-latestupdate");

      const temp = EncryptDecrypt(result.data);

      setUpdate(temp.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError("An error occurred while fetching the data.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getLatestupdate();
  }, []);

  //slider settings
  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading) {
    return (
      <>
        <h6>Loading Latest Update...</h6>
      </>
    );
  }

  const handlePlayPause = () => {
    setPlayPause(!playPause);
    if (playPause) {
      slider.current.slickPlay();
    } else {
      slider.current.slickPause();
    }
  };

  return (
    <>
      <div className="news-slider">
        <div className="heading">
          <h3>{lang === "en" ? "Latest Update" : "ताजा अपडेट"}</h3>
        </div>
        <Slider {...settings} ref={slider}>
          {update.length > 0 ? (
            update.map((item, index) => (
              <div
                key={item.id}
                className={
                  index === 0 ? "carousel-item active" : "carousel-item"
                }
              >
                <a
                  type="button"
                  // href="https://drt.etribunals.gov.in/front/efiling_notice.pdf"
                  href={`${imageURL}/efiling_notice.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.summery}
                </a>
              </div>
            ))
          ) : (
            <>No Data Found</>
          )}
        </Slider>
        <a onClick={handlePlayPause} className="btn pause">
            {!playPause ? (
              <span>
                <i className="fas fa-pause-circle"></i>
                Pause
              </span>
            ) : (
              <span>
                <i className="fas fa-play"></i>
                Play
              </span>
            )}
          </a>
      </div>
    </>
  );
};

export default Latestnews;
