import { useState } from "react";
import CaseNoFinalOrder from "./tabs-final-home/CaseNoFinalOrder";
import DiaryNoFinalOrder from "./tabs-final-home/DiaryNoFinalOrder";

const DrtFinalOrderParentHome = () => {
  const [relatedLink, setRelatedLink] = useState("DiaryNoFinalOrder");
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 tab-one p-0">
        <ul
          className="nav toptab-one mb-3 inner-tab mx-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={
                relatedLink === "DiaryNoFinalOrder"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab2"
              data-toggle="pill"
              href="#pills-cause"
              role="tab"
              aria-controls="pills-cause"
              aria-selected="false"
              onClick={() => setRelatedLink("DiaryNoFinalOrder")}
            >
              Diary No.
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "CaseNoFinalOrder"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("CaseNoFinalOrder")}
            >
              Case No.
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {relatedLink === "CaseNoFinalOrder" ? (
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              // aria-labelledby="pills-home-tab"
            >
              <CaseNoFinalOrder />
            </div>
          ) : (
            <div
              className="tab-pane fade show active"
              id="pills-notice"
              role="tabpanel"
              // aria-labelledby="pills-contact-tab"
            >
              <DiaryNoFinalOrder />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DrtFinalOrderParentHome;
