import { createContext, useState } from "react";
import axios from "axios";
import axiosBaseURL from "../httpCommon";
import { apiStagingOutdoorUrl } from "../constants";
import { NavLink } from "react-router-dom";
import CryptoJS from "crypto-js";

export const DrtContext = createContext();

export const DrtProvider = ({ children }) => {
  // to store case detail in object form
  const [caseDetail, setCaseDetail] = useState("");
  // to store case detail in array form
  const [caseDetailArr, setCaseDetailArr] = useState([]);

  // Language Change
  const [lang, setLang] = useState("en");
  
  const handleChange = (e) => {
    setLang(e.target.value);
  };

  const handlelangChange = () => {
    setLang("hi");
  };
  const handlelangChangedefault = () => {
    setLang("en");
  };
  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  // Font Size Change
  const [fontSize, setFontSize] = useState(16);

  const handleSizeIncrease = () => {
    if(fontSize < 33)
    setFontSize(fontSize + 1);
  };
  const handleSizeDecrease = () => {
    if(fontSize > 11)
    setFontSize(fontSize - 1);
  };
  const handleSizeOriginal = () => {
    setFontSize(16);
  };
  const blackTheme = () => {
    document.body.classList.add("black-theme");
  };

  const normalTheme = () => {
    document.body.classList.remove("black-theme");
  };

  //----------------For Loading---------------------------//
  const [loading, setLoading] = useState(false);

  //-----------------All Get Calls-------------------------//
  const [drtGetData, setDrtGetData] = useState([]);
  const fetchDrtData = async (url) => {
    const response = await axiosBaseURL.get(url);
    const data = await response.data;
    setDrtGetData(data.data);
  };

  // fetching Case detail data all components--------------//
  // --------All Post Calls-------------------------//
  const fetchCaseDetail = async (url, data) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    // to store state in object form
    if (url === "casedetail_party_name_wise") {
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetailArr(response.data);
    } else {
      // to store state in array
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetail(response.data);
    }
    setLoading(false);
  };

  // ------------------- OLD Search Functionality search state---------------------------//
  // const [searchState, setSearchState] = useState("");
  // search result state
  // const [searchResult, setSearchResult] = useState([]);
  // fetching search results, post call
  // const fetchSearchResult = async (data) => {
  //   setLoading(true);
  //   const config = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   const dataobj = {
  //     filterkeyword: data,
  //   };

  //   const response = await axios.post(
  //     "http://216.48.181.168/application/public/index.php/api/v1/searchfilter",
  //     dataobj,
  //     config
  //   );
  //   console.log("response of the search result is", response.data);
  //   setSearchResult(response.data.data);

  //   setTimeout(() => {
  //     navigate("search");
  //   }, 1000);
  //   setLoading(false);
  // };

  // ----------------New Search-----------------------------------//
  const link = [
    <NavLink to="/">Home</NavLink>,
    <NavLink to="/aboutus">About Us</NavLink>,
    <NavLink to="/casedetail">Case Details </NavLink>,
    <NavLink to="/causelist">Cause List </NavLink>,
    <NavLink to="/order">Order</NavLink>,
    <NavLink to="/listOfcpio">List of CPIOs </NavLink>,
    <NavLink to="/allnotice">Notices</NavLink>,
    <NavLink to="/contactus"> Contact Us</NavLink>,
    <NavLink to="6"> e-Filing</NavLink>,
    <NavLink to="/gallery">Gallery</NavLink>,
    <NavLink to="/tender">Tender</NavLink>,
    <NavLink to="/vacancies">Vacancies</NavLink>,
    <NavLink to="13">Press Release</NavLink>,
    <NavLink to="/actnrule">Acts & Rules</NavLink>,
    <NavLink to="/downloads">Downloads</NavLink>,
    <NavLink to="/usefullink">Useful Links</NavLink>,
    <NavLink to="/rti">RTI</NavLink>,
    <NavLink to="/termsCondition">Terms & Conditions</NavLink>,
    <NavLink to="/privacy">Privacy Policy</NavLink>,
    <NavLink to="/copyright">Copyright Policy</NavLink>,
    <NavLink to="/hyperpolicy">Hyperlinking Policy</NavLink>,
    <NavLink to="/disclaimer">Disclaimer</NavLink>,
    // <NavLink to="/accessability">Accessibility Statement</NavLink>,
    <NavLink to="/help">Help</NavLink>,
    <NavLink to="/allnotice">Auction Notice</NavLink>,
    <NavLink to="/allnotice">Publication Notice</NavLink>,
    <NavLink to="/allnotice">Tender Notice</NavLink>,
    <NavLink to="/allnotice">Public Notice</NavLink>,
    <NavLink to="/sitemap">Site Map</NavLink>,
    <NavLink to="/organizationalstructure">Organizational Structure</NavLink>,
    <NavLink to="/faqs">FAQ's</NavLink>,
    <NavLink to="/whatsnewdetail">Whats New</NavLink>,
  ];

  const data = [
    {
      link: link[0],
      name: "home",
    },
    {
      link: link[1],
      name: "About Us",
    },
    {
      link: link[2],
      name: "case details",
    },
    {
      link: link[3],
      name: "Cause List",
    },
    {
      link: link[4],
      name: "Order",
    },
    {
      link: link[5],
      name: "List of CPIOs",
    },
    {
      link: link[6],
      name: "Notices",
    },
    {
      link: link[7],
      name: "Contact Us",
    },
    // {
    //   link: link[8],
    //   name: "e-Filing",
    // },
    {
      link: link[9],
      name: "gallery",
    },
    {
      link: link[10],
      name: "Tender",
    },
    {
      link: link[11],
      name: "Vacancies",
    },
    {
      link: link[12],
      name: "Press Release",
    },
    {
      link: link[13],
      name: "Acts & Rules",
    },
    {
      link: link[14],
      name: "Downloads",
    },
    {
      link: link[15],
      name: "Useful Links",
    },
    {
      link: link[16],
      name: "RTI",
    },
    {
      link: link[17],
      name: "Terms & Conditions",
    },
    {
      link: link[18],
      name: "Privacy Policy",
    },
    {
      link: link[19],
      name: "Copyright Policy",
    },
    {
      link: link[20],
      name: "Hyperlinking Policy",
    },
    {
      link: link[21],
      name: "Disclaimer",
    },
    {
      link: link[22],
      name: "Help",
    },
    {
      link: link[23],
      name: "Auction Notice",
    },
    {
      link: link[24],
      name: "Publication Notice",
    },
    {
      link: link[25],
      name: "Tender Notice",
    },
    {
      link: link[26],
      name: "Public Notice",
    },
    {
      link: link[27],
      name: "Site Map",
    },
    {
      link: link[28],
      name: "Organizational Structure",
    },
    {
      link: link[29],
      name: "FAQ's",
    },
    {
      link: link[30],
      name: "Whats New",
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const getSearchTerm = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm !== "") {
      const newSearch = data.filter((item) => {
        return Object.values(item)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(newSearch);
    }
  };
  const handleClickSearch = () => {
    setSearchTerm("");
  };

  // for drycption
  function EncryptDecrypt(encryptedString) {
    const temp = CryptoJS.AES.decrypt(encryptedString, "ostrich").toString(
      CryptoJS.enc.Utf8
    );
    // console.log(" text is", temp);
    // console.log(" json of text is", JSON.parse(temp));
    const packet = JSON.parse(temp);
    // const data = packet.data[0].summery;
    return packet;
  }

  // ExternalLinkPopup start
  const [externalLink, setExternalLink] = useState('');
  const [source, setSource] = useState('');
  const [showExternalLinkPopup, setExternalLinkPopupShow] = useState(false);
  // ExternalLinkPopup end

  return (
    <DrtContext.Provider
      value={{
        fontSize,
        lang,
        caseDetail,
        caseDetailArr,
        drtGetData,
        loading,
        // searchResult,
        // searchState,
        // setSearchState,
        searchTerm,
        searchResults,
        handleSizeDecrease,
        handleSizeIncrease,
        handleSizeOriginal,
        normalTheme,
        blackTheme,
        handleChange,
        handlelangChange,
        handlelangChangedefault,
        fetchCaseDetail,
        fetchDrtData,
        // fetchSearchResult,
        getSearchTerm,
        handleClickSearch,
        EncryptDecrypt,
        externalLink,
        setExternalLink,
        source,
        setSource,
        showExternalLinkPopup,
        setExternalLinkPopupShow,
      }}
    >
      {children}
    </DrtContext.Provider>
  );
};
