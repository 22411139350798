import React from "react";
import Captcha from "react-numeric-captcha";
import { useState, useEffect, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { apiStagingOutdoorUrl } from "../../../../../constants";
import axios from "axios";
import { useFormik } from "formik";
import AdvocateNameMoreDetail from "./AdvocateNameMoreDetail";

const AdvocateNameDrt = () => {
  const [drtName, setDrtName] = useState("");
  const [inputObj, setInputObj] = useState({});
  const [loading, setLoading] = useState(false);

  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [drtLocations, setDrtLocations] = useState([]);

  // fetching first dropDownData
  const fetchDratDrtLocation = async () => {
    const res = await axios.post(
      `${apiStagingOutdoorUrl}/getDrtDratScheamName`
    );
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDrtLocations(sortedLocation);
  };

  useEffect(() => {
    fetchDratDrtLocation();
  }, []);

  // get final data after sending required payload

  const [caseDetailArr, setCaseDetailArr] = useState([]);
  const [error, setError] = useState(false);

  const fetchCaseDetail = async (url, data) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      setCaseDetailArr(response.data);
    } catch (error) {
      setCaseDetailArr([]);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  const initialValues = {
    schemeNameDratDrtId: "",
    advocateName: "",
    advType: "",
  };

  const validate = (values) => {
    let errors = {};

    if (!values.schemeNameDratDrtId) {
      errors.schemeNameDratDrtId = "Required";
    }
    if (!values.advocateName.trim()) {
      errors.advocateName = "Required";
    }
    if (!values.advType) {
      errors.advType = "Required";
    }

    return errors;
  };

  const onSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      catschemaId: values.schemeNameDratDrtId,
      advName: values.advocateName.toUpperCase(),
      advType: values.advType,
    };
    getDrtDratName(values.schemeNameDratDrtId);
    fetchCaseDetail("casedetail_adv_name_wise_mob", caseDetailData);
    setInputObj(values);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const getDrtDratName = (id) => {
    let name = drtLocations.filter((item) => item.schemeNameDrtId === id);
    setDrtName(name[0]?.SchemaName);
  };

  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 pt-4">
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label className="mandatory" htmlFor="staticEmail">
              {" "}
              DRT
            </label>

            <select
              className="form-control"
              placeholder=""
              type="text"
              id="schemeNameDratDrtId"
              name="schemeNameDratDrtId"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.schemeNameDratDrtId}
              onBlur={formik.handleBlur}
            >
              <option> Select DRT Location</option>
              {drtLocations &&
                drtLocations
                  .filter((item) => parseInt(item.schemeNameDrtId) < 100)
                  .map((item, index) => (
                    <option value={`${item.schemeNameDrtId}`} key={index}>
                      {item.SchemaName}
                    </option>
                  ))}
            </select>
            {formik.touched.schemeNameDratDrtId &&
            formik.errors.schemeNameDratDrtId ? (
              <div className="error">{formik.errors.schemeNameDratDrtId}</div>
            ) : null}
          </div>

          <div className="form-group col-md-6">
            <label className="mandatory" htmlFor="">
              {" "}
              Advocate Name
            </label>

            <input
              id="advocateName"
              name="advocateName"
              type="text"
              className="form-control"
              placeholder="Enter Advocate Name"
              onChange={formik.handleChange}
              value={formik.values.advocateName}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.advocateName && formik.errors.advocateName ? (
              <div className="error">{formik.errors.advocateName}</div>
            ) : null}
          </div>

          <div className="form-group col-md-6">
            <label className="mandatory" htmlFor="">
              {" "}
              Advocate Type
            </label>

            <select
              id="advType"
              name="advType"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.advType}
              onBlur={formik.handleBlur}
            >
              <option value="">Select Advocate Type</option>
              <option value="1">Applicant</option>
              <option value="2">Defendant</option>
            </select>
            {formik.touched.advType && formik.errors.advType ? (
              <div className="error">{formik.errors.advType}</div>
            ) : null}
          </div>

          <div className="form-group col-md-8 main-menu-captcha capcha-sec">
            <label>Captcha</label>
            <div className="col-sm-12 px-0 d-flex">
              <Captcha
                onChange={(status) => {
                  setCaptchaStatus(status);
                }}
                ref={capt}
                required
              />
              <br />
              <button
                type="submit"
                disabled={!captchaStatus}
                className="btn btn-reload"
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
            {!captchaStatus ? (
              <div className="captcha-text">Enter valid captcha</div>
            ) : null}
          </div>
        </div>
      </form>
      {loading ? (
        <h6 className="loader">Loading ......</h6>
      ) : (
        <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
          <Modal.Header>
            DRT/DRAT Advocate wise Report <br /> {drtName}
            <Button
              type="button"
              className="close"
              onClick={() => setIsOpenModal(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive" style={{ maxHeight: "350px" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Diary No.</th>
                    <th>Case No.</th>
                    <th>Date of Filing</th>
                    <th>Applicant vs Defendant </th>
                    {/* <th>Case Type</th> */}
                    <th>
                      {" "}
                      {formik.values.advType === "1"
                        ? `Applicant's Advocate`
                        : `Defendant's Advocate `}
                    </th>
                    <th>Stage</th>
                  </tr>
                </thead>
                {caseDetailArr && caseDetailArr.length > 0 ? (
                  <tbody>
                    {caseDetailArr.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.diaryno}</td>
                        <td>{item.caseno}</td>
                        <td>{item.dateoffiling}</td>
                        <td style={{ textAlign: "center" }}>
                          {item.applicant} <br /> vs <br /> {item.respondent}
                        </td>
                        {/* <td>{item.casetype}</td> */}
                        <td>
                          {formik.values.advType === "1"
                            ? item.applicantadvocate
                            : item.respondentadvocate}
                        </td>
                        <td>
                          {item.stage === "D"
                            ? "Disposed"
                            : item.stage === "T"
                            ? "Transfered"
                            : "Pending"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <>
                    <tbody>
                      <tr>
                        <td colSpan={"9"}> No Record Found </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default AdvocateNameDrt;
