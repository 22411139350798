import { useState, useEffect, useContext } from "react";
import axiosBaseURL from "../../httpCommon";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import { DrtContext } from "../../context/DrtContext";
import { useTitle } from "../../services/PageTitle";
import archiveDate from "../../services/Date";

const Downloads = () => {
  useTitle("Downloads");
  const { EncryptDecrypt } = useContext(DrtContext);
  const [listDownloads, setListDownloads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showArchive, setShowArchive] = useState(false)

  // fetching List of Downloads
  const fetchDownloads = async () => {
    setLoading(true);
    const result = await axiosBaseURL.get("/get-Downloads");
    const temp = EncryptDecrypt(result.data);
    
    if (showArchive) {
      setListDownloads(temp.data);
    } else {
      const twelveMonthsAgo = archiveDate();
      const filteredEntries = temp.data.filter((item) => {
        const noticeDate = new Date(item.created_at);
        return noticeDate >= twelveMonthsAgo;
      });
  
      setListDownloads(filteredEntries);
    }
    
    setLoading(false);
  };
  
  useEffect(() => {
    fetchDownloads();
  }, [showArchive]);   
  

  if (loading) {
    return <h6 className="loader">Loading......</h6>;
  }
  return (
    <>
      <InternalBaseBanner title={"List of Downloads "} />
      <BredCrumb title={"List of Downloads "} />
      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div className="about-content">
                <h6>List of Downloads</h6>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <button className="btn btn-warning mb-3" onClick={() => setShowArchive(!showArchive)} > { showArchive ? "Show last 12 months Data" : "Show Archive" }</button>

              <div className="table-responsive">
                <table className="table table-striped common-table bluetable">
                  <thead>
                    <tr>
                      <th>Sl. No.</th>
                      <th>Title</th>
                      <th>File</th>
                      {/* <th>Designation</th>
                <th>Contact No.</th>
                <th>Address</th>
                <th>Name of 1st Appellate</th>
                <th>Designation</th>
                <th>Address</th>
                <th>Contact No.</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {listDownloads.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        {/* <td>{item.upload_image_path}</td> */}
                        <td>
                        <a target="_blank" href={`${item.file_path}`}>
                          <i className="fas fa-file"><span className="sr-only">no text</span></i>
                        </a>
                      </td>
                        {/* <td>{item.cpio_designation}</td>
                  <td>{item.cpio_contact}</td>
                  <td>{item.cpio_address}</td>
                  <td>{item.first_appellate_name}</td>
                  <td>{item.appellate_designation}</td>
                  <td>{item.appellate_address}</td>
                  <td>{item.appellate_contact}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Downloads;
