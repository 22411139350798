import React, { useEffect, useState, useContext } from "react";
import axiosBaseURL from "../../../httpCommon";
import { NavLink } from "react-router-dom";
import { imageURL } from "../../../constants";
import { DrtContext } from "../../../context/DrtContext";
import Loader from "../../Loader";

const Welcome = () => {
  const { EncryptDecrypt } = useContext(DrtContext);
  const [about, setAbout] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAbout = async () => {
    try {
      setLoading(true);
      const result = await axiosBaseURL.get("/site/about");
      const temp = EncryptDecrypt(result.data);
      setAbout(temp.data);
      setLoading(false);
    } catch (error) {
      setError("An error occurred while fetching the data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAbout();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!about) {
    return null; // Return any appropriate fallback or loading state
  }

  const toShow = about.body.substring(0, 450) + "...";

  return (
    <>
      <div className="about-bg">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-7">
            <div className="about-content">
              <h6>Welcome to</h6>
              <div
                className="about-wrap"
                dangerouslySetInnerHTML={{ __html: toShow }}
              ></div>
              <NavLink to="/aboutus" className="btn readmore">
                Read More
              </NavLink>{" "}
              <span>
                <i className="fas fa-long-arrow-alt-right"></i>
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-5">
            <div className="video-bg">
              <img
                src={`${imageURL}/playvideo.jpg`}
                className="img-fluid"
                alt="welcome"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
