import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DrtContext } from "../../../../context/DrtContext";
import axiosBaseURL from "../../../../httpCommon";
import BredCrumb from "../../BredCrumb";
import InternalBaseBanner from "../../InternalBaseBanner";

const WhatsNewSingle = () => {
  const { EncryptDecrypt } = useContext(DrtContext);
  const [whatsNew, setWhatsNew] = useState();
  const [loading, setLoading] = useState(false);
  const prams = useParams();
  const navigate = useNavigate();
  let id = prams.id;

  const fetchWhatsNewSingle = async () => {
    try {
      setLoading(true);
      const response = await axiosBaseURL.get(`/get-recent-news/${id}`);
      const temp = EncryptDecrypt(response.data);
      setWhatsNew(temp.data);
      setLoading(false);
    } catch (error) {
      setWhatsNew(null);
    }
  };

  useEffect(() => {
    fetchWhatsNewSingle();
  }, []);

  if (loading)
    return (
      <>
        <h6 className="loader">Loading Latest Update......</h6>
      </>
    );

  return (
    <>
      <div className="about">
        <InternalBaseBanner title={"What's New"} />
        <BredCrumb title={"What's New"} />
        <div className="whatsnewsingle">
          <div className="container">
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="about-content px-0">
                  <div className="mt-1 mb-1 d-flex justify-content-end ">
                    <button
                      onClick={() => navigate(-1)}
                      className="btn-sm btn-primary"
                    >
                      Back
                    </button>
                  </div>
                  <h2>Whats New</h2>
                  {whatsNew &&
                    whatsNew.map((item, index) => (
                      <div key={index} className="row mt-4">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                          <div className="whatsnew-img">
                            <img src={`${item.upload_image_path}`} alt="Whats New" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                          <div>
                            <h2>{item.title}</h2>
                            <div className="mb-3">
                              <strong className="mr-2">Created At:</strong>
                              <span>{item.created_at}</span>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WhatsNewSingle;
