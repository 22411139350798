import React, { useState, useEffect } from "react";
// import Rc_TrcCaseStatusReport from "./Rc_TrcCaseStatusReport/Rc_TrcCaseStatusReportParent";
import { Link } from "react-router-dom";
// import DrtCaseStatusParent from "./DrtCaseStatusReport/DrtCaseStatusParent";
// import DratCaseStatusParent from "./DratCaseStatusReport/DratCaseStatusParent";
import { useTitle } from "../../../services/PageTitle";
import CaseTransferDrt from "./CaseTransferDrt";

const CaseDetailParent = () => {
 
  useTitle("Case Transfer");

  const [relatedLink, setRelatedLink] = useState("CaseTransfer");

  return (
    <div>
      <div className="inner-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="b-title">
                <h5>CASE TRANSFER</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Case Transfer
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {relatedLink === "CaseTransfer"
                      ? "Case Transfer"
                      : null}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="common-bg mb-4">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 pt-4 inner-side-nav">
              {relatedLink === "CaseTransfer" ? (
                <CaseTransferDrt />
              ) : null}
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-static">
              <div className="bg-shade"></div>
              <div className="right-menu">
                <h3>Related Links</h3>
                <ul>
                 
                  <li>
                    <span
                      className={
                        relatedLink === "CaseTransfer" ? "active" : ""
                      }
                      onClick={() => setRelatedLink("CaseTransfer")}
                    >
                      DRT Case Status
                    </span>
                  </li>
                  <li>
                    <span
                      
                    >
                      
                    </span>
                  </li>
                  <li>
                    <span
                     
                    >
                      
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseDetailParent;
