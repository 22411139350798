import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AuctionNotice from "./home/AuctionNotice";
import PublicationNotice from "./home/PublicationNoice";
import TenderNotice from "./home/TenderNotice";
import ActAndRuleParent from "../Acts/ActAndRuleParent";
import PublicNotice from "./home/PublicNotice";
import { useTitle } from "../../services/PageTitle";

// import BredCrumb from "./BredCrumb";
// import InternalBaseBanner from "./InternalBaseBanner";

const AllNotices = () => {
  useTitle("Notices")
  const [relatedLink, setRelatedLink] = useState("PublicNotice");
  return (
    <>
      <div className="about">
        <div className="inner-bg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="b-title">
                  <h5>Notices</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Notices
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* <InternalBaseBanner title={about.title} />
                <BredCrumb title={about.title} /> */}
        <div className="tender-bg allnotice-sec">
          <div className="container">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <ul
                className="nav d-flex justify-content-center"
                id="pills-tab-btm"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    onClick={() => setRelatedLink("AuctionNotice")}
                    className={
                      relatedLink === "AuctionNotice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id=""
                    data-toggle="pill"
                    href="#pills-auction"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Auction Notice
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setRelatedLink("PublicationNotice")}
                    className={
                      relatedLink === "PublicationNotice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id=""
                    data-toggle="pill"
                    href="#pills-pn"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Publication Notice
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setRelatedLink("TenderNotice")}
                    className={
                      relatedLink === "TenderNotice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id=""
                    data-toggle="pill"
                    href="#pills-tender"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Tender Notice
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setRelatedLink("PublicNotice")}
                    className={
                      relatedLink === "PublicNotice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id=""
                    data-toggle="pill"
                    href="#pills-filling"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Public Notice
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    onClick={() => setRelatedLink("Acts&Rules")}
                    className={
                      relatedLink === "Acts&Rules"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id=""
                    data-toggle="pill"
                    href="#pills-filling"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Acts & Rules
                  </a>
                </li> */}
              </ul>

              <div className="tab-content notices-sec" id="pills-tabContent">
                {relatedLink === "AuctionNotice" ? (
                  <div
                    className="tab-pane fade show active"
                    id="pills-auction"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <AuctionNotice />
                  </div>
                ) : relatedLink === "PublicationNotice" ? (
                  <div
                    className="tab-pane fade show active"
                    id="pills-pn"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <PublicationNotice />
                  </div>
                ) : relatedLink === "TenderNotice" ? (
                  <div
                    className="tab-pane fade show active"
                    id="pills-tender"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <TenderNotice />
                  </div>
                ) : relatedLink === "PublicNotice" ? (
                  <div
                    className="tab-pane fade show active"
                    id="pills-filling"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <PublicNotice />
                  </div>
                ) : (
                  <div
                    className="tab-pane fade show active"
                    id="pills-filling"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <ActAndRuleParent />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllNotices;
