import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { DrtContext } from "../../../../context/DrtContext";
import axiosBaseURL from "../../../../httpCommon";
import BredCrumb from "../../BredCrumb";
import InternalBaseBanner from "../../InternalBaseBanner";

const WhatsNewDetail = () => {
  const { EncryptDecrypt } = useContext(DrtContext);
  const [whatsNewDetailAll, setWhatsNewDetailAll] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchWhatsNewAll = async () => {
    try {
      setLoading(true);
      const response = await axiosBaseURL.get(`/get-recent-news`);
      const temp = EncryptDecrypt(response.data);
      setWhatsNewDetailAll(temp.data);
      setLoading(false);
    } catch (error) {
      setWhatsNewDetailAll(null);
    }
  };

  useEffect(() => {
    fetchWhatsNewAll();
  }, []);

  if (loading)
    return (
      <>
        <h6 className="loader">Loading Latest Update......</h6>
      </>
    );

  return (
    <>
      <div className="about">
        <InternalBaseBanner title={"What's New"} />
        <BredCrumb title={"What's New"} />

        <div className="common-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="about-content">
                  <h2 className="mb-3">Whats New</h2>
                  {/* <div dangerouslySetInnerHTML={{ __html: about.body }}></div> */}

                  <div className="table-responsive">
                    <table className="table table-bordered whatsnew-tble">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Event&nbsp;Start&nbsp;Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {whatsNewDetailAll &&
                          whatsNewDetailAll.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.title}</td>
                              <td
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.description.substring(0, 100) + "...",
                                }}
                              ></td>
                              <td>{item.events_from_date}</td>
                              <td>
                                <NavLink
                                  to={`/whats-new-detail-Single/${item.slug}`}
                                >
                                  Read&nbsp;More
                                </NavLink>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WhatsNewDetail;
