import React from 'react'
import InternalBaseBanner from './InternalBaseBanner';
import BredCrumb from './BredCrumb';
import { useTitle } from '../../services/PageTitle';

const Screenreader = () => {
  useTitle("Screen Reader")
  return (
    <>
     <InternalBaseBanner title={"Screen Reader"} />
        <BredCrumb title={"Screen Reader"} />
        <div className="container mt-4">
        <div className="table-responsive">
          <table className="table table-striped common-table bluetable">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Screen Reader</th>
                <th>Website</th>
                <th>Free/ Commercial</th>
                
              </tr>
            </thead>
            <tbody>
               
             <tr >
                <td>1</td>
                <td>Screen Access For All (SAFA)</td>
                <td><a target="_blank" href="http://www.nabdelhi.org/NAB_SAFA.htm">http://www.nabdelhi.org/NAB_SAFA.htm</a></td> 
                <td>Free</td>
             </tr>
             <tr >
                <td>2</td>
                <td>Non Visual Desktop Access (NVDA)</td>
                <td><a target="_blank" href="http://www.nvda-project.org">http://www.nvda-project.org</a></td> 
                <td>Free</td>
             </tr>
             <tr >
                <td>3</td>
                <td>System Access To Go</td>
                <td><a target="_blank" href="http://www.satogo.com">http://www.satogo.com</a></td> 
                <td>Free</td>
             </tr>
             <tr >
                <td>4</td>
                <td>JAWS</td>
                <td><a target="_blank" href="http://www.freedomscientific.com/jaws-hq.asp">http://www.freedomscientific.com/jaws-hq.asp</a></td> 
                <td>Commercial</td>
             </tr>
             
            </tbody>
          </table>
        </div>
        </div>
    </>
  )
}

export default Screenreader