import { useState } from "react";
import DrtFinalOrderParentHome from "./DrtFinalOrderParentHome";
import DrtDailyOrderParentHome from "./DrtDailyOrderParentHome";

const DrtDailyFinalOrderParentHome = () => {
  const [relatedLink, setRelatedLink] = useState("DrtDailyOrderParentHome");
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 tab-one p-0">
        <ul
          className="nav toptab-one mb-3 inner-tab mx-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={
                relatedLink === "DrtDailyOrderParentHome"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("DrtDailyOrderParentHome")}
            >
              DRT's Daily Order
            </a>
          </li>

          <li className="nav-item">
            <a
              className={
                relatedLink === "DrtFinalOrderParentHome"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab2"
              data-toggle="pill"
              href="#pills-cause"
              role="tab"
              aria-controls="pills-cause"
              aria-selected="false"
              onClick={() => setRelatedLink("DrtFinalOrderParentHome")}
            >
              DRT's Final Order
            </a>
          </li>
        </ul>

        <div className="tab-content" id="pills-tabContent">
          {relatedLink === "DrtDailyOrderParentHome" ? (
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              // aria-labelledby="pills-home-tab"
            >
              <DrtDailyOrderParentHome />
            </div>
          ) : (
            <div
              className="tab-pane fade show active"
              id="pills-cause"
              role="tabpanel"
              // aria-labelledby="pills-profile-tab"
            >
              <DrtFinalOrderParentHome />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DrtDailyFinalOrderParentHome;
