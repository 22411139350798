import { useEffect } from "react";

export const setPageTitle = (title) => {
    document.title = title;
};

export const useTitle = (title) => {
    useEffect(() => {
        setPageTitle(title);
    
        return () => {
          setPageTitle("");
        };
    }, []);
};
