import React, { useEffect, useState, useContext } from "react";
import { DrtContext } from "../../../context/DrtContext";
import ReactPaginate from "react-paginate";
import { axiosBaseURLExternal } from "../../../httpCommon";

const TenderNotice = () => {
  const [loading, setLoading] = useState(false);
  const [selectedDratLocationId, setSelectedDratLocationId] = useState("100");
  const [drtDratLocationName, setDrtDratLocationName] = useState(
    "DEBT RECOVERY APPELLATE TRIBUNAL - DELHI"
  );
  const [tenderNotices, setTenderNotices] = useState([]);

  // DRAT Locations Dropdown
  const [dratLocations, setDratLocations] = useState([]);
  const fetchDratLocations = async () => {
    try {
      const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
      setDratLocations(res.data);
    } catch (error) {
      console.error("Error fetching DRAT locations:", error);
      setDratLocations([]);
    }
  };

  useEffect(() => {
    fetchDratLocations();
    fetchTenderNotices(selectedDratLocationId);
    fetchDrtLocations(selectedDratLocationId);
  }, [selectedDratLocationId]);

  const getDrtDratName = (id) => {
    let name = dratLocations.filter((item) => item.schemeNameDrtId === id);
    console.log(name);
    setDrtDratLocationName(name[0]?.SchemaName);
  };

  // DRT Locations Dropdown
  const [drtLocationsDropdown, setDrtLocationsDropdown] = useState([]);
  const fetchDrtLocations = async (id) => {
    try {
      const data = {
        schemeNameDratId: id,
      };
      const res = await axiosBaseURLExternal.post("getDrtSchemaName", data);
      setDrtLocationsDropdown(res.data);
    } catch (error) {
      console.error("Error fetching DRT locations:", error);
      setDrtLocationsDropdown([]);
    }
  };

  const fetchTenderNotices = async (id) => {
    try {
      setLoading(true);
      const data = {
        schemeNameDrtId: id,
      };
      const res = await axiosBaseURLExternal.post("getTenderNotice", data);
      setTenderNotices(
        res.data.map((item, index) => ({ ...item, sr: index + 1 }))
      );
      setLoading(false);
    } catch (error) {
      console.error("Error fetching tender notices:", error);
      setTenderNotices([]);
      setLoading(false);
    }
  };

  const doubleDigitDate = (date) => {
    if (date) {
      let arr = date.split("/");
      if (arr[0].length === 1) arr[0] = "0" + arr[0];
      if (arr[1].length === 1) arr[1] = "0" + arr[1];
      return arr.join("/");
    }
  };

  // pagination
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [startListNo, setStartListNo] = useState(0);

  useEffect(() => {
    const endListNo = startListNo + itemsPerPage;
    setCurrentItems(
      tenderNotices ? tenderNotices.slice(startListNo, endListNo) : []
    );
    setPageCount(
      Math.ceil((tenderNotices ? tenderNotices.length : 0) / itemsPerPage)
    );
  }, [startListNo, itemsPerPage, tenderNotices]);

  const handlePageClick = (event) => {
    const newStartListNo =
      (event.selected * itemsPerPage) %
      Math.max(tenderNotices ? tenderNotices.length : 1, 1);
    console.log("newStartListNo:", newStartListNo);
    setStartListNo(newStartListNo);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4 d-flex justify-content-between align-items-center">
          <div className="w-25">
            <h2>Tender Notice</h2>
          </div>
          <div className="w-75 d-flex">
            <div className="w-50 pr-3">
              <select
                className="form-control"
                onChange={(e) => {
                  setSelectedDratLocationId(e.target.value);
                  fetchDrtLocations(e.target.value);
                  getDrtDratName(e.target.value);
                  fetchTenderNotices(e.target.value);
                  setDrtLocationsDropdown([]);
                }}
                value={selectedDratLocationId}
              >
                {dratLocations &&
                  dratLocations
                    .filter((item) => parseInt(item.schemeNameDrtId) >= 100)
                    .map((item, index) => (
                      <option value={`${item.schemeNameDrtId}`} key={index}>
                        {item.SchemaName}
                      </option>
                    ))}
              </select>
            </div>
            <div className="w-50 pr-3">
              <select
                className="form-control"
                onChange={(e) => {
                  fetchTenderNotices(e.target.value);
                  getDrtDratName(e.target.value);
                }}
              >
                <option selected hidden>
                  Select DRT
                </option>
                {drtLocationsDropdown &&
                  drtLocationsDropdown.map((item, index) => (
                    <option value={item.schema_id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 notice-db">
          <div className="table-responsive">
            <table className="table table-striped common-table tender">
              <thead>
                <tr>
                  <th>Sl.No.</th>
                  <th>DRT/DRAT Name</th>
                  <th>Notice Title </th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td>
                      <h6 className="loader">Loading ...</h6>
                    </td>
                  </tr>
                ) : currentItems && currentItems.length > 0 ? (
                  currentItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{item.sr}</td>
                        <td>{drtDratLocationName}</td>
                        <td>{item.massage}</td>
                        <td>
                          <a target="_blank" href={item.auctionurl}>
                            <i className="fas fa-file-pdf"><span className="sr-only">no text</span></i>
                          </a><br/>
                          <small> size 589kb</small><br/>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">
                      <p>No Data Found ...</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <div className="nav-pagination mb-5">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TenderNotice;
