import { useState } from "react";
import CaseNoWiseDrat from "./Tabs/CaseNoWiseDrat";
import DiaryNoWiseDrat from "./Tabs/DiaryNoWiseDrat";
import PartyNoWiseDrat from "./Tabs/PartyNoWiseDrat";

const DratCaseStatusParent = () => {
  const [relatedLink, setRelatedLink] = useState("DiaryNoWiseDrat");
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 tab-one p-0">
        <ul
          className="nav toptab-one mb-3 inner-tab mx-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={
                relatedLink === "DiaryNoWiseDrat"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab2"
              data-toggle="pill"
              href="#pills-cause"
              role="tab"
              aria-controls="pills-cause"
              aria-selected="false"
              onClick={() => setRelatedLink("DiaryNoWiseDrat")}
            >
              Diary Number
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "CaseNoWiseDrat"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("CaseNoWiseDrat")}
            >
              Case Number
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                relatedLink === "PartyNoWiseDrat"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="inner-tab1"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setRelatedLink("PartyNoWiseDrat")}
            >
              Party Name
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {relatedLink === "CaseNoWiseDrat" ? (
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              // aria-labelledby="pills-home-tab"
            >
              <CaseNoWiseDrat />
            </div>
          ) : relatedLink === "DiaryNoWiseDrat" ? (
            <div
              className="tab-pane fade show active"
              id="pills-cause"
              role="tabpanel"
              // aria-labelledby="pills-profile-tab"
            >
              <DiaryNoWiseDrat />
            </div>
          )
          :
          <div
              className="tab-pane fade show active"
              id="pills-cause"
              role="tabpanel"
              // aria-labelledby="pills-home-tab"
            >
              <PartyNoWiseDrat />
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default DratCaseStatusParent;
