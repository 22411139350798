import { useState } from "react";
import DratCaseStatusParent from "./DratCaseStatus/DratCaseStatusParent";
import DrtCaseStatusParent from "./DrtCaseStatus/DrtCaseStatusParent";
import RcTrcCaseStatusParent from "./RcTrcCaseStatus/RcTrcCaseStatusParent";

const CaseDEtailParent = () => {
  const [relatedLink, setRelatedLink] = useState("DratCaseStatus");
  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-0">
          <ul className="nav toptab-two mb-3" id="v-pills-tab" role="tablist">
            <li className="nav-item">
              <a
                className={
                  relatedLink === "DratCaseStatus"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#pills-drt"
                role="tab"
                aria-controls="pills-drt"
                aria-selected="true"
                onClick={() => setRelatedLink("DratCaseStatus")}
              >
                DRAT Case Status
              </a>
            </li>

            <li className="nav-item">
              <a
                className={
                  relatedLink === "DrtCaseStatus"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#pills-drt"
                role="tab"
                aria-controls="pills-drt"
                aria-selected="true"
                onClick={() => setRelatedLink("DrtCaseStatus")}
              >
                DRT Case Status
              </a>
            </li>

            <li className="nav-item">
              <a
                className={
                  relatedLink === "RcTrcCaseStatus"
                    ? "nav-link active"
                    : "nav-link"
                }
                id=""
                data-toggle="pill"
                href="#pills-status"
                role="tab"
                aria-controls="pills-status"
                aria-selected="false"
                onClick={() => setRelatedLink("RcTrcCaseStatus")}
              >
                RC/TRC Case Status
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 pr-0">
          <div className="tab-content tab-form" id="pills-tabContent">
            {relatedLink === "DratCaseStatus" ? (
              <div
                className="tab-pane fade show active"
                id="pills-drt"
                role="tabpanel"
                // aria-labelledby="pills-home-tab"
              >
                <DratCaseStatusParent />
              </div>
            ) : relatedLink === "DrtCaseStatus" ? (
              <div
                className="tab-pane fade show active"
                id="pills-status"
                role="tabpanel"
                // aria-labelledby="pills-profile-tab"
              >
                <DrtCaseStatusParent />
              </div>
            ) : (
              <div
                className="tab-pane fade show active"
                id="pills-status"
                role="tabpanel"
                // aria-labelledby="pills-profile-tab"
              >
                <RcTrcCaseStatusParent />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseDEtailParent;
