import { Modal, Table, Button } from "react-bootstrap";
import { useState } from "react";
import { axiosBaseURLExternal } from "../../../../../../../httpCommon";

const PartyNameMoreDetail = ({ drtId, item, drtName }) => {
  const [loading, setLoading] = useState(false);
  const [moreDetail, setMoreDetail] = useState("");
  const fetchMoreDetails = async (url, data) => {
    setLoading(true);
    try {
      const response = await axiosBaseURLExternal.post(url, data);
      setMoreDetail(response.data);
    } catch (error) {
      setLoading(false);
      setMoreDetail(error.response.data);
    }
    setLoading(false);
  };
  const newMapDate = (date) => {
    if (date) {
      const [dd, mm, yyyy] = date?.split("/");
      return [
        dd.length === 1 ? `0${dd}` : dd,
        mm.length === 1 ? `0${mm}` : mm,
        yyyy,
      ].join("/");
    }
  };
  const [isOpenModal2, setIsOpenModal2] = useState(false);
  return (
    <>
      <button
        className="pop-btn btn"
        style={{ cursor: "pointer" }}
        onClick={() => {
          const data = {
            // filingNo: item.filingNo,
            diaryNo: item.diaryno.split("/")[0],
            diaryYear: item.diaryno.split("/")[1],
            schemeNameDrtId: drtId,
          };
          fetchMoreDetails("getDratCaseDetailDiaryNoWise", data);
          setIsOpenModal2(true);
          // setIsOpenModal(false);
        }}
      >
        More&nbsp;Detail
      </button>
      {loading ? (
        <h6 className="loader">Loading ......</h6>
      ) : (
        <Modal show={isOpenModal2} onHide={() => setIsOpenModal2(false)}>
          <Modal.Header>
            More Details <br /> {drtName}
            <Button
              type="button"
              className="close"
              onClick={() => setIsOpenModal2(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            {/* case status */}
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="2">CASE STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Diary No/Year</td>
                    <td>
                      <strong>
                        {moreDetail?.diaryno}/{moreDetail?.diaryyear}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Case Type/Case No/Year</td>
                    <td>
                      <strong>
                        {moreDetail?.casetype}/{moreDetail?.caseno}/
                        {moreDetail?.caseyear}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Date of Filing.</td>
                    <td>
                      <strong>{newMapDate(moreDetail?.dateoffiling)}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Case Status.</td>
                    <td>
                      <strong>{moreDetail?.casestatus}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            {/* case listing details Tentative */}
            {moreDetail?.courtName && moreDetail?.courtNo && (
              <div className="table-responsive mb-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th colSpan="2">CASE LISTING DETAILS (Tentative)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>In the Court of</td>
                      <td>
                        <strong>{moreDetail.courtName}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Court No.</td>
                      <td>
                        <strong>{moreDetail.courtNo}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Next Listing Date</td>
                      <td>
                        <strong>
                          {newMapDate(moreDetail?.nextlistingdate)}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Next Listing Purpose</td>
                      <td>
                        <strong>{moreDetail?.nextListingPurpose}</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

            {/* petitioner's details */}
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="2">
                      <strong>PETITIONER/APPLICANT DETAIL</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Petitioner Name:{" "}
                      <strong>{moreDetail?.petitionerName}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Petitioner/Applicant Address:{" "} 
                    <strong>{moreDetail?.petitionerApplicantAddress}</strong>
                    </td>
                    
                  </tr>
                  <tr>
                    <td>
                      Additional Party:{" "}
                      <strong>{moreDetail?.additionalpartypet}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Advocate Name:{" "}
                      <strong>{moreDetail?.advocatePetName}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            {/* respondent's details */}
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="2">
                      <strong>RESPONDENTS/DEFENDANT DETAILS</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Respondent Name: </td>
                    <td>
                      <strong>{moreDetail?.respondentName}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Respondent/Defendant Address: </td>
                    <td>
                      <strong>{moreDetail?.respondentDefendentAddress}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td> Additional Party: </td>
                    <td>
                      <strong>{moreDetail?.additionalpartyres}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td> Advocate Name: </td>
                    <td>
                      <strong>{moreDetail?.advocateResName}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            {/* case proceeding details */}
            {moreDetail?.caseProceedingDetails?.length > 0 && (
              <div className="table-responsive mb-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th colSpan="4">CASE PROCEEDING DETAILS</th>
                    </tr>
                    <tr>
                      <th>Court Name</th>
                      <th>Causelist Date</th>
                      <th>Purpose</th>
                      <th>Order</th>
                    </tr>
                  </thead>
                  <tbody>
                    {moreDetail?.caseProceedingDetails?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.ascourtName}</td>
                        <td>{newMapDate(item?.causelistdate)}</td>
                        <td>{item.purpose}</td>
                        <td>
                          {item.orderUrl && (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${item.orderUrl}`}
                            >
                              <i className="fas fa-search"><span className="sr-only">no text</span></i>
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}

            {/* ia details */}
            {moreDetail?.iaDetails?.length > 0 && (
              <div className="table-responsive mb-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th colSpan="6">IA DETAILS</th>
                    </tr>
                    <tr>
                      <th>Sr. No.</th>
                      <th>IA No.</th>
                      <th>Date of Filling</th>
                      <th>Date of Order</th>
                      <th>View IA</th>
                      <th>View IA Order</th>
                    </tr>
                  </thead>
                  <tbody>
                    {moreDetail?.iaDetails?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.iano}</td>
                        <td>{newMapDate(item.iadateoffiling)}</td>
                        <td>{newMapDate(item.iaorderdate)}</td>
                        <td>
                          {item.iaUrl && (
                            <a
                              target="_blank"
                              href={`https://drt.gov.in/drtlive/order/pdf/pdfia.php?file=${item.iaUrl}`}
                            >
                              <i className="fas fa-search"><span className="sr-only">no text</span></i>
                            </a>
                          )}
                        </td>
                        <td>
                          {item.orderUrl && (
                            <a target="_blank" href={`${item?.orderUrl}`}>
                              <i className="fas fa-search"><span className="sr-only">no text</span></i>
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}

            {/* RC Details */}
            {moreDetail?.rcdetail && (
              <>
                <div className="table-responsive mb-3">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan="4">
                          {" "}
                          RC DETAILS{" "}
                          <strong>
                            {" "}
                            &nbsp;&nbsp;(
                            {moreDetail?.rcdetail[0]?.diaryno})
                          </strong>
                        </th>
                      </tr>
                      <tr>
                        <th>Court Name</th>
                        <th>RC List Date</th>
                        <th>Purpose</th>
                        <th>RC Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{moreDetail.rcdetail[0]?.courtname}</td>
                        <td>
                          {newMapDate(moreDetail.rcdetail[0]?.nextlistingdate)}
                        </td>
                        <td>{moreDetail.rcdetail[0]?.nextListingPurpose}</td>
                        <td>{moreDetail.rcdetail[0]?.status}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                {/* // RC Proceeding details */}
                {moreDetail?.rcdetail[0]?.rcCaseProceedingDetails.length >
                  0 && (
                  <div className="table-responsive mb-3">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan="3">RC CASE PROCEEDING DETAILS</th>
                        </tr>
                        <tr>
                          <th>Court No</th>
                          <th>Cause List Date</th>
                          <th>Purpose</th>
                        </tr>
                      </thead>
                      <tbody>
                        {moreDetail.rcdetail[0]?.rcCaseProceedingDetails?.map(
                          (item, index) => (
                            <tr key={index}>
                              <td>{item.courtNo}</td>
                              <td>{newMapDate(item.causelistdate)}</td>
                              <td>{item.purpose}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PartyNameMoreDetail;
